function getUrlParamsFromArray(arr, key) {
  let urlParams = ''

  arr.forEach(item => {
    urlParams += `${key}[]=${item}&`
  })

  return urlParams
}

export default function getUrlParamsFromObj(formObj) {
  let urlParams = '?'

  Object.keys(formObj).forEach(key => {
    if (Array.isArray(formObj[key])) {
      urlParams += getUrlParamsFromArray(formObj[key], key)
    } else if (formObj[key]) {
      urlParams += `${key}=${formObj[key]}&`
    }
  })

  return urlParams
}
