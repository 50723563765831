import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Table,
  Divider,
  Popconfirm,
  InputNumber,
  Modal,
  DatePicker,
  Icon,
  Spin,
  Select,
  Tooltip
} from 'antd'
import moment from 'moment'
import {
  EditOutlined,
  DeleteOutlined,
  SaveOutlined,
  CloseOutlined
} from '@ant-design/icons'

import {
  getList,
  updateTableItem,
  deleteItem,
  createTableItem,
  setField
} from '../../../../../redux/actions/ClientEstimatesActions'

const { Option } = Select

const GENERAL_ASSESSMENT_OF_MOTOR_ABILITIES = {
  1: 'Может самостоятельно стоять, учится ходить',
  2: 'Может самостоятельно сидеть, учится стоять',
  3: 'Может поддерживать позу лежа, учится сидеть',
  4: 'Не может поддерживать позу лежа, учится лежать'
}

const LEVEL_OF_ABILITY_TO_MAINTAIN_POSTURE = {
  0: 'Невозможно поместить в заданную позу',
  1: 'Можно поместить в заданную позу',
  2: 'Может поддерживать заданную позу',
  3: 'Может перемещаться внутри заданной позы (центр тяжести перемещается внутри площади опоры)',
  4: 'Может переместиться из заданной позы',
  5: 'Может переместиться в заданную позу'
}

class HereTab extends PureComponent {
  state = {
    isOpenModal: false,
    createDate: moment().format('YYYY-MM-DD'),
    createGeneralAssessmentOfMotorAbilities: undefined
  }

  constructor(props) {
    super(props)

    this.getRenderedItem = this.getRenderedItem.bind(this)
  }

  componentDidMount() {
    this.props.getList(this.props.clientId)
  }

  generateTableDataSource(item) {
    return [
      {
        key: 'bal-' + item.id,
        id: item.id,
        type: 'Баллы',

        lying_back: item.lying_back,
        lying_stomach: item.lying_stomach,
        lying_right_side: item.lying_right_side,
        lying_left_side: item.lying_left_side,

        sitting_outstretched_legs: item.sitting_outstretched_legs,
        sitting_legs_down: item.sitting_legs_down,

        standing_foot: item.standing_foot,
        standing_without_foot: item.standing_without_foot
      },
      {
        key: 'sum-' + item.id,
        id: item.id,
        type: <b>Сумма</b>,
        staticRaw: true,
        lying_back:
          item.lying_back +
          item.lying_stomach +
          item.lying_right_side +
          item.lying_left_side +
          item.sitting_outstretched_legs +
          item.sitting_legs_down +
          item.standing_foot +
          item.standing_without_foot
      }
    ]
  }

  getRenderedItem(text, record, columnKey) {
    const { harescale } = this.props.clientEstimates
    let children = text

    if (!record.staticRaw) {
      if (harescale.editableIds.indexOf(record.id) === -1) {
        children = (
          <Tooltip
            placement="top"
            title={LEVEL_OF_ABILITY_TO_MAINTAIN_POSTURE[text]}
          >
            <span style={{ cursor: 'help' }}>{text}</span>
          </Tooltip>
        )
      } else {
        children = (
          <InputNumber
            min={0}
            max={5}
            value={harescale.editableItems[record.id][columnKey]}
            onChange={value =>
              this.props.setField('editableItems', {
                ...harescale.editableItems,
                [record.id]: {
                  ...harescale.editableItems[record.id],
                  [columnKey]: value
                }
              })
            }
          />
        )
      }
    } else if (
      record.staticRaw &&
      harescale.editableIds.indexOf(record.id) !== -1 &&
      columnKey === 'lying_back'
    ) {
      return (
        harescale.editableItems[record.id].lying_back +
        harescale.editableItems[record.id].lying_stomach +
        harescale.editableItems[record.id].lying_right_side +
        harescale.editableItems[record.id].lying_left_side +
        harescale.editableItems[record.id].sitting_outstretched_legs +
        harescale.editableItems[record.id].sitting_legs_down +
        harescale.editableItems[record.id].standing_foot +
        harescale.editableItems[record.id].standing_without_foot
      )
    }

    return {
      children
    }
  }

  render() {
    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />
    const { harescale } = this.props.clientEstimates

    const columns = [
      {
        title: '',
        dataIndex: 'type'
      },
      {
        title: 'Положение лежа',
        children: [
          {
            title: 'На спине',
            dataIndex: 'lying_back',
            render: (text, record) =>
              this.getRenderedItem(text, record, 'lying_back')
          },
          {
            title: 'На животе',
            dataIndex: 'lying_stomach',
            render: (text, record) =>
              this.getRenderedItem(text, record, 'lying_stomach')
          },
          {
            title: 'На правом боку',
            dataIndex: 'lying_right_side',
            render: (text, record) =>
              this.getRenderedItem(text, record, 'lying_right_side')
          },
          {
            title: 'На левом боку',
            dataIndex: 'lying_left_side',
            render: (text, record) =>
              this.getRenderedItem(text, record, 'lying_left_side')
          }
        ]
      },
      {
        title: 'Положение сидя',
        children: [
          {
            title: 'С вытянутыми ногами',
            dataIndex: 'sitting_outstretched_legs',
            render: (text, record) =>
              this.getRenderedItem(text, record, 'sitting_outstretched_legs')
          },
          {
            title: 'Со спущенными ногами (с опорой на ноги)',
            dataIndex: 'sitting_legs_down',
            render: (text, record) =>
              this.getRenderedItem(text, record, 'sitting_legs_down')
          }
        ]
      },
      {
        title: 'Положение стоя',
        children: [
          {
            title: 'У опоры',
            dataIndex: 'standing_foot',
            render: (text, record) =>
              this.getRenderedItem(text, record, 'standing_foot')
          },
          {
            title: 'Без опоры',
            dataIndex: 'standing_without_foot',
            render: (text, record) =>
              this.getRenderedItem(text, record, 'standing_without_foot')
          }
        ]
      }
    ]

    return (
      <Spin spinning={harescale.isLoading} indicator={antIcon}>
        <Button
          type="primary"
          onClick={() => this.setState({ isOpenModal: true })}
        >
          Добавить таблицу
        </Button>
        <Modal
          title="Параметры оценки"
          visible={this.state.isOpenModal}
          onOk={() => {
            this.props.createTableItem(this.props.clientId, {
              date: this.state.createDate,
              general_assessment_of_motor_abilities: this.state
                .createGeneralAssessmentOfMotorAbilities
            })
            this.setState({
              isOpenModal: false,
              createDate: moment().format('YYYY-MM-DD'),
              createGeneralAssessmentOfMotorAbilities: undefined
            })
          }}
          onCancel={() => this.setState({ isOpenModal: false })}
        >
          <div className="Client__modal-div">
            Дата
            <br />
            <DatePicker
              placeholder="Выберите дату"
              format={'DD.MM.YYYY'}
              value={moment(this.state.createDate, 'YYYY-MM-DD')}
              allowClear={false}
              onChange={(date, dateString) => {
                this.setState({
                  createDate: moment(dateString, 'DD.MM.YYYY').format(
                    'YYYY-MM-DD'
                  )
                })
              }}
            />
          </div>
          <div className="Client__modal-div">
            Общая оценка двигательных возможностей
            <br />
            <Select
              placeholder="Выберите"
              onChange={value =>
                this.setState({
                  createGeneralAssessmentOfMotorAbilities: value
                })
              }
              value={this.state.createGeneralAssessmentOfMotorAbilities}
              style={{ width: '100%' }}
            >
              {Object.keys(GENERAL_ASSESSMENT_OF_MOTOR_ABILITIES).map(key => (
                <Option value={key} key={key}>
                  {GENERAL_ASSESSMENT_OF_MOTOR_ABILITIES[key]}
                </Option>
              ))}
            </Select>
          </div>
        </Modal>
        {harescale.items.map(item => (
          <div key={item.id}>
            <h3
              style={{
                marginTop: '20px',
                display: 'inline-block',
                marginRight: '.5rem'
              }}
            >
              {moment(item.date).format('DD.MM.YYYY')}
            </h3>
            {harescale.editableIds.indexOf(item.id) === -1 ? (
              <span className="Clients__control-buttons">
                <a
                  href="/"
                  onClick={e => {
                    e.preventDefault()
                    this.props.setField('editableIds', [
                      ...harescale.editableIds,
                      item.id
                    ])
                    this.props.setField('editableItems', {
                      ...harescale.editableItems,
                      [item.id]: item
                    })
                  }}
                >
                  <EditOutlined />
                </a>
                <Divider type="vertical" />
                <Popconfirm
                  title="Вы уверены, что хотите удалить запись?"
                  onConfirm={e => {
                    this.props.deleteItem(item.id)
                  }}
                  okText="Да"
                  cancelText="Нет"
                >
                  <a href="/">
                    <DeleteOutlined />
                  </a>
                </Popconfirm>
              </span>
            ) : (
              <span className="Clients__control-buttons">
                <a
                  href="/"
                  onClick={e => {
                    e.preventDefault()
                    this.props.setField(
                      'editableIds',
                      harescale.editableIds.filter(value => value !== item.id)
                    )
                    this.props.updateTableItem(
                      item.id,
                      harescale.editableItems[item.id]
                    )
                  }}
                >
                  <SaveOutlined />
                </a>
                <Divider type="vertical" />
                <a
                  href="/"
                  onClick={e => {
                    e.preventDefault()
                    this.props.setField(
                      'editableIds',
                      harescale.editableIds.filter(value => value !== item.id)
                    )
                  }}
                >
                  <CloseOutlined />
                </a>
              </span>
            )}
            <p>
              <b>Общая оценка:</b>{' '}
              {
                GENERAL_ASSESSMENT_OF_MOTOR_ABILITIES[
                  item.general_assessment_of_motor_abilities
                ]
              }
            </p>
            <Table
              dataSource={this.generateTableDataSource(item)}
              columns={columns}
              pagination={false}
              rowClassName={record =>
                record.staticRaw
                  ? 'Client__table-static-row ant-table-row-selected'
                  : ''
              }
            />
            {harescale.editableIds.indexOf(item.id) !== -1 && (
              <div style={{ marginTop: '15px' }}>
                <div>
                  <b>Обозначения:</b>
                </div>
                {Object.keys(LEVEL_OF_ABILITY_TO_MAINTAIN_POSTURE).map(key => (
                  <div value={key} key={key}>
                    {key} – {LEVEL_OF_ABILITY_TO_MAINTAIN_POSTURE[key]}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </Spin>
    )
  }
}

const mapStateToProps = store => {
  return {
    clientEstimates: store.clientEstimates
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getList: (id, params = {}) =>
      dispatch(getList(id, 'harescale', 'harescale', params)),
    setField: (field, value) => dispatch(setField(field, value, 'harescale')),
    updateTableItem: (id, formObj) =>
      dispatch(updateTableItem(id, 'harescale', formObj)),
    deleteItem: id => dispatch(deleteItem(id, 'harescale', 'harescale')),
    createTableItem: (clientId, formObj) =>
      dispatch(createTableItem(clientId, 'harescale', formObj))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HereTab)
