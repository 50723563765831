import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  Icon,
  Spin,
  Table,
  Divider,
  Popconfirm,
  InputNumber,
  Button,
  Modal,
  DatePicker,
  Row,
  Col,
  Tooltip,
  Input,
  Radio,
  Select
} from 'antd'
import moment from 'moment'
import {
  EditOutlined,
  DeleteOutlined,
  SaveOutlined,
  CloseOutlined
} from '@ant-design/icons'

import DateRangeLoad from '../../../../../components/DateRangeLoadComponent'

import {
  getTestCategories,
  getList,
  updateTableItem,
  deleteItem,
  createTableItem,
  setField,
  setMobilityTestValue,
  removeMobilityTest
} from '../../../../../redux/actions/ClientEstimatesActions'

import downloadFile from '../../../../../utils/downloadFile'

const { TextArea } = Input
const { Option, OptGroup } = Select

class MobilityTab extends PureComponent {
  state = {
    isOpenModal: false,
    createDate: moment().format('YYYY-MM-DD'),

    isOpenDescriptionModal: false,
    descriptionModalTitle: '',
    descriptionModalContent: '',

    isOpenNewTestModal: false,
    newTestSelectId: undefined,
    newTestIds: []
  }

  constructor(props) {
    super(props)

    this.getFieldValue = this.getFieldValue.bind(this)
  }

  componentDidMount() {
    this.props.getTestCategories()
    this.props.getList(this.props.clientId)
  }

  componentDidUpdate() {
    const { mobility } = this.props.clientEstimates
    let existTestIds = []
    mobility.items.forEach(item => {
      item.estimates.forEach(estimate => {
        existTestIds.push(estimate.test_id)
      })
    })
    const newTestIds = this.state.newTestIds.filter(
      id => existTestIds.indexOf(id) === -1
    )
    let self = this
    let isSame =
      newTestIds.length === this.state.newTestIds.length &&
      newTestIds.every(function(element, index) {
        return element === self.state.newTestIds[index]
      })

    if (!isSame) {
      this.setState({ newTestIds })
    }
  }

  getFieldValue(id, testId, field) {
    let value = null
    this.props.clientEstimates.mobility.editableItems[id].estimates.forEach(
      estimate => {
        if (estimate.test_id === testId) {
          value = estimate[field]
        }
      }
    )
    return value
  }

  exportDocx() {
    const { mobility } = this.props.clientEstimates

    let url = `${process.env.REACT_APP_API_HOST}/mobility/export/${this.props.clientId}?`
    if (mobility.requestParams.date_s && mobility.requestParams.date_e) {
      url += `date_s=${mobility.requestParams.date_s}&date_e=${mobility.requestParams.date_e}`
    }
    const { clientData } = this.props.client
    downloadFile(
      url,
      `Мобильность ${clientData.name} ${clientData.surname}.docx`,
      this.props.user.attributes.token
    )
  }

  render() {
    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />
    const { mobility } = this.props.clientEstimates

    let columns = [
      {
        title: '',
        dataIndex: 'test',
        width: 225,
        fixed: 'left'
      }
    ]

    mobility.items.forEach(item => {
      columns.push({
        title: (
          <>
            <span style={{ marginRight: '8px' }}>
              {moment(item.date, 'YYYY-MM-DD').format('DD.MM.YYYY')}
            </span>
            {mobility.editableIds.indexOf(item.id) === -1 ? (
              <span className="Clients__control-buttons">
                <a
                  href="/"
                  onClick={e => {
                    e.preventDefault()
                    this.props.setField('editableIds', [
                      ...mobility.editableIds,
                      item.id
                    ])
                    this.props.setField('editableItems', {
                      ...mobility.editableItems,
                      [item.id]: item
                    })
                  }}
                >
                  <EditOutlined />
                </a>
                <Divider type="vertical" />
                <Popconfirm
                  title="Вы уверены, что хотите удалить оценки?"
                  onConfirm={e => {
                    this.props.deleteItem(item.id)
                  }}
                  okText="Да"
                  cancelText="Нет"
                >
                  <a href="/">
                    <DeleteOutlined />
                  </a>
                </Popconfirm>
              </span>
            ) : (
              <span className="Clients__control-buttons">
                <a
                  href="/"
                  onClick={e => {
                    e.preventDefault()
                    this.props.setField(
                      'editableIds',
                      mobility.editableIds.filter(value => value !== item.id)
                    )
                    this.props.updateTableItem(
                      item.id,
                      mobility.editableItems[item.id]
                    )
                  }}
                >
                  <SaveOutlined />
                </a>
                <Divider type="vertical" />
                <a
                  href="/"
                  onClick={e => {
                    e.preventDefault()
                    this.props.setField(
                      'editableIds',
                      mobility.editableIds.filter(value => value !== item.id)
                    )
                  }}
                >
                  <CloseOutlined />
                </a>
              </span>
            )}
          </>
        ),
        children: [
          {
            title: 'Результат',
            dataIndex: `result_${item.id}`,
            width: 110,
            render: (text, record) => ({
              children:
                mobility.editableIds.indexOf(item.id) === -1 ? (
                  text ? (
                    text
                  ) : (
                    '–'
                  )
                ) : (
                  <InputNumber
                    min={0}
                    precision={2}
                    step={0.01}
                    value={this.getFieldValue(item.id, record.key, 'result')}
                    onChange={value => {
                      this.props.setMobilityTestValue(
                        item.id,
                        record.key,
                        'result',
                        value
                      )
                    }}
                  />
                )
            })
          },
          {
            title: 'Комментарий',
            dataIndex: `comment_${item.id}`,
            render: (text, record) => ({
              children:
                mobility.editableIds.indexOf(item.id) === -1 ? (
                  text ? (
                    text
                  ) : (
                    '–'
                  )
                ) : (
                  <TextArea
                    maxLength={1000}
                    value={this.getFieldValue(item.id, record.key, 'comment')}
                    onChange={e => {
                      this.props.setMobilityTestValue(
                        item.id,
                        record.key,
                        'comment',
                        e.target.value
                      )
                    }}
                  />
                )
            })
          },
          {
            title: 'Состояние',
            dataIndex: `emoji_${item.id}`,
            width: 150,
            render: (text, record) => ({
              children:
                mobility.editableIds.indexOf(item.id) === -1 ? (
                  text !== '' && text !== '–' ? (
                    <span
                      role="img"
                      aria-label=""
                      className="Client__table-emoji-single"
                    >
                      {text}
                    </span>
                  ) : (
                    '–'
                  )
                ) : (
                  <Radio.Group
                    value={this.getFieldValue(item.id, record.key, 'emoji')}
                    onChange={e => {
                      this.props.setMobilityTestValue(
                        item.id,
                        record.key,
                        'emoji',
                        e.target.value
                      )
                    }}
                  >
                    <Radio value="😧">
                      <span
                        role="img"
                        aria-label=""
                        className="Client__table-emoji"
                      >
                        😧
                      </span>
                    </Radio>
                    <Radio value="🙁">
                      <span
                        role="img"
                        aria-label=""
                        className="Client__table-emoji"
                      >
                        🙁
                      </span>
                    </Radio>
                    <Radio value="😐">
                      <span
                        role="img"
                        aria-label="😐"
                        className="Client__table-emoji"
                      >
                        😐
                      </span>
                    </Radio>
                    <Radio value="🙂">
                      <span
                        role="img"
                        aria-label=""
                        className="Client__table-emoji"
                      >
                        🙂
                      </span>
                    </Radio>
                    <Radio value="😊">
                      <span
                        role="img"
                        aria-label=""
                        className="Client__table-emoji"
                      >
                        😊
                      </span>
                    </Radio>
                  </Radio.Group>
                )
            })
          }
        ]
      })
    })

    const tests = []
    mobility.testCategories.forEach(category => {
      category.tests.forEach(test => {
        tests.push(test)
      })
    })

    let data = []
    if (mobility.items.length > 0) {
      tests.forEach(test => {
        let rowObj = {
          key: test.id,
          test: (
            <>
              {test.name}{' '}
              {test.description && (
                <span className="Clients__control-buttons">
                  <a
                    href="/"
                    onClick={e => {
                      e.preventDefault()
                      this.setState({
                        isOpenDescriptionModal: true,
                        descriptionModalTitle: test.name,
                        descriptionModalContent: test.description
                      })
                    }}
                  >
                    <Icon type="info-circle" />
                  </a>
                </span>
              )}
              {this.state.newTestIds.indexOf(test.id) !== -1 && (
                <div>
                  <a
                    href="/"
                    onClick={e => {
                      e.preventDefault()
                      this.setState({
                        newTestIds: this.state.newTestIds.filter(
                          item => item !== test.id
                        )
                      })
                      this.props.removeMobilityTest(test.id)
                    }}
                  >
                    Отмена
                  </a>
                </div>
              )}
            </>
          )
        }

        mobility.items.forEach(item => {
          let estimateObj = {
            result: '–',
            comment: '–',
            emoji: '–'
          }

          item.estimates.forEach(estitamte => {
            if (estitamte.test_id === test.id && estitamte.result !== 0) {
              estimateObj = estitamte
            }
          })

          rowObj[`result_${item.id}`] = estimateObj.result
          rowObj[`comment_${item.id}`] = estimateObj.comment
          rowObj[`emoji_${item.id}`] = estimateObj.emoji
        })

        data.push(rowObj)
      })
    }

    let existTestIds = []
    data.forEach(row => {
      mobility.items.forEach(item => {
        if (
          row[`result_${item.id}`] !== '–' ||
          this.state.newTestIds.indexOf(row.key) !== -1
        ) {
          existTestIds.push(row.key)
        }
      })
    })

    let newTestOptionGroups = []
    mobility.testCategories.forEach(category => {
      let testOptions = []
      category.tests.forEach(test => {
        if (existTestIds.indexOf(test.id) === -1) {
          testOptions.push(
            <Option key={`test-${test.id}`} value={test.id}>
              {test.name}
            </Option>
          )
        }
      })
      if (testOptions.length > 0) {
        newTestOptionGroups.push(
          <OptGroup label={category.name} key={`category-${category.id}`}>
            {testOptions}
          </OptGroup>
        )
      }
    })

    return (
      <Spin
        spinning={mobility.isLoadingTestCategories || mobility.isLoading}
        indicator={antIcon}
      >
        <Button
          type="primary"
          onClick={() => this.setState({ isOpenModal: true })}
        >
          Создать
        </Button>
        <Row gutter={16} className="Clients__settings" align="middle">
          <Col span={21}>
            <DateRangeLoad
              clientId={this.props.clientId}
              getList={this.props.getList}
            />
          </Col>
          <Col span={3} className="Client__calendar-col">
            <Tooltip
              placement="bottom"
              mouseEnterDelay={0.75}
              title="Экспорт таблицы в MS Word"
            >
              <Button
                shape="circle"
                size="large"
                onClick={this.exportDocx.bind(this)}
              >
                <Icon type="file-word" className="Client__calendar-icon" />
              </Button>
            </Tooltip>
          </Col>
        </Row>
        <Modal
          title="Параметры оценки"
          visible={this.state.isOpenModal}
          onOk={() => {
            this.props.createTableItem(this.props.clientId, {
              date: this.state.createDate
            })
            this.setState({
              isOpenModal: false,
              createDate: moment().format('YYYY-MM-DD')
            })
          }}
          onCancel={() => this.setState({ isOpenModal: false })}
        >
          <div className="Client__modal-div">
            Дата
            <br />
            <DatePicker
              placeholder="Выберите дату"
              format={'DD.MM.YYYY'}
              value={moment(this.state.createDate, 'YYYY-MM-DD')}
              allowClear={false}
              onChange={(date, dateString) => {
                this.setState({
                  createDate: moment(dateString, 'DD.MM.YYYY').format(
                    'YYYY-MM-DD'
                  )
                })
              }}
            />
          </div>
        </Modal>
        <p></p>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{ x: `${columns.length * 400}px` }}
          rowClassName={row => {
            let rowClass = ''
            if (existTestIds.indexOf(row.key) === -1) {
              rowClass = 'Client__table-hidden-row'
            }
            if (this.state.newTestIds.indexOf(row.key) !== -1) {
              rowClass = 'Client__table-new-row ant-table-row-selected'
            }
            return rowClass
          }}
        />
        <Modal
          title={this.state.descriptionModalTitle}
          visible={this.state.isOpenDescriptionModal}
          onOk={() => this.setState({ isOpenDescriptionModal: false })}
          onCancel={() => this.setState({ isOpenDescriptionModal: false })}
          cancelButtonProps={{ style: { display: 'none' } }}
          okText={'Закрыть'}
          width={'65%'}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: this.state.descriptionModalContent
            }}
          ></div>
        </Modal>
        <p></p>
        <Button
          style={{ marginBottom: '10px' }}
          onClick={() => this.setState({ isOpenNewTestModal: true })}
        >
          Добавить тест
        </Button>
        <Modal
          title="Добавление теста"
          visible={this.state.isOpenNewTestModal}
          onOk={() =>
            this.setState({
              isOpenNewTestModal: false,
              newTestIds: [
                ...this.state.newTestIds,
                this.state.newTestSelectId
              ],
              newTestSelectId: undefined
            })
          }
          onCancel={() => this.setState({ isOpenNewTestModal: false })}
          okButtonProps={{
            disabled: !this.state.newTestSelectId ? true : false
          }}
        >
          <div className="Client__modal-div">
            <Select
              style={{ width: '100%' }}
              placeholder="Выберите тест"
              value={this.state.newTestSelectId}
              onChange={value => this.setState({ newTestSelectId: value })}
            >
              {newTestOptionGroups}
            </Select>
          </div>
        </Modal>
      </Spin>
    )
  }
}

const mapStateToProps = store => {
  return {
    clientEstimates: store.clientEstimates,
    client: store.client,
    user: store.user
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    getTestCategories: () => dispatch(getTestCategories()),
    getList: (id, params = {}) =>
      dispatch(getList(id, 'mobility', 'mobility', params)),
    setField: (field, value) => dispatch(setField(field, value, 'mobility')),
    createTableItem: (clientId, formObj) =>
      dispatch(createTableItem(clientId, 'mobility', formObj)),
    setMobilityTestValue: (id, testId, field, value) =>
      dispatch(setMobilityTestValue(id, testId, field, value)),
    updateTableItem: (id, formObj) =>
      dispatch(updateTableItem(id, 'mobility', formObj)),
    removeMobilityTest: testId => dispatch(removeMobilityTest(testId)),
    deleteItem: id => dispatch(deleteItem(id, 'mobility', 'mobility'))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MobilityTab)
