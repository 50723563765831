// client form START

export const OPEN_CLIENT_FORM = 'CLIENT/OPEN_CLIENT_FORM'

export function openClientForm(mode = 'clientForm') {
  return dispatch => {
    dispatch({
      type: OPEN_CLIENT_FORM,
      payload: {
        mode
      }
    })
  }
}

export const OPEN_EDIT_CLIENT_FROM = 'CLIENT/OPEN_EDIT_CLIENT_FROM'

export function openEditClientForm(item, mode = 'clientForm', copy = false) {
  return dispatch => {
    dispatch({
      type: OPEN_EDIT_CLIENT_FROM,
      payload: {
        item,
        mode,
        copy
      }
    })
  }
}

export const CLOSE_CLIENT_FORM = 'CLIENT/CLOSE_CLIENT_FORM'

export function closeClientForm(mode = 'clientForm') {
  return dispatch => {
    dispatch({
      type: CLOSE_CLIENT_FORM,
      payload: {
        mode
      }
    })
  }
}

export const SET_FIELD_FOR_CLIENT = 'CLIENT/SET_FIELD_FOR_CLIENT'

export function setFieldForClient(field, value, mode = 'clientForm') {
  return dispatch => {
    dispatch({
      type: SET_FIELD_FOR_CLIENT,
      payload: {
        field,
        value,
        mode
      }
    })
  }
}

export const UPDATE_SPECIALISTS = 'CLIENT/UPDATE_SPECIALISTS'

export function updateSpecialists(value, mode = 'clientForm') {
  return dispatch => {
    dispatch({
      type: UPDATE_SPECIALISTS,
      payload: {
        value,
        mode
      }
    })
  }
}

export const SET_TYPE_FOR_SPECIALIST = 'CLIENT/SET_TYPE_FOR_SPECIALIST'

export function setTypeForSpecialist(id, value, mode = 'clientForm') {
  return dispatch => {
    dispatch({
      type: SET_TYPE_FOR_SPECIALIST,
      payload: {
        id,
        value,
        mode
      }
    })
  }
}

export const CREATE_CLIENT_REQUEST = 'CLIENT/CREATE_CLIENT_REQUEST'
export const CREATE_CLIENT_SUCCESS = 'CLIENT/CREATE_CLIENT_SUCCESS'

export function createClient(formObj) {
  return dispatch => {
    dispatch({
      types: [CREATE_CLIENT_REQUEST, CREATE_CLIENT_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/clients/create`,
          data: {
            ...formObj
          }
        }
      }
    })
  }
}

export const UPDATE_CLIENT_REQUEST = 'CLIENT/UPDATE_CLIENT_REQUEST'
export const UPDATE_CLIENT_SUCCESS = 'CLIENT/UPDATE_CLIENT_SUCCESS'

export function updateClient(id, formObj) {
  return dispatch => {
    dispatch({
      types: [UPDATE_CLIENT_REQUEST, UPDATE_CLIENT_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/clients/update/${id}`,
          data: {
            ...formObj
          }
        }
      }
    })
  }
}

// client form END

// client page START
export const DATA_LOAD_REQUEST = 'CLIENT/DATA_LOAD_REQUEST'
export const DATA_LOAD_SUCCESS = 'CLIENT/DATA_LOAD_SUCCESS'

export function getData(id) {
  return dispatch => {
    dispatch({
      types: [DATA_LOAD_REQUEST, DATA_LOAD_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: `/clients/get-data/${id}`
        }
      }
    })
  }
}

export const GENERAL_DATA_LOAD_REQUEST = 'CLIENT/GENERAL_DATA_LOAD_REQUEST'
export const GENERAL_DATA_LOAD_SUCCESS = 'CLIENT/GENERAL_DATA_LOAD_SUCCESS'

export function getGeneralData(id) {
  return dispatch => {
    dispatch({
      types: [GENERAL_DATA_LOAD_REQUEST, GENERAL_DATA_LOAD_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: `/clients/get-general-data/${id}`
        }
      }
    })
  }
}

export const UPDATE_GENERAL_DATA_REQUEST = 'CLIENT/UPDATE_GENERAL_DATA_REQUEST'
export const UPDATE_GENERAL_DATA_SUCCESS = 'CLIENT/UPDATE_GENERAL_DATA_SUCCESS'

export function updateGeneralData(id, formObj) {
  return dispatch => {
    dispatch({
      types: [UPDATE_GENERAL_DATA_REQUEST, UPDATE_GENERAL_DATA_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/clients/update-general-data/${id}`,
          data: {
            ...formObj
          }
        }
      }
    })
  }
}

export const MOVE_TO_ARCHIVE = 'CLIENT/MOVE_TO_ARCHIVE'

export function moveToArchive(type = 'project', name, date) {
  return dispatch => {
    dispatch({
      type: MOVE_TO_ARCHIVE,
      payload: {
        type,
        name,
        date
      }
    })
  }
}

export const PROJECTS_LIST_REQUEST = 'CLIENT/PROJECTS_LIST_REQUEST'
export const PROJECTS_LIST_SUCCESS = 'CLIENT/PROJECTS_LIST_SUCCESS'

export function getProjectsList() {
  return dispatch => {
    dispatch({
      types: [PROJECTS_LIST_REQUEST, PROJECTS_LIST_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: `/clients/get-projects-list`
        }
      }
    })
  }
}

export const SPONSORS_LIST_REQUEST = 'CLIENT/SPONSORS_LIST_REQUEST'
export const SPONSORS_LIST_SUCCESS = 'CLIENT/SPONSORS_LIST_SUCCESS'

export function getSponsorsList() {
  return dispatch => {
    dispatch({
      types: [SPONSORS_LIST_REQUEST, SPONSORS_LIST_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: `/clients/get-sponsors-list`
        }
      }
    })
  }
}

export const CLIENTS_LIST_REQUEST = 'CLIENT/CLIENTS_LIST_REQUEST'
export const CLIENTS_LIST_SUCCESS = 'CLIENT/CLIENTS_LIST_SUCCESS'

export function getClientsList() {
  return dispatch => {
    dispatch({
      types: [CLIENTS_LIST_REQUEST, CLIENTS_LIST_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: `/clients/get-clients-list`,
          params: {
            limit: 1000,
            status: 'active',
            sort: 'surname',
            sort_type: 'asc'
          }
        }
      }
    })
  }
}

export const SITE_USERS_LIST_REQUEST = 'CLIENT/SITE_USERS_LIST_REQUEST'
export const SITE_USERS_LIST_SUCCESS = 'CLIENT/SITE_USERS_LIST_SUCCESS'

export function getSiteUsersList() {
  return dispatch => {
    dispatch({
      types: [SITE_USERS_LIST_REQUEST, SITE_USERS_LIST_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: `/clients/get-site-users-list`
        }
      }
    })
  }
}

export const USERS_LIST_REQUEST = 'CLIENT/USERS_LIST_REQUEST'
export const USERS_LIST_SUCCESS = 'CLIENT/USERS_LIST_SUCCESS'

export function getUsersList() {
  return dispatch => {
    dispatch({
      types: [USERS_LIST_REQUEST, USERS_LIST_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: `/users/get-users-list`
        }
      }
    })
  }
}

export const DIAGNOSES_LIST_REQUEST = 'CLIENT/DIAGNOSES_LIST_REQUEST'
export const DIAGNOSES_LIST_SUCCESS = 'CLIENT/DIAGNOSES_LIST_SUCCESS'

export function getDiagnosesList() {
  return dispatch => {
    dispatch({
      types: [DIAGNOSES_LIST_REQUEST, DIAGNOSES_LIST_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: `/clients/get-diagnoses-list`
        }
      }
    })
  }
}

export const COPM_TABLES_LOAD_REQUEST = 'CLIENT/COPM_TABLES_LOAD_REQUEST'
export const COPM_TABLES_LOAD_SUCCESS = 'CLIENT/COPM_TABLES_LOAD_SUCCESS'

export function getCopmTalbes(id) {
  return dispatch => {
    dispatch({
      types: [COPM_TABLES_LOAD_REQUEST, COPM_TABLES_LOAD_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: `/copm/get-copm-tables/${id}`
        }
      }
    })
  }
}

export const CREATE_COPM_TABLE_REQUEST = 'CLIENT/CREATE_COPM_TABLE_REQUEST'
export const CREATE_COPM_TABLE_SUCCESS = 'CLIENT/CREATE_COPM_TABLE_SUCCESS'

export function createCopmTable(clientId, formObj) {
  return dispatch => {
    dispatch({
      types: [CREATE_COPM_TABLE_REQUEST, CREATE_COPM_TABLE_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/copm/create-copm-table`,
          data: {
            client_id: clientId,
            ...formObj
          }
        }
      }
    })
  }
}

export const ADD_COPM_ITEM = 'CLIENT/ADD_COPM_ITEM'

export function addCopmItem(copmId) {
  return dispatch => {
    dispatch({
      type: ADD_COPM_ITEM,
      payload: {
        copmId
      }
    })
  }
}

export const EDIT_COPM_ITEM = 'CLIENT/EDIT_COPM_ITEM'

export function editCopmItem(copmId, id) {
  return dispatch => {
    dispatch({
      type: EDIT_COPM_ITEM,
      payload: {
        copmId,
        id
      }
    })
  }
}

export const CANCEL_COPM_ITEM = 'CLIENT/CANCEL_COPM_ITEM'

export function cancelCopmItem(copmId, id = null, uuid = null) {
  return dispatch => {
    dispatch({
      type: CANCEL_COPM_ITEM,
      payload: {
        copmId,
        id,
        uuid
      }
    })
  }
}

export const UPDADE_COPM_ITEM_FIELD = 'CLIENT/UPDADE_COPM_ITEM_FIELD'

export function updateCopmItemField(tmpCopmItemId, field, value) {
  return dispatch => {
    dispatch({
      type: UPDADE_COPM_ITEM_FIELD,
      payload: {
        tmpCopmItemId,
        field,
        value
      }
    })
  }
}

export const CREATE_COPM_ITEM_REQUEST = 'CLIENT/CREATE_COPM_ITEM_REQUEST'
export const CREATE_COPM_ITEM_SUCCESS = 'CLIENT/CREATE_COPM_ITEM_SUCCESS'

export function createCopmItem(copmId, formObj) {
  return dispatch => {
    dispatch({
      types: [CREATE_COPM_ITEM_REQUEST, CREATE_COPM_ITEM_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/copm/create-copm-item`,
          data: {
            copm_id: copmId,
            ...formObj
          }
        }
      }
    })
  }
}

export const UPDATE_COPM_ITEM_REQUEST = 'CLIENT/UPDATE_COPM_ITEM_REQUEST'
export const UPDATE_COPM_ITEM_SUCCESS = 'CLIENT/UPDATE_COPM_ITEM_SUCCESS'

export function updateCopmItem(id, formObj) {
  return dispatch => {
    dispatch({
      types: [UPDATE_COPM_ITEM_REQUEST, UPDATE_COPM_ITEM_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/copm/update-copm-item/${id}`,
          data: {
            ...formObj
          }
        }
      }
    })
  }
}

export const DELETE_COPM_ITEM_REQUEST = 'CLIENT/DELETE_COPM_ITEM_REQUEST'
export const DELETE_COPM_ITEM_SUCCESS = 'CLIENT/DELETE_COPM_ITEM_SUCCESS'

export function deleteCopmItem(copmId, id) {
  return dispatch => {
    dispatch({
      types: [DELETE_COPM_ITEM_REQUEST, DELETE_COPM_ITEM_SUCCESS],
      payload: {
        copmId,
        id,
        request: {
          method: 'POST',
          url: `/copm/delete-copm-item/${id}`
        }
      }
    })
  }
}

export const DATA_LIST_LOAD_REQUEST = 'CLIENT/DATA_LIST_LOAD_REQUEST'
export const DATA_LIST_LOAD_SUCCESS = 'CLIENT/DATA_LIST_LOAD_SUCCESS'

export function getDataList(id, mode = 'problem') {
  return dispatch => {
    dispatch({
      types: [DATA_LIST_LOAD_REQUEST, DATA_LIST_LOAD_SUCCESS],
      payload: {
        mode,
        request: {
          method: 'GET',
          url: `/${mode}/get-list/${id}`
        }
      }
    })
  }
}

export const CREATE_DATA_REQUEST = 'CLIENT/CREATE_DATA_REQUEST'
export const CREATE_DATA_SUCCESS = 'CLIENT/CREATE_DATA_SUCCESS'

export function createData(clientId, mode = 'problem', formObj) {
  return dispatch => {
    dispatch({
      types: [CREATE_DATA_REQUEST, CREATE_DATA_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/${mode}/create`,
          data: {
            client_id: clientId,
            ...formObj
          }
        }
      }
    })
  }
}

export const PROBLEMS_LIST_LOAD_REQUEST = 'CLIENT/PROBLEMS_LIST_LOAD_REQUEST'
export const PROBLEMS_LIST_LOAD_SUCCESS = 'CLIENT/PROBLEMS_LIST_LOAD_SUCCESS'

export function getProblemsList(id) {
  return dispatch => {
    dispatch({
      types: [PROBLEMS_LIST_LOAD_REQUEST, PROBLEMS_LIST_LOAD_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: `/problem/get-problems-list/${id}`
        }
      }
    })
  }
}

export const LAST_COPM_ITEMS_LOAD_REQUEST =
  'CLIENT/LAST_COPM_ITEMS_LOAD_REQUEST'
export const LAST_COPM_ITEMS_LOAD_SUCCESS =
  'CLIENT/LAST_COPM_ITEMS_LOAD_SUCCESS'

export function getLastCopmItems(id) {
  return dispatch => {
    dispatch({
      types: [LAST_COPM_ITEMS_LOAD_REQUEST, LAST_COPM_ITEMS_LOAD_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: `/copm/get-last-copm-items/${id}`
        }
      }
    })
  }
}

export const UPDATE_DATA_REQUEST = 'CLIENT/UPDATE_DATA_REQUEST'
export const UPDATE_DATA_SUCCESS = 'CLIENT/UPDATE_DATA_SUCCESS'

export function updateData(id, mode = 'problem', formObj) {
  return dispatch => {
    dispatch({
      types: [UPDATE_DATA_REQUEST, UPDATE_DATA_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/${mode}/update/${id}`,
          data: {
            ...formObj
          }
        }
      }
    })
  }
}

export const DELETE_DATA_REQUEST = 'CLIENT/DELETE_DATA_REQUEST'
export const DELETE_DATA_SUCCESS = 'CLIENT/DELETE_DATA_SUCCESS'

export function deleteData(id, mode = 'problem') {
  return dispatch => {
    dispatch({
      types: [DELETE_DATA_REQUEST, DELETE_DATA_SUCCESS],
      payload: {
        id,
        request: {
          method: 'POST',
          url: `/${mode}/delete/${id}`
        }
      }
    })
  }
}

export const SERVICES_LOAD_REQUEST = 'CLIENT/SERVICES_LOAD_REQUEST'
export const SERVICES_LOAD_SUCCESS = 'CLIENT/SERVICES_LOAD_SUCCESS'

export function getServicesList(id, sort = null, filters = null, page = 1) {
  let additionalParams = { page }

  if (sort) {
    additionalParams.sort = sort.field
    additionalParams.sort_type = sort.type
  }

  if (filters) {
    additionalParams = {
      ...additionalParams,
      ...filters
    }
  }

  return dispatch => {
    dispatch({
      types: [SERVICES_LOAD_REQUEST, SERVICES_LOAD_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: `/service/get-services/${id}`,
          params: {
            ...additionalParams
          }
        }
      }
    })
  }
}

export const SERVICES_SPECIALISTS_LOAD_REQUEST =
  'CLIENT/SERVICES_SPECIALISTS_LOAD_REQUEST'
export const SERVICES_SPECIALISTS_LOAD_SUCCESS =
  'CLIENT/SERVICES_SPECIALISTS_LOAD_SUCCESS'

export function getServicesSpecialists(id) {
  return dispatch => {
    dispatch({
      types: [
        SERVICES_SPECIALISTS_LOAD_REQUEST,
        SERVICES_SPECIALISTS_LOAD_SUCCESS
      ],
      payload: {
        request: {
          method: 'GET',
          url: `/service/get-services-specialists/${id}`
        }
      }
    })
  }
}

export const CREATE_SERVICE_REQUEST = 'CLIENT/CREATE_SERVICE_REQUEST'
export const CREATE_SERVICE_SUCCESS = 'CLIENT/CREATE_SERVICE_SUCCESS'

export function createService(clientId, formObj) {
  return dispatch => {
    dispatch({
      types: [CREATE_SERVICE_REQUEST, CREATE_SERVICE_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/service/create`,
          data: {
            client_id: clientId,
            ...formObj
          }
        }
      }
    })
  }
}

export const UPDATE_SERVICE_REQUEST = 'CLIENT/UPDATE_SERVICE_REQUEST'
export const UPDATE_SERVICE_SUCCESS = 'CLIENT/UPDATE_SERVICE_SUCCESS'

export function updateService(id, formObj) {
  return dispatch => {
    dispatch({
      types: [UPDATE_SERVICE_REQUEST, UPDATE_SERVICE_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/service/update/${id}`,
          data: {
            ...formObj
          }
        }
      }
    })
  }
}

export const DELETE_SERVICE_REQUEST = 'CLIENT/DELETE_SERVICE_REQUEST'
export const DELETE_SERVICE_SUCCESS = 'CLIENT/DELETE_SERVICE_SUCCESS'

export function deleteService(id) {
  return dispatch => {
    dispatch({
      types: [DELETE_SERVICE_REQUEST, DELETE_SERVICE_SUCCESS],
      payload: {
        id,
        request: {
          method: 'POST',
          url: `/service/delete/${id}`
        }
      }
    })
  }
}

export const GET_SERVICE_REQUEST = 'CLIENT/GET_SERVICE_REQUEST'
export const GET_SERVICE_SUCCESS = 'CLIENT/GET_SERVICE_SUCCESS'

export function getService(id) {
  return dispatch => {
    dispatch({
      types: [GET_SERVICE_REQUEST, GET_SERVICE_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/service/get/${id}`
        }
      }
    })
  }
}

export const WAY_LOAD_REQUEST = 'CLIENT/WAY_LOAD_REQUEST'
export const WAY_LOAD_SUCCESS = 'CLIENT/WAY_LOAD_SUCCESS'

export function getWay(id, page = 1) {
  return dispatch => {
    dispatch({
      types: [WAY_LOAD_REQUEST, WAY_LOAD_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: `/clients/get-way/${id}`,
          params: {
            page
          }
        }
      }
    })
  }
}

export const ANKETA_LOAD_REQUEST = 'CLIENT/ANKETA_LOAD_REQUEST'
export const ANKETA_LOAD_SUCCESS = 'CLIENT/ANKETA_LOAD_SUCCESS'

export function getAnketa(id) {
  return dispatch => {
    dispatch({
      types: [ANKETA_LOAD_REQUEST, ANKETA_LOAD_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: `/anketa/get/${id}`
        }
      }
    })
  }
}

export const DELETE_ANKETA_REQUEST = 'CLIENT/DELETE_ANKETA_REQUEST'
export const DELETE_ANKETA_SUCCESS = 'CLIENT/DELETE_ANKETA_SUCCESS'

export function deleteAnketa(id) {
  return dispatch => {
    dispatch({
      types: [DELETE_ANKETA_REQUEST, DELETE_ANKETA_SUCCESS],
      payload: {
        id,
        request: {
          method: 'POST',
          url: `/anketa/reset/${id}`
        }
      }
    })
  }
}

export const CREATE_ANKETA_REQUEST = 'CLIENT/CREATE_ANKETA_REQUEST'
export const CREATE_ANKETA_SUCCESS = 'CLIENT/CREATE_ANKETA_SUCCESS'

export function createAnketa(id, questions, date) {
  return dispatch => {
    dispatch({
      types: [CREATE_ANKETA_REQUEST, CREATE_ANKETA_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/anketa/set/${id}`,
          data: {
            questions,
            date
          }
        }
      }
    })
  }
}
// client page END
