import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Form, DatePicker, Button, Icon, Spin, Select, Input } from 'antd'
import moment from 'moment'

import {
  setFieldForEmployment,
  createEmployment,
  updateEmployment
} from '../../../../redux/actions/EmploymentActions'

import taskTypes from '../../../../config/taskTypes.json'
import methodicalWorkTypes from '../../../../config/methodicalWorkTypes.json'

const { Option } = Select
const { TextArea } = Input

class EmploymentForm extends PureComponent {
  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { employmentForm } = this.props.employment
        let formObj = {}

        Object.keys(employmentForm).forEach(item => {
          if (
            employmentForm[item] !== null &&
            item !== 'id' &&
            item !== 'isOpen' &&
            item !== 'isSending'
          ) {
            formObj[item] = employmentForm[item]
          }
        })

        if (!employmentForm.id) {
          // create
          this.props.createEmployment(formObj)
        } else {
          // update
          this.props.updateEmployment(employmentForm.id, formObj)
        }
      }
    })
  }

  render() {
    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />
    const { getFieldDecorator } = this.props.form

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 }
      }
    }

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 20,
          offset: 6
        }
      }
    }

    const employmentForm = this.props.employment.employmentForm

    return employmentForm.isOpen ? (
      <Spin spinning={employmentForm.isSending} indicator={antIcon}>
        <Form
          {...formItemLayout}
          onSubmit={this.handleSubmit}
          autoComplete="off"
        >
          <Form.Item label="Дата">
            {getFieldDecorator('date', {
              rules: [{ required: true, message: 'Выберите дату' }],
              initialValue: employmentForm.date
                ? moment(employmentForm.date, 'YYYY-MM-DD')
                : null
            })(
              <DatePicker
                format={'DD.MM.YYYY'}
                placeholder="Выберите дату"
                onChange={(date, dateString) =>
                  this.props.setFieldForEmployment(
                    'date',
                    moment(dateString, 'DD.MM.YYYY').format('YYYY-MM-DD')
                  )
                }
              />
            )}
          </Form.Item>
          <Form.Item label="Время">
            {getFieldDecorator('time', {
              rules: [{ required: true, message: 'Выберите' }],
              initialValue: employmentForm.length
                ? employmentForm.length
                : undefined
            })(
              <Select
                placeholder="Выберите"
                onChange={value =>
                  this.props.setFieldForEmployment('length', value)
                }
              >
                <Option value={30}>30 мин</Option>
                <Option value={60}>1 час</Option>
                <Option value={120}>2 часа</Option>
                <Option value={180}>3 часа</Option>
                <Option value={240}>4 часа</Option>
                <Option value={300}>5 часов</Option>
                <Option value={360}>6 часов</Option>
                <Option value={420}>7 часов</Option>
                <Option value={480}>8 часов</Option>
                <Option value={540}>9 часов</Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Категория">
            {getFieldDecorator('category', {
              rules: [{ required: true, message: 'Выберите' }],
              initialValue: employmentForm.category
            })(
              <Select
                placeholder="Выберите"
                onChange={value => {
                  this.props.setFieldForEmployment('category', value)
                  this.props.setFieldForEmployment('type', null)
                }}
              >
                <Option value={'task'}>Задача</Option>
                <Option value={'methodical_work'}>Методическая работа</Option>
              </Select>
            )}
          </Form.Item>

          {employmentForm.category === 'task' && (
            <Form.Item label="Тип">
              {getFieldDecorator('task_type', {
                rules: [{ required: true, message: 'Выберите' }],
                initialValue: employmentForm.type
                  ? employmentForm.type
                  : undefined
              })(
                <Select
                  placeholder="Выберите"
                  onChange={value =>
                    this.props.setFieldForEmployment('type', value)
                  }
                >
                  {taskTypes.map(item => (
                    <Option key={item.key} value={item.key}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          )}

          {employmentForm.category === 'methodical_work' && (
            <Form.Item label="Тип">
              {getFieldDecorator('methodical_work_type', {
                rules: [{ required: true, message: 'Выберите' }],
                initialValue: employmentForm.type
                  ? employmentForm.type
                  : undefined
              })(
                <Select
                  placeholder="Выберите"
                  onChange={value =>
                    this.props.setFieldForEmployment('type', value)
                  }
                >
                  {methodicalWorkTypes.map(item => (
                    <Option key={item.key} value={item.key}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          )}

          <Form.Item label="Комментарий">
            {getFieldDecorator('comment', {
              rules: [{ required: true, message: 'Введите' }],
              initialValue: employmentForm.comment
            })(
              <TextArea
                rows={3}
                placeholder="Опишите рабочий процесс"
                onChange={e =>
                  this.props.setFieldForEmployment('comment', e.target.value)
                }
              />
            )}
          </Form.Item>

          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    ) : null
  }
}

const mapStateToProps = store => {
  return {
    employment: store.employment
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    setFieldForEmployment: (field, value) =>
      dispatch(setFieldForEmployment(field, value)),
    createEmployment: formObj => dispatch(createEmployment(formObj)),
    updateEmployment: (id, formObj) => dispatch(updateEmployment(id, formObj))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'EmploymentForm' })(EmploymentForm))
