export default function getSpecialistTypeName(type) {
  switch (type) {
    case 1:
      return 'физический терапевт'
    case 2:
      return 'эрготерапевт'
    case 3:
      return 'специалист по коммуникации'
    case 4:
      return 'психолог'
    case 5:
      return 'подбор и адаптация ТСР'
    case 6:
      return 'врач ФРМ'
    case 7:
      return 'анализ походки'
    case 8:
      return 'логопед'
    case 9:
      return 'специалист программы ранней помощи'
    case 10:
      return 'супервизор программы ранней помощи'
    default:
      return 'тип не указан'
  }
}
