import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Form, Input, Button, Icon, Spin, Table, InputNumber } from 'antd'

import {
  setFieldForRate,
  createRate,
  updateRate,
  setPriceForRate
} from '../../../../redux/actions/RatesActions'

import getServiceTypeName from '../../../../utils/getServiceTypeName'
import getSpecialistTypeName from '../../../../utils/getSpecialistTypeName'

class RateForm extends PureComponent {
  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { rateForm } = this.props.rates
        let formObj = {}

        Object.keys(rateForm).forEach(item => {
          if (
            rateForm[item] !== null &&
            item !== 'id' &&
            item !== 'isOpen' &&
            item !== 'isSending'
          ) {
            formObj[item] = rateForm[item]
          }
        })

        if (!rateForm.id) {
          // create
          this.props.createRate(formObj)
        } else {
          // update
          this.props.updateRate(rateForm.id, formObj)
        }
      }
    })
  }

  render() {
    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />
    const { getFieldDecorator } = this.props.form

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    }

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 16,
          offset: 8
        }
      }
    }

    const rateForm = this.props.rates.rateForm

    const columns = [
      {
        title: 'Тип специалиста',
        dataIndex: 'specialist_type',
        key: 'specialist_type'
      },
      {
        title: 'Цена',
        dataIndex: 'price',
        key: 'price'
      }
    ]

    let priceBlocks = []

    Object.keys(rateForm.relations).forEach(key => {
      let dataSource = []

      Object.keys(rateForm.relations[key]).forEach(specialistKey => {
        dataSource.push({
          key: specialistKey,
          specialist_type: getSpecialistTypeName(parseInt(specialistKey)),
          price: (
            <InputNumber
              min={0}
              value={rateForm.relations[key][specialistKey]}
              onChange={value =>
                this.props.setPriceForRate(key, specialistKey, value)
              }
            />
          )
        })
      })

      priceBlocks.push(
        <div style={{ marginBottom: '1rem' }} key={key}>
          <b>{getServiceTypeName(parseInt(key))}</b> <br />
          <Table dataSource={dataSource} columns={columns} pagination={false} />
        </div>
      )
    })

    return rateForm.isOpen ? (
      <Spin spinning={rateForm.isSending} indicator={antIcon}>
        <Form
          {...formItemLayout}
          onSubmit={this.handleSubmit}
          autoComplete="off"
        >
          <Form.Item label="Название">
            {getFieldDecorator('name', {
              rules: [{ required: true, message: 'Введите' }],
              initialValue: rateForm.name
            })(
              <Input
                placeholder="Введите"
                autoComplete="off"
                onChange={e =>
                  this.props.setFieldForRate('name', e.target.value)
                }
              />
            )}
          </Form.Item>
          <Form.Item label="Цены">{priceBlocks}</Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    ) : null
  }
}

const mapStateToProps = store => {
  return {
    rates: store.rates
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setFieldForRate: (field, value) => dispatch(setFieldForRate(field, value)),
    createRate: formObj => dispatch(createRate(formObj)),
    updateRate: (id, formObj) => dispatch(updateRate(id, formObj)),
    setPriceForRate: (type, specialist_type, price) =>
      dispatch(setPriceForRate(type, specialist_type, price))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'RateForm' })(RateForm))
