import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Icon, Spin, Button, Card, TimePicker, Select } from 'antd'
import moment from 'moment'
import { DeleteOutlined } from '@ant-design/icons'

import {
  addTimespan,
  deleteTimespan,
  editTimespan,
  getTimespans,
  updateTimespans
} from '../../../../redux/actions/ScheduleActions'

const { Option } = Select

class MyScheduleTab extends PureComponent {
  componentDidMount() {
    this.props.getTimespans()
  }

  generateCardItems(date, timespans) {
    return timespans.map(item => {
      return (
        <div className="MySchedule__timespan" key={item.id}>
          <TimePicker
            format="HH:mm"
            placeholder="Начало"
            minuteStep={30}
            value={item.time_s ? moment(item.time_s, 'HH:mm') : undefined}
            onChange={value =>
              this.props.editTimespan(
                date,
                item.id,
                'time_s',
                value.format('HH:mm')
              )
            }
          />
          {' – '}
          <TimePicker
            format="HH:mm"
            placeholder="Окончание"
            minuteStep={30}
            value={item.time_e ? moment(item.time_e, 'HH:mm') : undefined}
            onChange={value =>
              this.props.editTimespan(
                date,
                item.id,
                'time_e',
                value.format('HH:mm')
              )
            }
          />{' '}
          <Select
            value={item.type}
            style={{ width: 120 }}
            onChange={value =>
              this.props.editTimespan(date, item.id, 'type', value)
            }
          >
            <Option value={0}>онлайн</Option>
            <Option value={1}>очно</Option>
            <Option value={2}>онлайн/очно</Option>
          </Select>
          <span
            className="Clients__control-buttons"
            style={{ marginLeft: '10px' }}
          >
            <a
              href="/"
              onClick={e => {
                e.preventDefault()
                this.props.deleteTimespan(date, item.id)
              }}
            >
              <DeleteOutlined />
            </a>
          </span>
        </div>
      )
    })
  }

  updateTimespans() {
    const { mySchedule } = this.props.schedule

    let params = { timespans: [] }

    mySchedule.items.forEach(item => {
      item.timespans.forEach(timespan => {
        if (timespan.time_s && timespan.time_e) {
          params.timespans.push({
            date: item.date,
            time_s: timespan.time_s,
            time_e: timespan.time_e,
            type: timespan.type
          })
        }
      })
    })

    this.props.updateTimespans(params)
  }

  render() {
    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />

    const { mySchedule } = this.props.schedule

    const cards = mySchedule.items.map((item, index) => {
      return (
        <Card
          type="inner"
          title={`${moment(item.date, 'YYYY-MM-DD').format('DD.MM, ddd')}`}
          className="MySchedule__card"
          key={index}
        >
          {this.generateCardItems(item.date, item.timespans)}
          <Button onClick={() => this.props.addTimespan(item.date)}>
            Добавить
          </Button>
        </Card>
      )
    })

    return (
      <Spin spinning={mySchedule.isLoading} indicator={antIcon}>
        <p>Укажите свободные слоты для приема первичных клиентов</p>
        <Button type="primary" onClick={() => this.updateTimespans()}>
          Сохранить
        </Button>
        <p></p>
        {cards}
        <Button type="primary" onClick={() => this.updateTimespans()}>
          Сохранить
        </Button>
      </Spin>
    )
  }
}

const mapStateToProps = store => {
  return {
    schedule: store.schedule
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addTimespan: date => dispatch(addTimespan(date)),
    deleteTimespan: (date, id) => dispatch(deleteTimespan(date, id)),
    editTimespan: (date, id, field, value) =>
      dispatch(editTimespan(date, id, field, value)),
    getTimespans: () => dispatch(getTimespans()),
    updateTimespans: items => dispatch(updateTimespans(items))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyScheduleTab)
