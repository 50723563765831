import axios from 'axios'
import { message } from 'antd'

export default function downloadFile(url, fileName, token, callback = false) {
  const link = document.createElement('a')
  link.target = '_blank'
  link.download = fileName
  axios
    .get(url, {
      headers: { authorization: `Bearer ${token}` },
      responseType: 'blob'
    })
    .then(res => {
      if (callback) {
        callback()
      }
      if (res.status === 200) {
        link.href = URL.createObjectURL(new Blob([res.data]))
        link.click()
      } else {
        message.info('Нет данных')
      }
    })
}
