import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Button, Icon, DatePicker, Spin, Form, Input } from 'antd'
import moment from 'moment'
import uuid from 'uuid'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import {
  setField,
  setInterviewAnswer,
  createItem,
  updateItem
} from '../../../../../redux/actions/ClientEstimatesActions'

const { TextArea } = Input

class InterviewForm extends PureComponent {
  handleSubmit = e => {
    e.preventDefault()

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { interviewForm } = this.props.clientEstimates
        const { clientId } = this.props
        let formObj = {}

        Object.keys(interviewForm).forEach(item => {
          if (
            interviewForm[item] !== null &&
            item !== 'id' &&
            item !== 'isOpen' &&
            item !== 'isSending'
          ) {
            formObj[item] = interviewForm[item]
          }
        })

        if (!interviewForm.id) {
          // create
          this.props.createItem(clientId, formObj)
        } else {
          // update
          this.props.updateItem(interviewForm.id, formObj)
        }
      }
    })
  }

  componentDidMount() {
    const { interviewForm } = this.props.clientEstimates

    const sections = [
      // section 1
      {
        uuid: uuid(),
        name: 'Взрослый',
        questions: [
          {
            uuid: uuid(),
            type: 'textarea',
            question:
              'Стали ли Вам ясней сигналы Вашего ребенка / дифференцированные сигналы?'
          },
          {
            uuid: uuid(),
            type: 'textarea',
            question:
              'Каким образом изменилась коммуникативная направленность ребенка? (Вы / члены семьи / другие люди / дети)'
          },
          {
            uuid: uuid(),
            type: 'textarea',
            question:
              'Изменилось ли понимание обращенной речи / пассивный / активный словарь ребенка?'
          },
          {
            uuid: uuid(),
            type: 'textarea',
            question:
              'С кем ребенок использует АДК? С кем стабильно? С кем иногда / ситуативно?'
          },
          {
            uuid: uuid(),
            type: 'textarea',
            question:
              'Как другие члены семьи относятся к использованию выбранного способа АДК?'
          },
          {
            uuid: uuid(),
            type: 'textarea',
            question:
              'Замечали ли Вы, что ребенок рад от того, что Вы поняли его / он смог выразить себя / свое желание, идею?'
          },
          {
            uuid: uuid(),
            type: 'textarea',
            question:
              'Замечали ли Вы, что ребенок расстроен / рассержен, что Вам не удалось понять его / он не смог выразить себя / свое желание, идею?'
          }
        ]
      },
      // section 2
      {
        uuid: uuid(),
        name: 'Ребенок',
        questions: [
          {
            uuid: uuid(),
            type: 'textarea',
            question:
              'Замечали ли Вы ситуации, в которых ребенок не может выразить себя посредством АДК? Из-за ограничений способа?'
          },
          {
            uuid: uuid(),
            type: 'textarea',
            question:
              'Какие изменения произошли в распорядке дня вашего ребенка?'
          },
          {
            uuid: uuid(),
            type: 'textarea',
            question:
              'Как Вам удается включать использование АДК в ваши рутины?'
          },
          {
            uuid: uuid(),
            type: 'textarea',
            question:
              'Как изменилась игра / продуктивная деятельность (в зависимости от возраста) вашего ребенка?'
          },
          {
            uuid: uuid(),
            type: 'textarea',
            question:
              'Как изменились отношения вашего ребенка с другими детьми? Как изменилось его поведение в группе?'
          }
        ]
      },
      // section 3
      {
        uuid: uuid(),
        name: 'Способ АДК',
        questions: [
          {
            uuid: uuid(),
            type: 'textarea',
            question: 'Как вы используете АДК?'
          },
          {
            uuid: uuid(),
            type: 'textarea',
            question:
              'В каких ситуациях Вы используете АДК? Какие из этих ситуаций являются стабильными? Какие возникают иногда?'
          },
          {
            uuid: uuid(),
            type: 'textarea',
            question:
              'Бывают ли дни, когда не удается использовать АДК? Почему? (время, скорость, удобство, обстановка)'
          },
          {
            uuid: uuid(),
            type: 'textarea',
            question: 'Требуется ли изменение / адаптация способа АДК?'
          },
          {
            uuid: uuid(),
            type: 'textarea',
            question:
              'Требуется ли сокращение диапазона? (способ, ситуация, люди)'
          },
          {
            uuid: uuid(),
            type: 'textarea',
            question: 'В чем вы видите ограничения данного способа АДК?'
          }
        ]
      }
    ]

    if (!interviewForm.id) {
      this.props.setField('sections', sections)
    }
  }

  render() {
    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />
    const { getFieldDecorator } = this.props.form

    const { clientEstimates } = this.props
    const { interviewForm } = clientEstimates

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      }
    }

    return (
      <Spin spinning={interviewForm.isSending} indicator={antIcon}>
        <Form
          {...formItemLayout}
          onSubmit={this.handleSubmit}
          autoComplete="off"
        >
          <Form.Item>
            {getFieldDecorator('date', {
              rules: [{ required: true, message: 'Выберите дату' }],
              initialValue: interviewForm.date
                ? moment(interviewForm.date, 'YYYY-MM-DD')
                : null
            })(
              <DatePicker
                format={'DD.MM.YYYY'}
                placeholder="Выберите дату"
                onChange={(date, dateString) =>
                  this.props.setField(
                    'date',
                    moment(dateString, 'DD.MM.YYYY').format('YYYY-MM-DD')
                  )
                }
              />
            )}
          </Form.Item>

          {interviewForm.sections.map((section, sectionIndex) => (
            <div key={section.uuid}>
              <h3 className="Interview__header">{section.name}</h3>
              {section.questions.map((question, questionIndex) => (
                <div className="Interview__answer-block" key={question.uuid}>
                  <div className="Interview__question">
                    <b>
                      {questionIndex + 1}. {question.question}
                    </b>
                  </div>
                  {question.type === 'ckeditor' ? (
                    <CKEditor
                      editor={ClassicEditor}
                      value={question.answer ? question.answer : ''}
                      config={{
                        toolbar: [
                          'heading',
                          'bold',
                          'italic',
                          'link',
                          'blockQuote',
                          'bulletedList',
                          'numberedList'
                        ],
                        heading: {
                          options: [
                            {
                              model: 'paragraph',
                              title: 'Параграф',
                              class: 'ck-heading_paragraph'
                            },
                            {
                              model: 'heading2',
                              view: 'h2',
                              title: 'Заголовок',
                              class: 'ck-heading_heading2'
                            }
                          ]
                        }
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        this.props.setInterviewAnswer(
                          sectionIndex,
                          questionIndex,
                          data
                        )
                      }}
                    />
                  ) : (
                    <TextArea
                      rows={3}
                      placeholder="Введите текст"
                      autoComplete="off"
                      value={question.answer ? question.answer : ''}
                      onChange={e => {
                        this.props.setInterviewAnswer(
                          sectionIndex,
                          questionIndex,
                          e.target.value
                        )
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
          ))}

          <div className="Interview__answer-block">
            <h3 className="Interview__header">Комментарий</h3>
            <CKEditor
              editor={ClassicEditor}
              data={interviewForm.comment}
              config={{
                toolbar: [
                  'heading',
                  'bold',
                  'italic',
                  'link',
                  'blockQuote',
                  'bulletedList',
                  'numberedList'
                ],
                heading: {
                  options: [
                    {
                      model: 'paragraph',
                      title: 'Параграф',
                      class: 'ck-heading_paragraph'
                    },
                    {
                      model: 'heading2',
                      view: 'h2',
                      title: 'Заголовок',
                      class: 'ck-heading_heading2'
                    }
                  ]
                }
              }}
              onChange={(event, editor) => {
                const data = editor.getData()
                this.props.setField('comment', data)
              }}
            />
          </div>

          <Button
            type="primary"
            htmlType="submit"
            className="Interview__form-save-button"
          >
            Сохранить
          </Button>

          <p></p>

          {interviewForm.id && (
            <Form.Item>
              <p>
                Создана: {interviewForm.user_creating.user_creating_name}{' '}
                {interviewForm.user_creating.user_creating_surname},{' '}
                {moment(interviewForm.date_creating).format('DD.MM.YYYY HH:mm')}
                <br />
                Последнее редактирование:{' '}
                {interviewForm.user_last_editing.user_last_editing_name}{' '}
                {interviewForm.user_last_editing.user_last_editing_surname},{' '}
                {moment(interviewForm.date_last_editing).format(
                  'DD.MM.YYYY HH:mm'
                )}
              </p>
            </Form.Item>
          )}
        </Form>
      </Spin>
    )
  }
}

const mapStateToProps = store => {
  return {
    clientEstimates: store.clientEstimates
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    setField: (field, value) =>
      dispatch(setField(field, value, 'interviewForm')),
    setInterviewAnswer: (sectionIndex, questionIndex, answer) =>
      dispatch(setInterviewAnswer(sectionIndex, questionIndex, answer)),
    createItem: (clientId, formObj) =>
      dispatch(createItem(clientId, 'interview', 'interview', formObj)),
    updateItem: (id, formObj) =>
      dispatch(updateItem(id, 'interview', 'interview', formObj))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'InterviewForm' })(InterviewForm))
