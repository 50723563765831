import axios from 'axios'

import { USER_LOGOUT } from '../actions/UserActions'
import { SERVER_ERROR } from '../actions/ServerActions'

export const middlewareConfig = {
  interceptors: {
    request: [
      {
        success: function({ getState, dispatch, getSourceAction }, req) {
          const user = getState().user
          const token = user && user.authorized ? user.attributes.token : ''
          req.headers.authorization = token ? `Bearer ${token}` : ''
          return req
        },
        error: function({ getState, dispatch, getSourceAction }, error) {
          console.error(error)
          return error
        }
      }
    ],
    response: [
      {
        success: function({ getState, dispatch, getSourceAction }, res) {
          return Promise.resolve(res.data)
        },
        error: function({ getState, dispatch, getSourceAction }, error) {
          const status = error.response.status
          switch (status) {
            case 401:
              dispatch({
                type: USER_LOGOUT
              })
              break
            case 404:
              dispatch({
                type: SERVER_ERROR,
                payload: {
                  status,
                  text: 'Неверный адрес запроса'
                }
              })
              break
            case 500:
              dispatch({
                type: SERVER_ERROR,
                payload: {
                  status,
                  text: 'Во время выполнения запроса произошла ошибка'
                }
              })
              break
            default:
              dispatch({
                type: SERVER_ERROR,
                payload: {
                  status,
                  text:
                    'Во время выполнения запроса произошла неизвестная ошибка. Обратитесь к администратору системы.'
                }
              })
              console.error(error)
              break
          }
          return Promise.reject(error)
        }
      }
    ]
  }
}

export const client = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  responseType: 'json'
})
