import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  Steps,
  Button,
  Table,
  Select,
  Input,
  Icon,
  Spin,
  Modal,
  DatePicker
} from 'antd'
import uuid from 'uuid'
import moment from 'moment'

import {
  getAnketa,
  deleteAnketa,
  createAnketa,
  setFieldForClient
} from '../../../../redux/actions/ClientActions'

const { Step } = Steps
const { Option } = Select
const { TextArea } = Input

class AnketaForm extends PureComponent {
  state = {
    current: 0,
    steps: [],
    answers: {},

    isOpenModal: false
  }

  componentDidMount() {
    this.openAnketaDateModal = this.openAnketaDateModal.bind(this)
    this.handleOk = this.handleOk.bind(this)
    this.handleCancel = this.handleCancel.bind(this)

    this.props.getAnketa(this.props.clientId)

    // 3 типа: чекбоксы, радио и текст. Первые два могут быть селектом с одним или множественным выбором
    const steps = [
      // step 1
      {
        name: 'ОБЛАСТИ РАЗВИТИЯ: Двигательные возможности ребенка',
        disabled: false,
        questions: [
          {
            uuid: uuid(),
            name: 'Как бы вы охарактеризовали двигательные возможности ребенка',
            type: 'select',
            mode: 'multiple',
            options: [
              'Может ходить',
              'Может стоять - учится ходить',
              'Может сидеть - учится стоять',
              'Может сидеть - учится сидеть',
              'Может лежать и самостоятельно организует позу лежа'
            ]
          },
          {
            uuid: uuid(),
            name: 'Может ли ребенок перевернуться со спины на живот и обратно',
            type: 'select',
            mode: 'default',
            options: ['Да', 'Нет']
          },
          {
            uuid: uuid(),
            name: 'Может ли ребенок самостоятельно сесть',
            type: 'select',
            mode: 'default',
            options: ['Да', 'Нет']
          },
          {
            uuid: uuid(),
            name: 'Может ли ребенок перейти из позы сидя в положение лежа',
            type: 'select',
            mode: 'default',
            options: ['Да', 'Нет']
          },
          {
            uuid: uuid(),
            name: 'Может ли ребенок встать на четвереньки',
            type: 'select',
            mode: 'default',
            options: ['Да', 'Нет']
          },
          {
            uuid: uuid(),
            name: 'Может ли ребенок встать из положения сидя',
            type: 'select',
            mode: 'default',
            options: ['Да', 'Нет']
          },
          {
            uuid: uuid(),
            name:
              'Отметьте способы, с помощью которых ребенок может переместиться из одного места в другое',
            type: 'select',
            mode: 'tags',
            options: [
              'Перекатывается',
              'Ползает на животе',
              'Ползает на четвереньках',
              'Прыгает на попе',
              'Ходит вдоль опоры',
              'Ходит с ходунками',
              'Ходит с тростями',
              'Ходит за руку',
              'Ходит самостоятельно',
              'Перемещается на активной коляске',
              'Ребенка перевозят на коляске или переносят на руках'
            ]
          },
          {
            uuid: uuid(),
            name:
              'Есть ли у вашего ребенка проблемы с движениями? Если есть, опишите их подробно. Если нет, напишите НЕТ.',
            type: 'text'
          }
        ]
      },
      // step 2
      {
        name: 'Вопросы о ТСР (технические средства реабилитации)',
        disabled: false,
        questions: [
          {
            uuid: uuid(),
            name:
              'Используете ли вы ТСР (для поддержания позы и перемещения) для своего ребенка',
            type: 'select',
            mode: 'default',
            options: ['Да', 'Нет'],
            trigger: value => {
              let newSteps = new Array(...this.state.steps)

              if (value === 'Нет') {
                newSteps[2].disabled = true
              } else {
                newSteps[2].disabled = false
              }
              this.setState({ steps: newSteps })
            }
          }
        ]
      },
      // step 3
      {
        name: 'Вопросы о ТСР',
        disabled: true,
        questions: [
          {
            uuid: uuid(),
            name: 'Какие ТСР вы используете',
            type: 'select',
            mode: 'tags',
            options: [
              'Специальное кресло для кормления',
              'Комнатная коляска',
              'Прогулочная коляска',
              'Активная коляска',
              'Санитарный стул',
              'Вертикализатор',
              'Ходунки',
              'Трости'
            ]
          },
          {
            uuid: uuid(),
            name: 'Соответствуют ли имеющиеся  ТСР возможностям вашего ребенка',
            type: 'select',
            mode: 'default',
            options: ['Да', 'Нет']
          },
          {
            uuid: uuid(),
            name:
              'Есть ли у вас вопросы по использованию ТСР? Если есть, перечислите. Если нет, напишите НЕТ.',
            type: 'text'
          }
        ]
      },
      // step 4
      {
        name: 'ОБЛАСТИ РАЗВИТИЯ: Коммуникация',
        disabled: false,
        questions: [
          {
            uuid: uuid(),
            name: 'Какие способы коммуникации использует ваш ребенок',
            type: 'select',
            mode: 'multiple',
            options: [
              'Ранние звуки (звуки, похожие на те, которые произносит младенец)',
              'Выражение лица',
              'Телодвижения',
              'Взгляд',
              'Жесты',
              'Символы',
              'Язык'
            ]
          },
          {
            uuid: uuid(),
            name:
              'Ваш ребенок может сообщить вам, чего он хочет, задать вопрос и отправлять другие коммуникативные сообщения (любым способом)',
            type: 'select',
            mode: 'default',
            options: ['Да', 'Нет']
          },
          {
            uuid: uuid(),
            name:
              'Понимает ли ребенок, о чем вы говорите, просите, спрашиваете',
            type: 'select',
            mode: 'default',
            options: ['Да', 'Нет']
          },
          {
            uuid: uuid(),
            name: 'Понимают ли вашего ребенка незнакомые люди',
            type: 'select',
            mode: 'default',
            options: ['Да', 'Нет']
          },
          {
            uuid: uuid(),
            name: 'Понимает ли ваш ребенок обращения незнакомых людей',
            type: 'select',
            mode: 'default',
            options: ['Да', 'Нет']
          },
          {
            uuid: uuid(),
            name:
              'Замечаете ли вы, что скорость общения у вашего ребенка снижена, ему требуется больше времени, чтобы отправить или понять коммуникативное сообщение',
            type: 'select',
            mode: 'default',
            options: ['Да', 'Нет']
          },
          {
            uuid: uuid(),
            name:
              'Есть ли у ребенка проблемы с коммуникацией? Если есть, опишите их подробно. Если нет, напишите НЕТ.',
            type: 'text'
          }
        ]
      },
      // step 5
      {
        name: 'ОБЛАСТИ РАЗВИТИЯ: Прием пищи',
        disabled: false,
        questions: [
          {
            uuid: uuid(),
            name: 'Как ваш ребенок ест и пьет',
            type: 'select',
            mode: 'multiple',
            options: [
              'Ваш ребенок может глотать только жидкую пищу',
              'Ваш ребенок может пережевывать пищу',
              'Ваш ребенок может откусывать кусочки от целого куска',
              'Ваш ребенок справляется с волокнистой, твердой и неоднородной консистенцией пищи',
              'Ваш ребенок может самостоятельно донести ложку или вилку с едой до рта',
              'Ваш ребенок самостоятельно  пьет из обычной чашки',
              'Ваш ребенок пьет жидкость отдельными глотками',
              'Ваш ребенок поперхивается и кашляет во время еды',
              'Ваш ребенок поперхивается и кашляет во время питья',
              'Ребенок питается через зонд или гастростому (см. описание вопроса!)'
            ]
          },
          {
            uuid: uuid(),
            name:
              'Есть ли у вашего ребенка проблемы с приемом пищи и жидкости? Если есть, опишите их подробно. Если нет, напишите НЕТ.',
            type: 'text'
          }
        ]
      },
      // step 6
      {
        name: 'ОБЛАСТИ РАЗВИТИЯ: Функция руки',
        disabled: false,
        questions: [
          {
            uuid: uuid(),
            name:
              'Как ваш ребенок  использует руки при выполнении какого-либо вида деятельности',
            type: 'select',
            mode: 'multiple',
            options: [
              'Легко и успешно оперирует  руками, даже при действиях с мелкими предметами.',
              'Оперирует большинством предметов, используя  руки, однако скорость и качество действий снижены.',
              'Успешно использует  руки в деятельности только в случае, если пространство организовано определенным  образом, темп выполнения заметно снижен.',
              'Использует руки для манипулирования ограниченным кругом предметов, может выполнять отдельные компоненты целого действия.',
              'Все манипуляции с предметами требуют поддержки.'
            ]
          },
          {
            uuid: uuid(),
            name:
              'Есть ли у вашего ребенка проблемы с действиями руками. Если есть, опишите их подробно. Если нет, напишите НЕТ.',
            type: 'text'
          }
        ]
      },
      // step 7
      {
        name: 'Предпочтения и интересы ребенка',
        disabled: false,
        questions: [
          {
            uuid: uuid(),
            name: 'Какие игрушки и игры любит ваш ребенок',
            type: 'text'
          },
          {
            uuid: uuid(),
            name: 'Чем ребенок любит заниматься',
            type: 'text'
          },
          {
            uuid: uuid(),
            name:
              'Какая деятельность, занятия или ежедневные дела (любые) вызывают у ребенка неприятие? Например, некоторые дети не любят мыть голову или ездить в машине и т.д.',
            type: 'text'
          },
          {
            uuid: uuid(),
            name:
              'Есть ли у ребенка какие-либо сенсорные предпочтения или неприятие каких-либо сенсорных стимулов? Например, некоторые дети не переносят громкие звуки, другие  готовы качаться целый день на качелях или прыгать на батуте и т.д.',
            type: 'text'
          }
        ]
      },
      // step 8
      {
        name: 'Комментарий',
        disabled: false,
        questions: [
          {
            uuid: uuid(),
            name:
              'Если вы хотите уточнить свой запрос, подробнее описать свою ситуацию или выразить какие-то иные пожелания, заполните это поле.',
            type: 'text'
          }
        ]
      }
    ]

    this.setState({ steps })
  }

  goNextStep() {
    this.setState({
      current: this.state.current + 1
    })
  }

  goPrevStep() {
    this.setState({
      current: this.state.current - 1
    })
  }

  generateQuestion(question) {
    let controlElement = null

    const { answers } = this.state

    switch (question.type) {
      case 'select':
        let selectPlaceholder = ''
        if (question.mode === 'multiple') {
          selectPlaceholder = 'один или несколько вариантов'
        } else if (question.mode === 'tags') {
          selectPlaceholder = 'один или несколько вариантов или введите свой'
        } else {
          selectPlaceholder = 'один вариант'
        }

        controlElement = (
          <Select
            mode={question.mode}
            placeholder={`Выберите ${selectPlaceholder}`}
            style={{ width: '100%' }}
            value={answers[question.uuid] ? answers[question.uuid] : []}
            onChange={value => {
              if (question.trigger) {
                question.trigger(value)
              }

              this.setState({
                answers: {
                  ...answers,
                  [question.uuid]: value
                }
              })
            }}
          >
            {question.options.map((item, index) => {
              return (
                <Option key={index} value={item}>
                  {item}
                </Option>
              )
            })}
          </Select>
        )
        break
      case 'text':
        controlElement = (
          <TextArea
            rows={4}
            placeholder="Введите текст"
            autoComplete="off"
            value={answers[question.uuid] ? answers[question.uuid] : ''}
            onChange={e => {
              this.setState({
                answers: {
                  ...answers,
                  [question.uuid]: e.target.value
                }
              })
            }}
          />
        )
        break
      default:
        return null
    }
    return (
      <div className="Anketa__question" key={question.uuid}>
        {question.name}
        {controlElement}
      </div>
    )
  }

  sendAnketa() {
    let questions = []

    this.state.steps.forEach(step => {
      step.questions.forEach(question => {
        let answer = this.state.answers[question.uuid]

        if (typeof answer === 'object') {
          answer = answer.join('; ')
        }

        if (answer) {
          questions.push({
            uuid: question.uuid,
            question: question.name,
            answer
          })
        }
      })
    })

    this.props.createAnketa(
      this.props.clientId,
      questions,
      this.props.client.anketaForm.date
    )
  }

  handleOk() {
    this.setState({ isOpenModal: false })
    this.props.setFieldForClient('isOpen', true)
  }

  handleCancel() {
    this.setState({ isOpenModal: false })
  }

  openAnketaDateModal() {
    this.setState({ isOpenModal: true })
  }

  render() {
    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />

    const anketaForm = this.props.client.anketaForm
    const anketa = this.props.client.anketa

    const visualSteps = this.state.steps
      .filter(step => !step.disabled)
      .map((step, index) => {
        return {
          title: `Раздел ${index + 1}`,
          content: (
            <div>
              {step.questions.map(question => this.generateQuestion(question))}
            </div>
          )
        }
      })

    const current = this.state.current

    let isAllStepQuestionsAnswered = true

    if (this.state.steps.filter(step => !step.disabled)[current]) {
      const currentStep = this.state.steps.filter(step => !step.disabled)[
        current
      ]
      currentStep.questions.forEach(item => {
        let answer = this.state.answers[item.uuid]
        if (!answer || (answer && answer.length === 0)) {
          isAllStepQuestionsAnswered = false
        }
      })
    }

    let questions = []

    this.state.steps.forEach(item => {
      questions.push(...item.questions)
    })

    const dataSource = questions.map((itemQuestion, index) => {
      let colAnswers = {}

      anketa.items.forEach(item => {
        let answerObj = null
        let answerStr = ''

        answerObj = item.questions.find(
          question => question.question === itemQuestion.name
        )

        answerStr = answerObj ? answerObj.answer : '–'

        colAnswers[
          'answer_' + moment(item.date).format('DD_MM_YYYY')
        ] = answerStr
      })

      return {
        key: itemQuestion.uuid,
        question: itemQuestion.name,
        type: itemQuestion.type,
        mode: itemQuestion.mode ? itemQuestion.mode : false,
        ...colAnswers
      }
    })

    const dateColumns = anketa.items.map(item => ({
      title: moment(item.date).format('DD.MM.YYYY'),
      dataIndex: 'answer_' + moment(item.date).format('DD_MM_YYYY'),
      width: 350,
      render: (text, record) => {
        if (
          record.type === 'select' &&
          (record.mode === 'multiple' || record.mode === 'tags') &&
          text !== '–'
        ) {
          text = '• ' + text
          text = text.replaceAll(';', ';<br/>• ')

          return <span dangerouslySetInnerHTML={{ __html: text }}></span>
        } else {
          return text
        }
      }
    }))

    const columns = [
      {
        title: 'Вопрос',
        dataIndex: 'question',
        //fixed: 'left',
        width: 350
      },
      ...dateColumns
    ]

    return (
      <>
        {anketaForm.isOpen ? (
          <Spin spinning={anketaForm.isLoading} indicator={antIcon}>
            <Steps current={current}>
              {visualSteps.map(item => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            {visualSteps[current] && (
              <div className="Anketa__steps-content">
                {visualSteps[current].content}
              </div>
            )}
            <div className="Anketa__steps-action">
              {current < visualSteps.length - 1 && (
                <Button
                  type="primary"
                  onClick={() => this.goNextStep()}
                  disabled={!isAllStepQuestionsAnswered}
                >
                  Далее
                </Button>
              )}
              {current === visualSteps.length - 1 && (
                <Button
                  type="primary"
                  onClick={() => this.sendAnketa()}
                  disabled={!isAllStepQuestionsAnswered}
                >
                  Сохранить
                </Button>
              )}
              {current > 0 && (
                <Button
                  style={{ margin: '0 8px' }}
                  onClick={() => this.goPrevStep()}
                >
                  Назад
                </Button>
              )}
            </div>
          </Spin>
        ) : (
          <>
            <Button type="primary" onClick={this.openAnketaDateModal}>
              Заполнить анкету
            </Button>
            <Modal
              title="Дата заполнения"
              visible={this.state.isOpenModal}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
            >
              <DatePicker
                placeholder="Выберите дату"
                format={'DD.MM.YYYY'}
                value={
                  anketaForm.date ? moment(anketaForm.date, 'YYYY-MM-DD') : null
                }
                allowClear={false}
                onChange={(date, dateString) => {
                  this.props.setFieldForClient(
                    'date',
                    moment(dateString, 'DD.MM.YYYY').format('YYYY-MM-DD')
                  )
                }}
              />
            </Modal>
          </>
        )}

        <p></p>

        {anketa.items.length > 0 && (
          <Spin spinning={anketa.isLoading} indicator={antIcon}>
            <Table
              dataSource={dataSource}
              columns={columns}
              pagination={false}
              scroll={{ x: 'calc(100%)' }}
            />
          </Spin>
        )}
      </>
    )
  }
}

const mapStateToProps = store => {
  return {
    client: store.client
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getAnketa: id => dispatch(getAnketa(id)),
    deleteAnketa: id => dispatch(deleteAnketa(id)),
    createAnketa: (id, questions, date) =>
      dispatch(createAnketa(id, questions, date)),
    setFieldForClient: (field, value) =>
      dispatch(setFieldForClient(field, value, 'anketaForm'))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AnketaForm)
