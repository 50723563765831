import axios from 'axios'

const url = process.env.REACT_APP_API_HOST

function logIn(login, password) {
  return axios.post(url + '/users/authorization', {
    login,
    password
  })
}

const UserAPI = {
  logIn
}

export default UserAPI
