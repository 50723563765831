import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { Tabs } from 'antd'
import qs from 'qs'

import { history } from '../../../redux/configureStore'

import hasUserAccess from '../../../utils/hasUserAccess'

import TableTab from './Components/TableTabComponent'

const { TabPane } = Tabs

class Employment extends PureComponent {
  changeTab(key) {
    history.push({
      search: qs.stringify({ tab: key })
    })
  }

  render() {
    const userEmploymentAllTabAccess = hasUserAccess(
      this.props.settings.params,
      'employment_all_tab_access',
      this.props.user.id
    )

    let defaultActiveKey = userEmploymentAllTabAccess ? 'all' : 'my'
    const getVars = qs.parse(document.location.search.substr(1))
    if (getVars.tab) {
      defaultActiveKey = getVars.tab
    }

    return (
      <div>
        <Helmet>
          <title>Занятость</title>
        </Helmet>
        <h1>Занятость</h1>
        <Tabs
          activeKey={defaultActiveKey}
          type="card"
          onChange={key => this.changeTab(key)}
          destroyInactiveTabPane
        >
          {userEmploymentAllTabAccess && (
            <TabPane tab="Общая" key="all">
              <TableTab disableEditing={true} />
            </TabPane>
          )}
          <TabPane tab="Личная" key="my">
            <TableTab
              disableUserFilter={true}
              disableEmployeeColumn={true}
              showForCurrentUserOnly={true}
            />
          </TabPane>
        </Tabs>
      </div>
    )
  }
}

const mapStateToProps = store => {
  return {
    user: store.user,
    settings: store.settings
  }
}

export default connect(mapStateToProps)(Employment)
