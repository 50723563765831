import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  Form,
  DatePicker,
  Button,
  Icon,
  Spin,
  Select,
  Input,
  Modal
} from 'antd'
import moment from 'moment'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import {
  setFieldForClient,
  createData,
  getProblemsList,
  getLastCopmItems,
  updateData
} from '../../../../redux/actions/ClientActions'

const { Option } = Select

class DataForm extends PureComponent {
  state = {
    problemsList: [],
    isOpenCopmModal: false,
    selectedCopmItem: ''
  }

  componentDidMount() {
    this.props.getProblemsList(this.props.clientId)
    this.props.getLastCopmItems(this.props.clientId)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      problemsList: nextProps.client.data.problemsList
    })
  }

  handleSubmit = e => {
    e.preventDefault()

    const { mode, clientId } = this.props

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { dataForm } = this.props.client
        let formObj = {}

        Object.keys(dataForm).forEach(item => {
          if (
            dataForm[item] !== null &&
            item !== 'id' &&
            item !== 'isOpen' &&
            item !== 'isSending'
          ) {
            formObj[item] = dataForm[item]
          }
        })

        if (parseInt(formObj.status) !== 1) {
          formObj.date_of_achievement = null
        }

        if (!dataForm.id) {
          // create
          this.props.createData(clientId, mode, formObj)
        } else {
          // update
          this.props.updateData(dataForm.id, mode, formObj)
        }
      }
    })
  }

  searchProblem = value => {
    value = value.toLowerCase()
    let problemsList = []
    this.props.client.data.problemsList.forEach(item => {
      if (item.name.toLowerCase().includes(value)) {
        problemsList.push(item)
      }
    })
    this.setState({ problemsList })
  }

  render() {
    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />
    const { getFieldDecorator } = this.props.form
    const { mode } = this.props

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 }
      }
    }

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 20,
          offset: 6
        }
      }
    }

    const dataForm = this.props.client.dataForm

    return dataForm.isOpen ? (
      <Spin spinning={dataForm.isSending} indicator={antIcon}>
        <Modal
          title="Выберите COPM"
          visible={this.state.isOpenCopmModal}
          onOk={() => {
            this.setState({ isOpenCopmModal: false })
            this.props.setFieldForClient('text', this.state.selectedCopmItem)
          }}
          onCancel={() => this.setState({ isOpenCopmModal: false })}
          okText="Выбрать"
          cancelText="Отмена"
        >
          <Select
            placeholder="Выберите"
            style={{ width: '100%' }}
            onChange={value => this.setState({ selectedCopmItem: value })}
          >
            {this.props.client.data.lastCopmItems.map(item => (
              <Option value={item.name} key={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Modal>
        <Form
          {...formItemLayout}
          onSubmit={this.handleSubmit}
          autoComplete="off"
        >
          {mode === 'target' && (
            <Form.Item label="Проблема">
              {getFieldDecorator('problem_id', {
                rules: [{ required: true, message: 'Выберите' }],
                initialValue: dataForm.problem_id ? dataForm.problem_id : null
              })(
                <Select
                  showSearch
                  placeholder="Выберите"
                  onChange={value =>
                    this.props.setFieldForClient('problem_id', value)
                  }
                  onSearch={this.searchProblem}
                  filterOption={false}
                >
                  {this.state.problemsList.map(item => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          )}

          <Form.Item label={mode === 'target' ? 'Цель' : 'Проблема'}>
            <CKEditor
              editor={ClassicEditor}
              data={dataForm.text}
              config={{
                toolbar: ['heading', 'bold', 'italic', 'link', 'blockQuote'],
                heading: {
                  options: [
                    {
                      model: 'paragraph',
                      title: 'Параграф',
                      class: 'ck-heading_paragraph'
                    },
                    {
                      model: 'heading2',
                      view: 'h2',
                      title: 'Заголовок',
                      class: 'ck-heading_heading2'
                    }
                  ]
                }
              }}
              onChange={(event, editor) => {
                const data = editor.getData()
                this.props.setFieldForClient('text', data)
              }}
            />
            {mode === 'problem' && (
              <Button
                onClick={() => this.setState({ isOpenCopmModal: true })}
                className="Client__undertextarea-button"
              >
                Выбрать из COPM
              </Button>
            )}
          </Form.Item>

          <Form.Item label="Тип специалиста">
            {getFieldDecorator('specialist_type', {
              rules: [{ required: true, message: 'Выберите' }],
              initialValue: dataForm.specialist_type
                ? dataForm.specialist_type
                : null
            })(
              <Select
                placeholder="Выберите"
                onChange={value =>
                  this.props.setFieldForClient('specialist_type', value)
                }
              >
                <Option value={1}>Физический терапевт</Option>
                <Option value={2}>Эрготерапевт</Option>
                <Option value={3}>Специалист по коммуникации</Option>
                <Option value={4}>Психолог</Option>
                <Option value={5}>Подбор и адаптация ТСР</Option>
                <Option value={6}>Врач ФРМ</Option>
                <Option value={7}>Анализ походки</Option>
                <Option value={8}>Логопед</Option>
                <Option value={9}>Специалист программы ранней помощи</Option>
                <Option value={10}>Супервизор программы ранней помощи</Option>
              </Select>
            )}
          </Form.Item>

          {mode === 'target' && (
            <>
              <Form.Item label="МКФ">
                {getFieldDecorator('mcf_icf', {
                  rules: [{ required: false, message: 'Введите' }],
                  initialValue: dataForm.mcf_icf
                })(
                  <Input
                    placeholder="Введите"
                    autoComplete="off"
                    maxLength={7}
                    onChange={e =>
                      this.props.setFieldForClient('mcf_icf', e.target.value)
                    }
                  />
                )}
              </Form.Item>
              <Form.Item label="Примечание">
                <CKEditor
                  editor={ClassicEditor}
                  data={dataForm.comment}
                  config={{
                    toolbar: [
                      'heading',
                      'bold',
                      'italic',
                      'link',
                      'blockQuote'
                    ],
                    heading: {
                      options: [
                        {
                          model: 'paragraph',
                          title: 'Параграф',
                          class: 'ck-heading_paragraph'
                        },
                        {
                          model: 'heading2',
                          view: 'h2',
                          title: 'Заголовок',
                          class: 'ck-heading_heading2'
                        }
                      ]
                    }
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    this.props.setFieldForClient('comment', data)
                  }}
                />
              </Form.Item>
            </>
          )}

          <Form.Item label="Дата">
            {getFieldDecorator('date', {
              rules: [{ required: true, message: 'Выберите дату' }],
              initialValue: dataForm.date
                ? moment(dataForm.date, 'YYYY-MM-DD')
                : null
            })(
              <DatePicker
                format={'DD.MM.YYYY'}
                placeholder="Выберите дату"
                onChange={(date, dateString) =>
                  this.props.setFieldForClient(
                    'date',
                    moment(dateString, 'DD.MM.YYYY').format('YYYY-MM-DD')
                  )
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Состояние">
            {getFieldDecorator('status', {
              rules: [{ required: true, message: 'Выберите' }],
              initialValue: dataForm.status
            })(
              <Select
                placeholder="Выберите"
                onChange={value =>
                  this.props.setFieldForClient('status', value)
                }
              >
                <Option value={0}>в процессе</Option>
                <Option value={1}>
                  {mode === 'target' ? 'достигли' : 'решили'}
                </Option>
                <Option value={2}>
                  {mode === 'target' ? 'не достигли' : 'не решили'}
                </Option>
              </Select>
            )}
          </Form.Item>

          {parseInt(dataForm.status) === 1 && (
            <Form.Item
              label={mode === 'target' ? 'Дата достижения' : 'Дата решения'}
            >
              {getFieldDecorator('date_of_achievement', {
                rules: [{ required: true, message: 'Выберите дату' }],
                initialValue: dataForm.date_of_achievement
                  ? moment(dataForm.date_of_achievement, 'YYYY-MM-DD')
                  : null
              })(
                <DatePicker
                  format={'DD.MM.YYYY'}
                  placeholder="Выберите дату"
                  onChange={(date, dateString) =>
                    this.props.setFieldForClient(
                      'date_of_achievement',
                      moment(dateString, 'DD.MM.YYYY').format('YYYY-MM-DD')
                    )
                  }
                />
              )}
            </Form.Item>
          )}

          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>

          {dataForm.id && (
            <Form.Item {...tailFormItemLayout}>
              <p>
                Создана: {dataForm.user_creating.user_creating_name}{' '}
                {dataForm.user_creating.user_creating_surname},{' '}
                {moment(dataForm.date_creating).format('DD.MM.YYYY HH:mm')}
                <br />
                Последнее редактирование:{' '}
                {dataForm.user_last_editing.user_last_editing_name}{' '}
                {dataForm.user_last_editing.user_last_editing_surname},{' '}
                {moment(dataForm.date_last_editing).format('DD.MM.YYYY HH:mm')}
              </p>
            </Form.Item>
          )}
        </Form>
      </Spin>
    ) : null
  }
}

const mapStateToProps = store => {
  return {
    client: store.client
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    setFieldForClient: (field, value) =>
      dispatch(setFieldForClient(field, value, 'dataForm')),
    createData: (clientId, mode, formObj) =>
      dispatch(createData(clientId, mode, formObj)),
    getProblemsList: id => dispatch(getProblemsList(id)),
    updateData: (id, mode, formObj) => dispatch(updateData(id, mode, formObj)),
    getLastCopmItems: id => dispatch(getLastCopmItems(id))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'DataForm' })(DataForm))
