import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Icon,
  Drawer,
  Menu,
  Dropdown,
  Popconfirm,
  Divider,
  Spin,
  Anchor,
  Row,
  Col
} from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'

import {
  getList,
  deleteItem,
  changeFormView,
  openEditForm
} from '../../../../../redux/actions/ClientEstimatesActions'

import downloadFile from '../../../../../utils/downloadFile'

import FoodForm from './FoodFormComponent'

const { Link } = Anchor

class FoodTab extends PureComponent {
  state = {
    currentDataIndex: 0
  }

  componentDidMount() {
    const { clientId } = this.props
    this.props.getList(clientId)
  }

  getQuestionAnswer(question) {
    let answer = null

    switch (question.type) {
      case 'static':
        answer = question.answer ? question.answer : '–'
        break
      case 'input-number':
        answer = question.answer ? question.answer : '–'
        break
      case 'textarea':
        answer = question.answer ? question.answer : '–'
        break
      case 'select':
        answer = question.answer ? question.answer : '–'
        break
      case 'multiple-select':
        answer = question.answer ? (
          <ul>
            {question.answer.split('; ').map(item => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        ) : (
          '–'
        )
        break
      case 'file':
        answer = question.answer ? (
          <a
            href={'/'}
            target="_blank"
            rel="noopener noreferrer"
            onClick={e => {
              e.preventDefault()
              downloadFile(
                `${process.env.REACT_APP_API_HOST}/file/get/${question.answer.id}`,
                question.answer.name,
                this.props.user.attributes.token
              )
            }}
          >
            Скачать <Icon type="download" />
          </a>
        ) : (
          '–'
        )
        break
      default:
        break
    }

    return answer
  }

  render() {
    const { clientEstimates } = this.props

    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />

    const menuItems = clientEstimates.food.items.map((item, index) => (
      <Menu.Item key={item.id}>
        <a
          href="/"
          onClick={e => {
            e.preventDefault()
            this.setState({ currentDataIndex: index })
          }}
        >
          {this.state.currentDataIndex === index ? (
            <b>{item.date}</b>
          ) : (
            <span>{item.date}</span>
          )}
        </a>
      </Menu.Item>
    ))
    const menu = <Menu>{menuItems}</Menu>

    let anchorItems = []
    let contentItems = []

    if (clientEstimates.food.items.length > 0) {
      anchorItems = clientEstimates.food.items[
        this.state.currentDataIndex
      ].tabs.map(item => (
        <Link href={'#' + item.key} key={item.key} title={item.name} />
      ))

      clientEstimates.food.items[this.state.currentDataIndex].tabs.forEach(
        (item, itemIndex) => {
          let questions = []

          item.questions.forEach((question, questionIndex) => {
            questions.push(
              <div className="Interview__answer-block" key={question.key}>
                <div className="Interview__question">
                  <b>
                    {itemIndex + 1}.{questionIndex + 1}. {question.question}
                  </b>
                </div>
                <div>{this.getQuestionAnswer(question)}</div>
              </div>
            )
          })

          contentItems.push(
            <div key={item.key}>
              <h3 className="Interview__header" id={item.key}>
                {item.name}
              </h3>
              {questions}
            </div>
          )
        }
      )
    }

    return (
      <Spin spinning={clientEstimates.food.isLoading} indicator={antIcon}>
        <Button type="primary" onClick={() => this.props.changeFormView(true)}>
          Создать
        </Button>
        <p></p>

        {clientEstimates.food.items.length > 0 && (
          <>
            <Dropdown overlay={menu} className="Interview__dropdown">
              <a
                href="/"
                className="ant-dropdown-link"
                onClick={e => e.preventDefault()}
              >
                {clientEstimates.food.items[this.state.currentDataIndex].date}{' '}
                <Icon type="down" />
              </a>
            </Dropdown>
            <span className="Clients__control-buttons">
              <a
                href="/"
                onClick={e => {
                  e.preventDefault()
                  this.props.openEditForm(
                    clientEstimates.food.items[this.state.currentDataIndex]
                  )
                }}
              >
                <EditOutlined />
              </a>
              <Divider type="vertical" />
              <Popconfirm
                title="Вы уверены, что хотите удалить запись?"
                onConfirm={e => {
                  this.props.deleteItem(
                    clientEstimates.food.items[this.state.currentDataIndex].id
                  )
                  this.setState({ currentDataIndex: 0 })
                }}
                okText="Да"
                cancelText="Нет"
              >
                <a href="/">
                  <DeleteOutlined />
                </a>
              </Popconfirm>
            </span>

            <Row>
              <Col span={18}>{contentItems}</Col>
              <Col span={6}>
                <Anchor>{anchorItems}</Anchor>
              </Col>
            </Row>
          </>
        )}

        <Drawer
          title={clientEstimates.foodForm.id ? 'Редактирование' : 'Создание'}
          width={'700'}
          onClose={() => this.props.changeFormView(false)}
          visible={clientEstimates.foodForm.isOpen}
        >
          {clientEstimates.foodForm.isOpen && (
            <>{<FoodForm clientId={this.props.clientId} />}</>
          )}
        </Drawer>
      </Spin>
    )
  }
}

const mapStateToProps = store => {
  return {
    clientEstimates: store.clientEstimates,
    user: store.user
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    getList: id => dispatch(getList(id, 'food', 'food')),
    deleteItem: id => dispatch(deleteItem(id, 'food', 'food')),
    changeFormView: (isOpen = true) =>
      dispatch(changeFormView('foodForm', isOpen)),
    openEditForm: item => dispatch(openEditForm(item, 'foodForm'))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FoodTab)
