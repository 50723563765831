import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Divider,
  Popconfirm,
  Drawer,
  Icon,
  Spin,
  Row,
  Col,
  message,
  Modal,
  Tooltip
} from 'antd'
import { EditOutlined, DeleteOutlined, CopyOutlined } from '@ant-design/icons'
import moment from 'moment'
import qs from 'qs'

import {
  openClientForm,
  closeClientForm,
  getServicesList,
  openEditClientForm,
  deleteService,
  getService,
  getServicesSpecialists
} from '../../../../redux/actions/ClientActions'

import ServiceForm from './ServiceFormComponent'
import AnimatedTable from '../../../../components/AnimatedTableComponent'
import TableSettings from '../../../../components/TableSettingsComponent'

import getSpecialistTypeName from '../../../../utils/getSpecialistTypeName'

class ServiceTab extends PureComponent {
  componentDidMount() {
    const { clientId } = this.props
    this.props.getServicesSpecialists(clientId)
    this.props.getServicesList(clientId)

    this.openGoogleCalLink = this.openGoogleCalLink.bind(this)

    const getVars = qs.parse(document.location.search.substr(1))
    if (getVars.id) {
      this.props.getService(getVars.id)
    }
  }

  getServiceTypeName(type) {
    switch (type) {
      case 1:
        return 'Консультация в центре'
      case 2:
        return 'Консультация онлайн'
      case 3:
        return 'Консультация на дому'
      case 4:
        return 'Индивидуальные занятия в центре'
      case 8:
        return 'Индивидуальные занятия на дому'
      case 9:
        return 'Индивидуальные занятия онлайн'
      case 5:
        return 'Семейная встреча онлайн'
      case 6:
        return 'Семейная встреча в центре'
      case 7:
        return 'Групповые занятия'
      case 10:
        return 'Фитнес-группа онлайн'
      default:
        return 'Тип не указан'
    }
  }

  getTextStatus(status) {
    switch (status) {
      case 1:
        return 'посетил'
      case 2:
        return 'не посетил'
      default:
        return 'предстоит'
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    let sort = null
    if (Object.keys(sorter).length > 0) {
      sort = {
        field: sorter.field,
        type: sorter.order === 'ascend' ? 'asc' : 'desc'
      }
    }

    let filtersParam = {
      specialists: [],
      specialistIds: []
    }

    if (filters.specialists) {
      filters.specialists.forEach(item => {
        let itemArr = item.split('-')
        filtersParam[itemArr[0]].push(itemArr[1])
      })
    }

    const { clientId } = this.props
    this.props.getServicesList(clientId, sort, filtersParam, pagination.current)
  }

  openGoogleCalLink = () => {
    const baseUrl = process.env.REACT_APP_API_HOST.replace('/api', '/')

    const calLinkUrl =
      baseUrl + 'calendar/calendarExport.php?clients[]=' + this.props.clientId

    Modal.info({
      title: 'Ссылка для экспорта в Google Календарь',
      content: (
        <div>
          <p>
            {calLinkUrl}
            <Button
              shape="circle"
              style={{ marginLeft: '5px' }}
              onClick={() => {
                navigator.clipboard
                  .writeText(calLinkUrl)
                  .then(() => {
                    message.success('Ссылка скопирована')
                  })
                  .catch(err => {
                    console.log('Something went wrong', err)
                  })
              }}
            >
              <Icon type="copy" />
            </Button>
          </p>
        </div>
      ),
      onOk() {}
    })
  }

  render() {
    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />

    const { client, clientId, user, settings } = this.props
    let userFullEditAccess = false
    if (settings.params.report_permanent_access.indexOf(user.id) !== -1) {
      userFullEditAccess = true
    }

    let columns = [
      {
        title: 'Тип',
        dataIndex: 'type',
        className: 'Clients__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            const serviceItem = client.service.items.find(
              item => item.id === record.id
            )
            this.props.openEditClientForm(serviceItem)
          }
        })
      },
      {
        title: 'Специалист(-ы)',
        dataIndex: 'specialists',
        className: 'Clients__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            const serviceItem = client.service.items.find(
              item => item.id === record.id
            )
            this.props.openEditClientForm(serviceItem)
          }
        }),
        filters: [
          {
            text: 'Специалист',
            value: 'specialistIds',
            children: client.service.services_specialists.map(item => ({
              text: `${item.surname} ${item.name}`,
              value: `specialistIds-${item.id}`
            }))
          },
          {
            text: 'Специальность',
            value: 'specialists',
            children: [
              {
                text: 'тип не указан',
                value: `specialists-0`
              },
              {
                text: 'физический терапевт',
                value: `specialists-1`
              },
              {
                text: 'эрготерапевт',
                value: `specialists-2`
              },
              {
                text: 'специалист по коммуникации',
                value: `specialists-3`
              },
              {
                text: 'психолог',
                value: `specialists-4`
              },
              {
                text: 'подбор и адаптация ТСР',
                value: `specialists-5`
              },
              {
                text: 'врач ФРМ',
                value: `specialists-6`
              },
              {
                text: 'анализ походки',
                value: `specialists-7`
              },
              {
                text: 'логопед',
                value: `specialists-8`
              },
              {
                text: 'специалист программы ранней помощи',
                value: `specialists-9`
              },
              {
                text: 'супервизор программы ранней помощи',
                value: `specialists-10`
              }
            ]
          }
        ]
      }
    ]

    if (
      user.settings.tables['services'] &&
      user.settings.tables['services'].indexOf('conclusion') !== -1
    ) {
      columns.push({
        title: 'Заключение',
        dataIndex: 'conclusion',
        className: 'Clients__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            const serviceItem = client.service.items.find(
              item => item.id === record.id
            )
            this.props.openEditClientForm(serviceItem)
          }
        }),
        render: (text, record) => {
          return <span dangerouslySetInnerHTML={{ __html: text }}></span>
        }
      })
    }

    if (
      user.settings.tables['services'] &&
      user.settings.tables['services'].indexOf('comment') !== -1
    ) {
      columns.push({
        title: 'Комментарий',
        dataIndex: 'comment',
        className: 'Clients__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            const serviceItem = client.service.items.find(
              item => item.id === record.id
            )
            this.props.openEditClientForm(serviceItem)
          }
        }),
        render: (text, record) => {
          return <span dangerouslySetInnerHTML={{ __html: text }}></span>
        }
      })
    }

    if (
      user.settings.tables['services'] &&
      user.settings.tables['services'].indexOf('date') !== -1
    ) {
      columns.push({
        title: 'Дата',
        dataIndex: 'date',
        className: 'Clients__table-cell',
        sorter: true,
        onCell: (record, rowIndex) => ({
          onClick: event => {
            const serviceItem = client.service.items.find(
              item => item.id === record.id
            )
            this.props.openEditClientForm(serviceItem)
          }
        })
      })
    }

    if (
      user.settings.tables['services'] &&
      user.settings.tables['services'].indexOf('status') !== -1
    ) {
      columns.push({
        title: 'Статус',
        dataIndex: 'status',
        className: 'Clients__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            const serviceItem = client.service.items.find(
              item => item.id === record.id
            )
            this.props.openEditClientForm(serviceItem)
          }
        })
      })
    }

    if (
      user.settings.tables['services'] &&
      user.settings.tables['services'].indexOf('is_primary') !== -1
    ) {
      columns.push({
        title: 'Первчиная',
        dataIndex: 'is_primary',
        className: 'Clients__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            const serviceItem = client.service.items.find(
              item => item.id === record.id
            )
            this.props.openEditClientForm(serviceItem)
          }
        })
      })
    }

    columns.push({
      title: '',
      key: 'action',
      width: 130,
      render: (text, record) => (
        <span className="Clients__control-buttons">
          <a
            href="/"
            onClick={e => {
              e.preventDefault()
              const serviceItem = client.service.items.find(
                item => item.id === record.id
              )
              this.props.openEditClientForm(serviceItem)
            }}
          >
            <EditOutlined />
          </a>
          <Divider type="vertical" />
          <a
            href="/"
            onClick={e => {
              e.preventDefault()
              const serviceItem = client.service.items.find(
                item => item.id === record.id
              )
              this.props.openEditClientForm(
                {
                  ...serviceItem,
                  date:
                    userFullEditAccess ||
                    moment(record.date, 'DD.MM.YYYY HH:mm').isAfter(
                      settings.params.report_last_date
                    )
                      ? serviceItem.date
                      : moment().format('YYYY-MM-DD HH:mm')
                },
                true
              )
            }}
          >
            <CopyOutlined />
          </a>
          {(userFullEditAccess ||
            moment(record.date, 'DD.MM.YYYY HH:mm').isAfter(
              settings.params.report_last_date
            )) && (
            <span>
              <Divider type="vertical" />
              <Popconfirm
                title="Вы уверены, что хотите удалить запись?"
                onConfirm={e => {
                  this.props.deleteService(record.id)
                }}
                okText="Да"
                cancelText="Нет"
              >
                <a href="/">
                  <DeleteOutlined />
                </a>
              </Popconfirm>
            </span>
          )}
        </span>
      )
    })

    const data = client.service.items.map(item => ({
      key: item.id,
      id: item.id,
      type: this.getServiceTypeName(item.type),
      specialists: item.specialists.map((specItem, i) => (
        <span key={i}>
          {specItem.name} {specItem.surname},{' '}
          {getSpecialistTypeName(specItem.type)}
          {i + 1 !== item.specialists.length && <br />}
        </span>
      )),
      conclusion: item.conclusion,
      comment: item.comment,
      date: moment(item.date).format('DD.MM.YYYY HH:mm'),
      status: this.getTextStatus(item.status),
      is_primary: item.is_primary === 1 ? 'да' : 'нет'
    }))

    return (
      <Spin spinning={client.service.isLoading} indicator={antIcon}>
        <Row gutter={16} className="Clients__settings" align="middle">
          <Col span={21}>
            <Button type="primary" onClick={this.props.openClientForm}>
              Создать
            </Button>
          </Col>
          <Col span={3} className="Client__calendar-col">
            <TableSettings
              section="services"
              columns={[
                {
                  key: 'conclusion',
                  title: 'Заключение',
                  isViewByDefault: true
                },
                {
                  key: 'comment',
                  title: 'Комментарий',
                  isViewByDefault: false
                },
                {
                  key: 'date',
                  title: 'Дата',
                  isViewByDefault: true
                },
                {
                  key: 'status',
                  title: 'Статус',
                  isViewByDefault: true
                },
                {
                  key: 'is_primary',
                  title: 'Первчиная',
                  isViewByDefault: false
                }
              ]}
            />
            <Tooltip
              placement="bottom"
              mouseEnterDelay={0.75}
              title="Экспорт расписания в календарь"
            >
              <Button
                shape="circle"
                size="large"
                onClick={this.openGoogleCalLink}
              >
                <Icon type="calendar" className="Client__calendar-icon" />
              </Button>
            </Tooltip>
          </Col>
        </Row>
        <p></p>
        <AnimatedTable
          columns={columns}
          pagination={{ total: client.service.total }}
          dataSource={data}
          animation={client.service.animation}
          onChange={this.handleTableChange}
        />
        <Drawer
          title={
            !this.props.client.serviceForm.id ? 'Создание' : 'Редактирование'
          }
          width={'700'}
          onClose={this.props.closeClientForm}
          visible={this.props.client.serviceForm.isOpen}
        >
          <ServiceForm clientId={clientId} />
        </Drawer>
      </Spin>
    )
  }
}

const mapStateToProps = store => {
  return {
    client: store.client,
    user: store.user,
    settings: store.settings
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openClientForm: () => dispatch(openClientForm('serviceForm')),
    closeClientForm: () => dispatch(closeClientForm('serviceForm')),
    getServicesList: (id, sort = null, filters = null, page = 1) =>
      dispatch(getServicesList(id, sort, filters, page)),
    openEditClientForm: (item, copy = false) =>
      dispatch(openEditClientForm(item, 'serviceForm', copy)),
    deleteService: id => dispatch(deleteService(id)),
    getService: id => dispatch(getService(id)),
    getServicesSpecialists: id => dispatch(getServicesSpecialists(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceTab)
