export const SET_FIELD = 'SETTINGS/SET_FIELD'

export function setField(field, value) {
  return dispatch => {
    dispatch({
      type: SET_FIELD,
      payload: {
        field,
        value
      }
    })
  }
}

export const GET_REQUEST = 'SETTINGS/GET_REQUEST'
export const GET_SUCCESS = 'SETTINGS/GET_SUCCESS'

export function getSettings() {
  return dispatch => {
    dispatch({
      types: [GET_REQUEST, GET_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: `/settings/get`
        }
      }
    })
  }
}

export const UPDATE_REQUEST = 'SETTINGS/UPDATE_REQUEST'
export const UPDATE_SUCCESS = 'SETTINGS/UPDATE_SUCCESS'

export function updateSettings(params) {
  return dispatch => {
    dispatch({
      types: [UPDATE_REQUEST, UPDATE_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/settings/update`,
          data: {
            ...params
          }
        }
      }
    })
  }
}
