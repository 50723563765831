import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Button, Icon, Drawer, Divider, Popconfirm, Spin } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import axios from 'axios'
import moment from 'moment'

import AnimatedTable from '../../../../../components/AnimatedTableComponent'
import FileForm from './FileFormComponent'

import {
  getList,
  deleteItem,
  changeFormView,
  openEditForm
} from '../../../../../redux/actions/ClientEstimatesActions'

import downloadFile from '../../../../../utils/downloadFile'

class FileTab extends PureComponent {
  state = {
    isOpenForm: false,
    loadingImages: [],
    images: {}
  }

  componentDidMount() {
    const { clientId, type } = this.props
    this.props.getList(clientId, type)
  }

  componentDidUpdate() {
    const { fileType, clientEstimates } = this.props
    if (fileType === 'image') {
      clientEstimates.file.items.forEach(item => {
        if (item.file_id > 0) {
          this.getImage(item.file_id)
        }
      })
    }
  }

  getImage(fileId) {
    const { user } = this.props
    if (this.state.loadingImages.indexOf(fileId) !== -1) {
      return
    } else {
      const loadingImages = this.state.loadingImages.concat(fileId)
      this.setState({ loadingImages })
    }
    axios
      .get(`${process.env.REACT_APP_API_HOST}/file/get/${fileId}`, {
        headers: { authorization: `Bearer ${user.attributes.token}` },
        responseType: 'arraybuffer'
      })
      .then(response => {
        let data = `data:${
          response.headers['content-type']
        };base64,${new Buffer(response.data, 'binary').toString('base64')}`
        this.setState({
          images: {
            ...this.state.images,
            [fileId]: {
              data,
              type: response.headers['content-type']
            }
          }
        })
      })
  }

  render() {
    const {
      type,
      fileType,
      fileName,
      internalComment,
      siteComment,
      clientEstimates
    } = this.props

    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />

    let columns = [
      {
        title: 'Дата',
        dataIndex: 'date',
        className: 'Clients__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            const fileItem = clientEstimates.file.items.find(
              item => item.id === record.id
            )
            this.props.openEditForm(fileItem)
          }
        })
      },
      {
        title: fileName,
        dataIndex: 'path',
        className: 'Clients__table-cell',
        render: (text, record) => {
          if (record.file_id > 0) {
            if (fileType === 'image') {
              if (!this.state.images[record.file_id]) {
                return (
                  <Icon
                    type="loading"
                    style={{ fontSize: 25 }}
                    className="Clients__link"
                    spin
                  />
                )
              } else {
                return (
                  <a
                    href={this.state.images[record.file_id].data}
                    data-fancybox
                    data-type={
                      this.state.images[record.file_id].type === 'image/jpeg'
                        ? 'image'
                        : 'iframe'
                    }
                    data-caption={
                      `<p style="text-align: center;"><b>${record.date}</b></p>` +
                      record.internal_comment
                    }
                    data-options={{ thumbs: false }}
                  >
                    Просмотр <Icon type="eye" />
                  </a>
                )
              }
            } else {
              return (
                <a
                  href={'/'}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={e => {
                    e.preventDefault()
                    downloadFile(
                      `${process.env.REACT_APP_API_HOST}/file/get/${record.file.f_id}`,
                      record.file.f_name,
                      this.props.user.attributes.token
                    )
                  }}
                >
                  Скачать <Icon type="download" />
                </a>
              )
            }
          } else {
            return '–'
          }
        }
      }
    ]

    if (internalComment) {
      columns.push({
        title: 'Специалистам',
        dataIndex: 'internal_comment',
        className: 'Clients__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            const fileItem = clientEstimates.file.items.find(
              item => item.id === record.id
            )
            this.props.openEditForm(fileItem)
          }
        }),
        render: (text, record) => {
          return <span dangerouslySetInnerHTML={{ __html: text }}></span>
        }
      })
    }

    if (siteComment) {
      columns.push({
        title: 'Комментарий',
        dataIndex: 'site_comment',
        className: 'Clients__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            const fileItem = clientEstimates.file.items.find(
              item => item.id === record.id
            )
            this.props.openEditForm(fileItem)
          }
        }),
        render: (text, record) => {
          return <span dangerouslySetInnerHTML={{ __html: text }}></span>
        }
      })
    }

    columns.push({
      title: '',
      key: 'action',
      width: 130,
      render: (text, record) => (
        <span className="Clients__control-buttons">
          <a
            href="/"
            onClick={e => {
              e.preventDefault()
              const fileItem = clientEstimates.file.items.find(
                item => item.id === record.id
              )
              this.props.openEditForm(fileItem)
            }}
          >
            <EditOutlined />
          </a>
          <Divider type="vertical" />
          <Popconfirm
            title="Вы уверены, что хотите удалить запись?"
            onConfirm={e => {
              this.props.deleteItem(record.id, type)
            }}
            okText="Да"
            cancelText="Нет"
          >
            <a href="/">
              <DeleteOutlined />
            </a>
          </Popconfirm>
        </span>
      )
    })

    const data = clientEstimates.file.items.map(item => ({
      key: item.id,
      id: item.id,
      date: moment(item.date).format('DD.MM.YYYY'),
      internal_comment: item.internal_comment ? item.internal_comment : null,
      site_comment: item.site_comment ? item.site_comment : null,
      file_id: item.file_id,
      file: item.file
    }))

    return (
      <Spin spinning={clientEstimates.file.isLoading} indicator={antIcon}>
        <Button type="primary" onClick={() => this.props.changeFormView(true)}>
          Создать
        </Button>
        <p></p>
        <AnimatedTable
          columns={columns}
          pagination
          dataSource={data}
          animation={clientEstimates.file.animation}
        />
        <Drawer
          title={!clientEstimates.fileForm.id ? 'Создание' : 'Редактирование'}
          width={'700'}
          onClose={() => this.props.changeFormView(false)}
          visible={clientEstimates.fileForm.isOpen}
        >
          <FileForm
            type={this.props.type}
            clientId={this.props.clientId}
            fileType={this.props.fileType}
            fileName={this.props.fileName}
            allowTypes={this.props.allowTypes}
            internalComment={this.props.internalComment}
            siteComment={this.props.siteComment}
          />
        </Drawer>
      </Spin>
    )
  }
}

const mapStateToProps = store => {
  return {
    clientEstimates: store.clientEstimates,
    user: store.user
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    getList: (id, type) => dispatch(getList(id, type, 'file')),
    deleteItem: (id, type) => dispatch(deleteItem(id, type, 'file')),
    changeFormView: (isOpen = true) =>
      dispatch(changeFormView('fileForm', isOpen)),
    openEditForm: item => dispatch(openEditForm(item, 'fileForm'))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FileTab)
