import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Table, Icon, Spin } from 'antd'
import moment from 'moment'

import { getWay } from '../../../../redux/actions/ClientActions'

class WayTab extends PureComponent {
  componentDidMount() {
    this.props.getWay(this.props.clientId)
  }

  getTypeName(type) {
    switch (type) {
      case 'problem':
        return 'Проблема'
      case 'target':
        return 'Цель'
      case 'service-1':
        return 'Консультация в центре'
      case 'service-2':
        return 'Консультация онлайн'
      case 'service-3':
        return 'Консультация на дому'
      case 'service-4':
        return 'Индивидуальные занятия в центре'
      case 'service-8':
        return 'Индивидуальные занятия на дому'
      case 'service-9':
        return 'Индивидуальные занятия онлайн'
      case 'service-5':
        return 'Семейная встреча онлайн'
      case 'service-6':
        return 'Семейная встреча в центре'
      case 'service-7':
        return 'Групповые занятия'
      case 'service-10':
        return 'Фитнес-группа онлайн'
      default:
        return 'Тип не указан'
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.props.getWay(this.props.clientId, pagination.current)
  }

  render() {
    const { client } = this.props

    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />

    const columns = [
      {
        title: 'Тип',
        dataIndex: 'type'
      },
      {
        title: 'Результат',
        dataIndex: 'text',
        render: (text, record) => {
          return <span dangerouslySetInnerHTML={{ __html: text }}></span>
        }
      },
      {
        title: 'Дата',
        dataIndex: 'date'
      },
      {
        title: 'Сотрудник',
        dataIndex: 'employee'
      }
    ]

    const data = client.way.items.map((item, index) => ({
      key: item.type + '-' + item.id + '-' + index,
      type: this.getTypeName(item.type),
      date:
        item.type !== 'problem' && item.type !== 'target'
          ? moment(item.date).format('DD.MM.YYYY HH:mm')
          : moment(item.date).format('DD.MM.YYYY'),
      employee: item.user.user_name + ' ' + item.user.user_surname,
      text: item.result ? item.result : '–'
    }))

    return (
      <Spin spinning={client.way.isLoading} indicator={antIcon}>
        <Table
          columns={columns}
          pagination={{ total: client.way.total }}
          dataSource={data}
          onChange={this.handleTableChange}
        />
      </Spin>
    )
  }
}

const mapStateToProps = store => {
  return {
    client: store.client
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getWay: (clientId, page = 1) => dispatch(getWay(clientId, page))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WayTab)
