export const EMPLOYMENT_LOAD_REQUEST = 'EMPLOYMENT/EMPLOYMENT_LOAD_REQUEST'
export const EMPLOYMENT_LOAD_SUCCESS = 'EMPLOYMENT/EMPLOYMENT_LOAD_SUCCESS'

export function getEmploymentList(pageNumber = 1, sort = null, filter = null) {
  return dispatch => {
    let additionalParams = {}

    if (sort) {
      additionalParams.sort = sort.field
      additionalParams.sort_type = sort.type
    }

    if (filter) {
      additionalParams = {
        ...additionalParams,
        ...filter
      }
    }

    dispatch({
      types: [EMPLOYMENT_LOAD_REQUEST, EMPLOYMENT_LOAD_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: '/employment/get-employment-items',
          params: {
            page: pageNumber,
            ...additionalParams
          }
        }
      }
    })
  }
}

export const APPLY_EMPLOYMENT_FILTERS = 'EMPLOYMENT/APPLY_EMPLOYMENT_FILTERS'

export function applyEmploymentFilters(filters) {
  return dispatch => {
    return dispatch({
      type: APPLY_EMPLOYMENT_FILTERS,
      payload: {
        filters
      }
    })
  }
}

export const RESET_EMPLOYMENT_FILTERS = 'EMPLOYMENT/RESET_EMPLOYMENT_FILTERS'

export function resetEmploymentFilters() {
  return dispatch => {
    return dispatch({
      type: RESET_EMPLOYMENT_FILTERS
    })
  }
}

export const OPEN_EMPLOYMENT_FORM = 'EMPLOYMENT/OPEN_EMPLOYMENT_FORM'

export function openEmploymentForm() {
  return dispatch => {
    dispatch({
      type: OPEN_EMPLOYMENT_FORM
    })
  }
}

export const OPEN_EDIT_EMPLOYMENT_FROM = 'EMPLOYMENT/OPEN_EDIT_EMPLOYMENT_FROM'

export function openEditEmploymentForm(item, copy = false) {
  return dispatch => {
    dispatch({
      type: OPEN_EDIT_EMPLOYMENT_FROM,
      payload: {
        item,
        copy
      }
    })
  }
}

export const CLOSE_EMPLOYMENT_FORM = 'EMPLOYMENT/CLOSE_EMPLOYMENT_FORM'

export function closeEmploymentForm() {
  return dispatch => {
    dispatch({
      type: CLOSE_EMPLOYMENT_FORM
    })
  }
}

export const SET_FIELD_FOR_EMPLOYMENT = 'EMPLOYMENT/SET_FIELD_FOR_EMPLOYMENT'

export function setFieldForEmployment(field, value) {
  return dispatch => {
    dispatch({
      type: SET_FIELD_FOR_EMPLOYMENT,
      payload: {
        field,
        value
      }
    })
  }
}

export const CREATE_EMPLOYMENT_REQUEST = 'EMPLOYMENT/CREATE_EMPLOYMENT_REQUEST'
export const CREATE_EMPLOYMENT_SUCCESS = 'EMPLOYMENT/CREATE_EMPLOYMENT_SUCCESS'

export function createEmployment(formObj) {
  return dispatch => {
    dispatch({
      types: [CREATE_EMPLOYMENT_REQUEST, CREATE_EMPLOYMENT_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/employment/create`,
          data: {
            ...formObj
          }
        }
      }
    })
  }
}

export const UPDATE_EMPLOYMENT_REQUEST = 'EMPLOYMENT/UPDATE_EMPLOYMENT_REQUEST'
export const UPDATE_EMPLOYMENT_SUCCESS = 'EMPLOYMENT/UPDATE_EMPLOYMENT_SUCCESS'

export function updateEmployment(id, formObj) {
  return dispatch => {
    dispatch({
      types: [UPDATE_EMPLOYMENT_REQUEST, UPDATE_EMPLOYMENT_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/employment/update/${id}`,
          data: {
            ...formObj
          }
        }
      }
    })
  }
}

export const DELETE_EMPLOYMENT_REQUEST = 'EMPLOYMENT/DELETE_EMPLOYMENT_REQUEST'
export const DELETE_EMPLOYMENT_SUCCESS = 'EMPLOYMENT/DELETE_EMPLOYMENT_SUCCESS'

export function deleteEmployment(id) {
  return dispatch => {
    dispatch({
      types: [DELETE_EMPLOYMENT_REQUEST, DELETE_EMPLOYMENT_SUCCESS],
      payload: {
        id,
        request: {
          method: 'POST',
          url: `/employment/delete/${id}`
        }
      }
    })
  }
}
