import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'antd'
import moment from 'moment'

class InfoTab extends PureComponent {
  render() {
    const { clientData } = this.props.client

    let addressArr = []

    if (clientData.post_index) {
      addressArr.push(clientData.post_index)
    }

    if (clientData.country) {
      addressArr.push(clientData.country)
    }

    if (clientData.region) {
      addressArr.push(clientData.region)
    }

    if (clientData.city) {
      addressArr.push(clientData.city)
    }

    if (clientData.street) {
      addressArr.push(clientData.street)
    }

    if (clientData.home_number) {
      addressArr.push('дом ' + clientData.home_number)
    }

    if (clientData.home_corps) {
      addressArr.push('корпус ' + clientData.home_corps)
    }

    if (clientData.home_letter) {
      addressArr.push('литера ' + clientData.home_letter)
    }

    if (clientData.home_structure) {
      addressArr.push('строение ' + clientData.home_structure)
    }

    if (clientData.home_flat_number) {
      addressArr.push('кв. ' + clientData.home_flat_number)
    }

    let addressStr = addressArr.join(', ')

    return (
      <>
        <Row gutter={[16, 8]}>
          <Col span={4} className="Client__field-name">
            Имя
          </Col>
          <Col span={10}>{clientData.name}</Col>
        </Row>

        <Row gutter={[16, 8]}>
          <Col span={4} className="Client__field-name">
            Дата рождения
          </Col>
          <Col span={10}>
            {clientData.date_of_birth
              ? moment(clientData.date_of_birth).format('DD.MM.YYYY')
              : null}
          </Col>
        </Row>

        <Row gutter={[16, 8]}>
          <Col span={4} className="Client__field-name">
            Законный представитель №1
          </Col>
          <Col span={10}>
            {!clientData.parent_one_name && !clientData.parent_one_surname ? (
              '–'
            ) : (
              <div>
                {clientData.parent_one_surname} {clientData.parent_one_name}{' '}
                {clientData.parent_one_patronymic}
                {clientData.parent_one_phone ? (
                  <>
                    <br />
                    {clientData.parent_one_phone}
                  </>
                ) : null}
                {clientData.parent_one_email ? (
                  <>
                    <br />
                    {clientData.parent_one_email}
                  </>
                ) : null}
              </div>
            )}
          </Col>
        </Row>

        <Row gutter={[16, 8]}>
          <Col span={4} className="Client__field-name">
            Законный представитель №2
          </Col>
          <Col span={10}>
            {!clientData.parent_two_name && !clientData.parent_two_surname ? (
              '–'
            ) : (
              <div>
                {clientData.parent_two_surname} {clientData.parent_two_name}{' '}
                {clientData.parent_two_patronymic}
                {clientData.parent_two_phone ? (
                  <>
                    <br />
                    {clientData.parent_two_phone}
                  </>
                ) : null}
                {clientData.parent_two_email ? (
                  <>
                    <br />
                    {clientData.parent_two_email}
                  </>
                ) : null}
              </div>
            )}
          </Col>
        </Row>

        <Row gutter={[16, 8]}>
          <Col span={4} className="Client__field-name">
            Адрес социального такси
          </Col>
          <Col span={10}>
            {clientData.social_taxi_address
              ? clientData.social_taxi_address
              : '–'}
          </Col>
        </Row>

        <Row gutter={[16, 8]}>
          <Col span={4} className="Client__field-name">
            Телефон
          </Col>
          <Col span={10}>{clientData.phone ? clientData.phone : '–'}</Col>
        </Row>

        <Row gutter={[16, 8]}>
          <Col span={4} className="Client__field-name">
            Email
          </Col>
          <Col span={10}>{clientData.email ? clientData.email : '–'}</Col>
        </Row>

        <Row gutter={[16, 8]}>
          <Col span={4} className="Client__field-name">
            Соцсеть
          </Col>
          <Col span={10}>
            {clientData.social_url ? clientData.social_url : '–'}
          </Col>
        </Row>

        <Row gutter={[16, 8]}>
          <Col span={4} className="Client__field-name">
            Адрес
          </Col>
          <Col span={10}>{addressStr ? addressStr : '–'}</Col>
        </Row>

        <Row gutter={[16, 8]}>
          <Col span={4} className="Client__field-name">
            Разница с МСК
          </Col>
          <Col span={10}>
            {clientData.difference_in_time
              ? clientData.difference_in_time
              : '–'}
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = store => {
  return {
    client: store.client
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(InfoTab)
