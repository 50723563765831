import React, { useState } from 'react'
import { Button, DatePicker } from 'antd'

import moment from 'moment'

const { RangePicker } = DatePicker

export default function DateRangeLoad(props) {
  const [dateRange, setDateRange] = useState([null, null])

  return (
    <>
      <RangePicker
        format={'DD.MM.YYYY'}
        value={[
          dateRange[0] ? moment(dateRange[0], 'YYYY-MM-DD') : null,
          dateRange[1] ? moment(dateRange[1], 'YYYY-MM-DD') : null
        ]}
        onChange={(value, dateString) => {
          setDateRange([
            dateString[0]
              ? moment(dateString[0], 'DD.MM.YYYY').format('YYYY-MM-DD')
              : null,
            dateString[1]
              ? moment(dateString[1], 'DD.MM.YYYY').format('YYYY-MM-DD')
              : null
          ])
        }}
      />{' '}
      <Button
        onClick={() => {
          if (dateRange[0] && dateRange[1]) {
            props.getList(props.clientId, {
              date_s: dateRange[0],
              date_e: dateRange[1]
            })
          } else {
            props.getList(props.clientId)
          }
        }}
      >
        Применить
      </Button>
    </>
  )
}
