import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Button, Divider, Popconfirm, Drawer, Icon, Spin } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import moment from 'moment'

import {
  openClientForm,
  closeClientForm,
  getDataList,
  openEditClientForm,
  deleteData
} from '../../../../redux/actions/ClientActions'

import DataForm from './DataFormComponent'
import AnimatedTable from '../../../../components/AnimatedTableComponent'

import getSpecialistTypeName from '../../../../utils/getSpecialistTypeName'

class DataTab extends PureComponent {
  componentDidMount() {
    const { mode, clientId } = this.props
    if (!this.props.client.mode) {
      this.props.getDataList(clientId, mode)
    }
  }

  getTextStatus(item) {
    const { mode } = this.props

    switch (item.status) {
      case 1:
        return mode === 'problem' ? 'решили' : 'достигли'
      case 2:
        return mode === 'problem' ? 'не решили' : 'не достигли'
      default:
        return 'в процессе'
    }
  }

  render() {
    const { mode, client, clientId } = this.props

    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />

    let problemColumn = []
    if (mode === 'target') {
      problemColumn = [
        {
          title: 'Проблема',
          dataIndex: 'problem',
          className: 'Clients__table-cell',
          onCell: (record, rowIndex) => ({
            onClick: event => {
              const dataItem = client.data.items.find(
                item => item.id === record.id
              )
              this.props.openEditClientForm(dataItem)
            }
          }),
          render: (text, record) => {
            return <span dangerouslySetInnerHTML={{ __html: text }}></span>
          }
        },
        {
          title: 'МКФ',
          dataIndex: 'mcf_icf',
          className: 'Clients__table-cell',
          onCell: (record, rowIndex) => ({
            onClick: event => {
              const dataItem = client.data.items.find(
                item => item.id === record.id
              )
              this.props.openEditClientForm(dataItem)
            }
          })
        }
      ]
    }

    const columns = [
      ...problemColumn,
      {
        title: mode === 'target' ? 'Цель' : 'Проблема',
        dataIndex: 'text',
        className: 'Clients__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            const dataItem = client.data.items.find(
              item => item.id === record.id
            )
            this.props.openEditClientForm(dataItem)
          }
        }),
        render: (text, record) => {
          return <span dangerouslySetInnerHTML={{ __html: text }}></span>
        }
      },
      {
        title: 'Тип специалиста',
        dataIndex: 'specialist_type',
        className: 'Clients__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            const dataItem = client.data.items.find(
              item => item.id === record.id
            )
            this.props.openEditClientForm(dataItem)
          }
        })
      },
      {
        title: 'Дата постановки',
        dataIndex: 'date',
        className: 'Clients__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            const dataItem = client.data.items.find(
              item => item.id === record.id
            )
            this.props.openEditClientForm(dataItem)
          }
        })
      },
      {
        title: 'Статус',
        dataIndex: 'status',
        className: 'Clients__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            const dataItem = client.data.items.find(
              item => item.id === record.id
            )
            this.props.openEditClientForm(dataItem)
          }
        })
      },
      {
        title: `Дата ${mode === 'target' ? 'достижения' : 'решения'}`,
        dataIndex: 'date_of_achievement',
        className: 'Clients__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            const dataItem = client.data.items.find(
              item => item.id === record.id
            )
            this.props.openEditClientForm(dataItem)
          }
        })
      },
      {
        title: 'Сотрудник',
        dataIndex: 'user_creating',
        className: 'Clients__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            const dataItem = client.data.items.find(
              item => item.id === record.id
            )
            this.props.openEditClientForm(dataItem)
          }
        })
      },
      {
        title: '',
        key: 'action',
        width: 110,
        render: (text, record) => (
          <span className="Clients__control-buttons">
            <a
              href="/"
              onClick={e => {
                e.preventDefault()
                const dataItem = client.data.items.find(
                  item => item.id === record.id
                )
                this.props.openEditClientForm(dataItem)
              }}
            >
              <EditOutlined />
            </a>
            <Divider type="vertical" />
            <Popconfirm
              title="Вы уверены, что хотите удалить запись?"
              onConfirm={e => {
                this.props.deleteData(record.id, mode)
              }}
              okText="Да"
              cancelText="Нет"
            >
              <a href="/">
                <DeleteOutlined />
              </a>
            </Popconfirm>
          </span>
        )
      }
    ]

    const data = client.data.items.map(item => ({
      key: item.id,
      id: item.id,
      problem: item.problem ? item.problem.problem_text : null,
      mcf_icf: item.mcf_icf ? item.mcf_icf : null,
      text: item.text,
      specialist_type: getSpecialistTypeName(item.specialist_type),
      date: moment(item.date).format('DD.MM.YYYY'),
      status: this.getTextStatus(item),
      date_of_achievement:
        item.status === 1
          ? moment(item.date_of_achievement).format('DD.MM.YYYY')
          : '–',
      user_creating:
        item.user_creating.user_creating_name +
        ' ' +
        item.user_creating.user_creating_surname
    }))

    return (
      <Spin spinning={client.data.isLoading} indicator={antIcon}>
        <Button type="primary" onClick={this.props.openClientForm}>
          Создать
        </Button>
        <p></p>
        <AnimatedTable
          columns={columns}
          pagination
          dataSource={data}
          animation={client.data.animation}
        />
        <Drawer
          title={!this.props.client.dataForm.id ? 'Создание' : 'Редактирование'}
          width={'700'}
          onClose={this.props.closeClientForm}
          visible={this.props.client.dataForm.isOpen}
        >
          <DataForm mode={mode} clientId={clientId} />
        </Drawer>
      </Spin>
    )
  }
}

const mapStateToProps = store => {
  return {
    client: store.client
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    openClientForm: () => dispatch(openClientForm('dataForm')),
    closeClientForm: () => dispatch(closeClientForm('dataForm')),
    openEditClientForm: item => dispatch(openEditClientForm(item, 'dataForm')),
    getDataList: (id, mode) => dispatch(getDataList(id, mode)),
    deleteData: (id, mode) => dispatch(deleteData(id, mode))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataTab)
