import React, { PureComponent } from 'react'
import { Icon, Button, Spin, DatePicker, Select } from 'antd'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import moment from 'moment'

import {
  setField,
  getSettings,
  updateSettings
} from '../../../redux/actions/SettingsActions'

import { getUsersList } from '../../../redux/actions/ClientActions'

const { Option } = Select

class Settings extends PureComponent {
  componentDidMount() {
    this.props.getSettings()
    this.props.getUsersList()
  }

  render() {
    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />
    const { settings, user } = this.props
    const commonData = this.props.client.commonData

    return (
      <Spin spinning={settings.isLoading} indicator={antIcon}>
        <Helmet>
          <title>Настройки</title>
        </Helmet>

        <h1>Настройки</h1>

        <h3>Отчетность</h3>

        {(user.type === 'admin' ||
          user.attributes.settings_report_date_access === 1) && (
          <div className="Settings__block">
            <div className="Settings__block-title">Дата последнего отчета</div>
            <DatePicker
              placeholder="Выберите дату"
              format={'DD.MM.YYYY'}
              value={moment(settings.params.report_last_date, 'YYYY-MM-DD')}
              allowClear={false}
              onChange={(date, dateString) => {
                this.props.setField(
                  'report_last_date',
                  moment(dateString, 'DD.MM.YYYY').format('YYYY-MM-DD')
                )
              }}
            />
          </div>
        )}

        {user.type === 'admin' && (
          <div className="Settings__block">
            <div className="Settings__block-title">
              Постоянный доступ к редактированию
            </div>
            <Select
              style={{ width: '40%' }}
              mode="multiple"
              placeholder="Выберите пользователей"
              value={settings.params.report_permanent_access}
              onChange={value =>
                this.props.setField('report_permanent_access', value)
              }
              multiple
            >
              {commonData.users_list.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.surname} {item.name}
                </Option>
              ))}
            </Select>
          </div>
        )}

        {user.type === 'admin' && (
          <div className="Settings__block">
            <div className="Settings__block-title">
              Доступ к отчету «Занятость и статистика»
            </div>
            <Select
              style={{ width: '40%' }}
              mode="multiple"
              placeholder="Выберите пользователей"
              value={settings.params.unloading_employment_and_statistics_access}
              onChange={value =>
                this.props.setField(
                  'unloading_employment_and_statistics_access',
                  value
                )
              }
              multiple
            >
              {commonData.users_list.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.surname} {item.name}
                </Option>
              ))}
            </Select>
          </div>
        )}

        {user.type === 'admin' && (
          <div className="Settings__block">
            <div className="Settings__block-title">
              Доступ к отчету «Общие сведения клиентов»
            </div>
            <Select
              style={{ width: '40%' }}
              mode="multiple"
              placeholder="Выберите пользователей"
              value={settings.params.unloading_clients_common_info_access}
              onChange={value =>
                this.props.setField(
                  'unloading_clients_common_info_access',
                  value
                )
              }
              multiple
            >
              {commonData.users_list.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.surname} {item.name}
                </Option>
              ))}
            </Select>
          </div>
        )}

        <h3>Разделы</h3>

        {user.type === 'admin' && (
          <div className="Settings__block">
            <div className="Settings__block-title">
              Доступ к вкладке «Общая» раздела «Занятость»
            </div>
            <Select
              style={{ width: '40%' }}
              mode="multiple"
              placeholder="Выберите пользователей"
              value={settings.params.employment_all_tab_access}
              onChange={value =>
                this.props.setField('employment_all_tab_access', value)
              }
              multiple
            >
              {commonData.users_list.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.surname} {item.name}
                </Option>
              ))}
            </Select>
          </div>
        )}

        <Button
          type="primary"
          className="Settings__save-button"
          onClick={() => this.props.updateSettings(settings.params)}
        >
          Сохранить
        </Button>
      </Spin>
    )
  }
}

const mapStateToProps = store => {
  return {
    settings: store.settings,
    client: store.client,
    user: store.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setField: (field, value) => dispatch(setField(field, value)),
    getSettings: () => dispatch(getSettings()),
    updateSettings: params => dispatch(updateSettings(params)),
    getUsersList: () => dispatch(getUsersList())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
