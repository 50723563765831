import React from 'react'
import { Result } from 'antd'
import { Helmet } from 'react-helmet'

const NotFound = props => (
  <div>
    <Helmet>
      <title>404 Страница не найдена</title>
    </Helmet>

    <Result status="404" title="404" subTitle="Страница не найдена" />
  </div>
)

export default NotFound
