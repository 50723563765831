import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  Icon,
  Spin,
  Table,
  Divider,
  Popconfirm,
  InputNumber,
  Button,
  Modal,
  DatePicker,
  Row,
  Col,
  Tooltip
} from 'antd'
import moment from 'moment'
import {
  EditOutlined,
  DeleteOutlined,
  SaveOutlined,
  CloseOutlined
} from '@ant-design/icons'

import DateRangeLoad from '../../../../../components/DateRangeLoadComponent'

import { getGeneralData } from '../../../../../redux/actions/ClientActions'

import {
  getList,
  updateTableItem,
  deleteItem,
  createTableItem,
  setField
} from '../../../../../redux/actions/ClientEstimatesActions'

import downloadFile from '../../../../../utils/downloadFile'

// GMFCS 1-3: 0, 4-5: 1
const FMFCS_COLOR_CLASSIFIER_PROPS = {
  0: {
    name: 'GMFCS I-III'
  },
  1: {
    name: 'GMFCS IV-V'
  }
}
const FMFCS_COLOR_CLASSIFIER = {
  0: {
    sgibanie_tbs: [100, 110],
    abdukciya_pri_sognutom_tbs: [30, 40],
    vnutrennyaya_rotaciya_bedra: [30, 40],
    naruzhnaya_rotaciya_bedra: [30, 40],
    sgibanie_ks: [110, 120],
    abdukciya_pri_razognutom_tbs: [30, 40],
    razgibanie_ks: [-10, 0],
    tylnoe_sgibanie_gc: [0, 10],
    tylnoe_sgibanie_gs_pri_sognutom_ks: [10, 20],
    harmstring: [130, 140],
    razgibanie_tbs: [0, 0]
  },
  1: {
    sgibanie_tbs: [90, 100],
    abdukciya_pri_sognutom_tbs: [20, 30],
    vnutrennyaya_rotaciya_bedra: [30, 40],
    naruzhnaya_rotaciya_bedra: [30, 40],
    sgibanie_ks: [90, 100],
    abdukciya_pri_razognutom_tbs: [20, 30],
    razgibanie_ks: [-20, 10],
    tylnoe_sgibanie_gc: [-10, 0],
    tylnoe_sgibanie_gs_pri_sognutom_ks: [0, 10],
    harmstring: [120, 130],
    razgibanie_tbs: [-10, 0]
  }
}

class GoniometriyaTab extends PureComponent {
  state = {
    isOpenModal: false,
    createDate: moment().format('YYYY-MM-DD')
  }

  componentDidMount() {
    this.props.getGeneralData(this.props.clientId)
    this.props.getList(this.props.clientId)
  }

  getCellColor(key, value) {
    const gmfcs = this.props.client.commonClientForm.gmfcs
    const classifierCode = gmfcs && parseInt(gmfcs) <= 3 ? 0 : 1

    let range = FMFCS_COLOR_CLASSIFIER[classifierCode][key]
    let color = ''

    if (value <= range[0]) {
      color = '#ff2400'
    } else if (value > range[0] && value < range[1]) {
      color = '#fbec5d'
    } else {
      color = '#7cb305'
    }

    return color
  }

  getCellTooltip(key) {
    const gmfcs = this.props.client.commonClientForm.gmfcs
    const classifierCode = gmfcs && parseInt(gmfcs) <= 3 ? 0 : 1

    let classificationItems = Object.keys(FMFCS_COLOR_CLASSIFIER).map(
      classifierIndex => {
        let range = FMFCS_COLOR_CLASSIFIER[classifierIndex][key]
        if (parseInt(classifierIndex) === classifierCode) {
          return (
            <div key={classifierIndex}>
              <b>
                {FMFCS_COLOR_CLASSIFIER_PROPS[classifierIndex].name} {range[0]}{' '}
                – {range[1]}
              </b>
            </div>
          )
        } else {
          return (
            <div key={classifierIndex}>
              <span style={{ fontSize: '85%' }}>
                {FMFCS_COLOR_CLASSIFIER_PROPS[classifierIndex].name} {range[0]}{' '}
                – {range[1]}
              </span>
            </div>
          )
        }
      }
    )

    return <div>{classificationItems}</div>
  }

  exportDocx() {
    const { goniometriya } = this.props.clientEstimates

    let url = `${process.env.REACT_APP_API_HOST}/goniometriya/export/${this.props.clientId}?`
    if (
      goniometriya.requestParams.date_s &&
      goniometriya.requestParams.date_e
    ) {
      url += `date_s=${goniometriya.requestParams.date_s}&date_e=${goniometriya.requestParams.date_e}`
    }
    const { clientData } = this.props.client
    downloadFile(
      url,
      `Гониометрия ${clientData.name} ${clientData.surname}.docx`,
      this.props.user.attributes.token
    )
  }

  render() {
    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />
    const { goniometriya } = this.props.clientEstimates

    let columns = [
      {
        title: '',
        dataIndex: 'type',
        width: 225,
        fixed: 'left'
      }
    ]

    goniometriya.items.forEach(item => {
      columns.push({
        title: (
          <>
            <span style={{ marginRight: '8px' }}>
              {moment(item.date, 'YYYY-MM-DD').format('DD.MM.YYYY')}
            </span>
            {goniometriya.editableIds.indexOf(item.id) === -1 ? (
              <span className="Clients__control-buttons">
                <a
                  href="/"
                  onClick={e => {
                    e.preventDefault()
                    this.props.setField('editableIds', [
                      ...goniometriya.editableIds,
                      item.id
                    ])
                    this.props.setField('editableItems', {
                      ...goniometriya.editableItems,
                      [item.id]: item
                    })
                  }}
                >
                  <EditOutlined />
                </a>
                <Divider type="vertical" />
                <Popconfirm
                  title="Вы уверены, что хотите удалить оценки?"
                  onConfirm={e => {
                    this.props.deleteItem(item.id)
                  }}
                  okText="Да"
                  cancelText="Нет"
                >
                  <a href="/">
                    <DeleteOutlined />
                  </a>
                </Popconfirm>
              </span>
            ) : (
              <span className="Clients__control-buttons">
                <a
                  href="/"
                  onClick={e => {
                    e.preventDefault()
                    this.props.setField(
                      'editableIds',
                      goniometriya.editableIds.filter(
                        value => value !== item.id
                      )
                    )
                    this.props.updateTableItem(
                      item.id,
                      goniometriya.editableItems[item.id]
                    )
                  }}
                >
                  <SaveOutlined />
                </a>
                <Divider type="vertical" />
                <a
                  href="/"
                  onClick={e => {
                    e.preventDefault()
                    this.props.setField(
                      'editableIds',
                      goniometriya.editableIds.filter(
                        value => value !== item.id
                      )
                    )
                  }}
                >
                  <CloseOutlined />
                </a>
              </span>
            )}
          </>
        ),
        children: [
          {
            title: 'Правая нога',
            dataIndex: `right_leg_${item.id}`,
            render: (text, record) => {
              let style = {}
              let children
              if (!record.staticRaw) {
                if (goniometriya.editableIds.indexOf(item.id) === -1) {
                  style = { background: this.getCellColor(record.key, text) }
                  children = (
                    <Tooltip
                      placement="top"
                      title={this.getCellTooltip(record.key)}
                    >
                      <span style={{ cursor: 'help' }}>{text}</span>
                    </Tooltip>
                  )
                } else {
                  children = (
                    <InputNumber
                      min={-180}
                      max={180}
                      value={
                        goniometriya.editableItems[item.id][
                          `right_leg_${record.key}`
                        ]
                      }
                      onChange={value =>
                        this.props.setField('editableItems', {
                          ...goniometriya.editableItems,
                          [item.id]: {
                            ...goniometriya.editableItems[item.id],
                            [`right_leg_${record.key}`]: value
                          }
                        })
                      }
                    />
                  )
                }
              }
              return {
                props: { style },
                children
              }
            }
          },
          {
            title: 'Левая нога',
            dataIndex: `left_leg_${item.id}`,
            render: (text, record) => {
              let style = {}
              let children
              if (!record.staticRaw) {
                if (goniometriya.editableIds.indexOf(item.id) === -1) {
                  style = { background: this.getCellColor(record.key, text) }
                  children = (
                    <Tooltip
                      placement="top"
                      title={this.getCellTooltip(record.key)}
                    >
                      <span style={{ cursor: 'help' }}>{text}</span>
                    </Tooltip>
                  )
                } else {
                  children = (
                    <InputNumber
                      min={-180}
                      max={180}
                      value={
                        goniometriya.editableItems[item.id][
                          `left_leg_${record.key}`
                        ]
                      }
                      onChange={value =>
                        this.props.setField('editableItems', {
                          ...goniometriya.editableItems,
                          [item.id]: {
                            ...goniometriya.editableItems[item.id],
                            [`left_leg_${record.key}`]: value
                          }
                        })
                      }
                    />
                  )
                }
              }
              return {
                props: { style },
                children
              }
            }
          }
        ]
      })
    })

    const rowKeys = [
      {
        key: 'on_the_back',
        type: <b>В положении на спине</b>,
        staticRaw: true
      },
      {
        key: 'sgibanie_tbs',
        type: 'Сгибание ТБС'
      },
      {
        key: 'abdukciya_pri_sognutom_tbs',
        type: 'Абдукция при согнутом ТБС'
      },
      {
        key: 'vnutrennyaya_rotaciya_bedra',
        type: 'Внутренняя ротация бедра'
      },
      {
        key: 'naruzhnaya_rotaciya_bedra',
        type: 'Наружная ротация бедра'
      },
      {
        key: 'sgibanie_ks',
        type: 'Сгибание КС'
      },
      {
        key: 'abdukciya_pri_razognutom_tbs',
        type: 'Абдукция при разогнутом ТБС'
      },
      {
        key: 'razgibanie_ks',
        type: 'Разгибание КС'
      },
      {
        key: 'tylnoe_sgibanie_gc',
        type: 'Тыльное сгибание ГС'
      },
      {
        key: 'tylnoe_sgibanie_gs_pri_sognutom_ks',
        type: 'Тыльное сгибание ГС при согнутом КС'
      },
      {
        key: 'harmstring',
        type: 'Harmstring'
      },
      {
        key: 'on-the-stomach',
        type: <b>В положении на животе</b>,
        staticRaw: true
      },
      {
        key: 'razgibanie_tbs',
        type: 'Разгибание ТБС'
      }
    ]

    let data = []

    if (goniometriya.items.length > 0) {
      rowKeys.forEach(row => {
        let rowObj = Object.assign({}, row)

        if (!row.staticRaw) {
          goniometriya.items.forEach(item => {
            rowObj[`right_leg_${item.id}`] = item[`right_leg_${rowObj.key}`]
            rowObj[`left_leg_${item.id}`] = item[`left_leg_${rowObj.key}`]
          })
        }

        data.push(rowObj)
      })
    }

    return (
      <Spin spinning={goniometriya.isLoading} indicator={antIcon}>
        <Button
          type="primary"
          onClick={() => this.setState({ isOpenModal: true })}
        >
          Создать
        </Button>
        <Modal
          title="Параметры оценки"
          visible={this.state.isOpenModal}
          onOk={() => {
            this.props.createTableItem(this.props.clientId, {
              date: this.state.createDate
            })
            this.setState({
              isOpenModal: false,
              createDate: moment().format('YYYY-MM-DD')
            })
          }}
          onCancel={() => this.setState({ isOpenModal: false })}
        >
          <div className="Client__modal-div">
            Дата
            <br />
            <DatePicker
              placeholder="Выберите дату"
              format={'DD.MM.YYYY'}
              value={moment(this.state.createDate, 'YYYY-MM-DD')}
              allowClear={false}
              onChange={(date, dateString) => {
                this.setState({
                  createDate: moment(dateString, 'DD.MM.YYYY').format(
                    'YYYY-MM-DD'
                  )
                })
              }}
            />
          </div>
        </Modal>
        <Row gutter={16} className="Clients__settings" align="middle">
          <Col span={21}>
            <DateRangeLoad
              clientId={this.props.clientId}
              getList={this.props.getList}
            />
          </Col>
          <Col span={3} className="Client__calendar-col">
            <Tooltip
              placement="bottom"
              mouseEnterDelay={0.75}
              title="Экспорт таблицы в MS Word"
            >
              <Button
                shape="circle"
                size="large"
                onClick={this.exportDocx.bind(this)}
              >
                <Icon type="file-word" className="Client__calendar-icon" />
              </Button>
            </Tooltip>
          </Col>
        </Row>
        <p></p>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{ x: `${columns.length * 240}px` }}
          rowClassName={record =>
            record.staticRaw
              ? 'Client__table-static-row ant-table-row-selected'
              : ''
          }
        />
      </Spin>
    )
  }
}

const mapStateToProps = store => {
  return {
    clientEstimates: store.clientEstimates,
    client: store.client,
    user: store.user
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    getGeneralData: id => dispatch(getGeneralData(id)),
    getList: (id, params = {}) =>
      dispatch(getList(id, 'goniometriya', 'goniometriya', params)),
    updateTableItem: (id, formObj) =>
      dispatch(updateTableItem(id, 'goniometriya', formObj)),
    deleteItem: id => dispatch(deleteItem(id, 'goniometriya', 'goniometriya')),
    createTableItem: (clientId, formObj) =>
      dispatch(createTableItem(clientId, 'goniometriya', formObj)),
    setField: (field, value) => dispatch(setField(field, value, 'goniometriya'))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GoniometriyaTab)
