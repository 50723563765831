import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { Tabs } from 'antd'
import qs from 'qs'

import { history } from '../../../redux/configureStore'

import CalendarTab from './Components/CalendarTabComponent'
import MyScheduleTab from './Components/MyScheduleTabComponent'

const { TabPane } = Tabs

class Schedule extends PureComponent {
  changeTab(key) {
    history.push({
      search: qs.stringify({ tab: key })
    })
  }

  render() {
    let defaultActiveKey = 'calendar'
    const getVars = qs.parse(document.location.search.substr(1))
    if (getVars.tab) {
      defaultActiveKey = getVars.tab
    }

    return (
      <div>
        <Helmet>
          <title>Расписание</title>
        </Helmet>
        <h1>Расписание</h1>
        <Tabs
          defaultActiveKey={defaultActiveKey}
          type="card"
          onChange={key => this.changeTab(key)}
          destroyInactiveTabPane
        >
          <TabPane tab="Расписание" key="calendar">
            <CalendarTab />
          </TabPane>
          {this.props.user.attributes.show_in_schedule_table === 1 && (
            <TabPane tab="Мои слоты" key="my-schedule">
              <MyScheduleTab />
            </TabPane>
          )}
        </Tabs>
      </div>
    )
  }
}

const mapStateToProps = store => {
  return {
    user: store.user
  }
}

export default connect(mapStateToProps)(Schedule)
