import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  Form,
  DatePicker,
  Input,
  Button,
  Switch,
  Icon,
  Spin,
  Select
} from 'antd'
import moment from 'moment'

import {
  setFieldForClient,
  createClient,
  updateClient
} from '../../../../redux/actions/ClientActions'

const { TextArea } = Input
const { Option } = Select

class ClientForm extends PureComponent {
  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { clientForm } = this.props.client
        let formObj = {}

        Object.keys(clientForm).forEach(item => {
          if (
            clientForm[item] !== null &&
            item !== 'id' &&
            item !== 'isOpen' &&
            item !== 'isSending'
          ) {
            formObj[item] = clientForm[item]
          }
        })

        if (!clientForm.id) {
          // create
          this.props.createClient(formObj)
        } else {
          // update
          this.props.updateClient(clientForm.id, formObj)
        }
      }
    })
  }

  render() {
    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />
    const { getFieldDecorator } = this.props.form

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    }

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 16,
          offset: 8
        }
      }
    }

    const clientForm = this.props.client.clientForm

    return clientForm.isOpen ? (
      <Spin spinning={clientForm.isSending} indicator={antIcon}>
        <Form
          {...formItemLayout}
          onSubmit={this.handleSubmit}
          autoComplete="off"
        >
          <Form.Item label="Имя">
            {getFieldDecorator('name', {
              rules: [{ required: true, message: 'Введите' }],
              initialValue: clientForm.name
            })(
              <Input
                placeholder="Введите"
                autoComplete="off"
                onChange={e =>
                  this.props.setFieldForClient('name', e.target.value)
                }
              />
            )}
          </Form.Item>
          <Form.Item label="Фамилия">
            {getFieldDecorator('surname', {
              rules: [{ required: true, message: 'Введите' }],
              initialValue: clientForm.surname
            })(
              <Input
                placeholder="Введите"
                autoComplete="off"
                onChange={e =>
                  this.props.setFieldForClient('surname', e.target.value)
                }
              />
            )}
          </Form.Item>
          <Form.Item label="Дата рождения">
            {getFieldDecorator('date_of_birth', {
              rules: [{ required: true, message: 'Выберите дату' }],
              initialValue: clientForm.date_of_birth
                ? moment(clientForm.date_of_birth, 'YYYY-MM-DD')
                : null
            })(
              <DatePicker
                placeholder="Выберите время"
                format={'DD.MM.YYYY'}
                onChange={(date, dateString) =>
                  this.props.setFieldForClient(
                    'date_of_birth',
                    moment(dateString, 'DD.MM.YYYY').format('YYYY-MM-DD')
                  )
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Фамилия ЗП №1">
            {getFieldDecorator('parent_one_surname', {
              rules: [{ required: true, message: 'Введите' }],
              initialValue: clientForm.parent_one_surname
            })(
              <Input
                placeholder="Введите"
                autoComplete="off"
                onChange={e =>
                  this.props.setFieldForClient(
                    'parent_one_surname',
                    e.target.value
                  )
                }
              />
            )}
          </Form.Item>
          <Form.Item label="Имя ЗП №1">
            {getFieldDecorator('parent_one_name', {
              rules: [{ required: true, message: 'Введите' }],
              initialValue: clientForm.parent_one_name
            })(
              <Input
                placeholder="Введите"
                autoComplete="off"
                onChange={e =>
                  this.props.setFieldForClient(
                    'parent_one_name',
                    e.target.value
                  )
                }
              />
            )}
          </Form.Item>
          <Form.Item label="Отчество ЗП №1">
            {getFieldDecorator('parent_one_patronymic', {
              rules: [{ required: false, message: 'Введите' }],
              initialValue: clientForm.parent_one_patronymic
            })(
              <Input
                placeholder="Введите"
                autoComplete="off"
                onChange={e =>
                  this.props.setFieldForClient(
                    'parent_one_patronymic',
                    e.target.value
                  )
                }
              />
            )}
          </Form.Item>
          <Form.Item label="Телефон ЗП №1">
            {getFieldDecorator('parent_one_phone', {
              rules: [{ required: true, message: 'Введите' }],
              initialValue: clientForm.parent_one_phone
            })(
              <Input
                placeholder="Введите"
                autoComplete="off"
                onChange={e =>
                  this.props.setFieldForClient(
                    'parent_one_phone',
                    e.target.value
                  )
                }
              />
            )}
          </Form.Item>
          <Form.Item label="E-mail ЗП №1">
            {getFieldDecorator('parent_one_email', {
              rules: [{ required: true, message: 'Введите' }],
              initialValue: clientForm.parent_one_email
            })(
              <Input
                placeholder="Введите"
                autoComplete="off"
                onChange={e =>
                  this.props.setFieldForClient(
                    'parent_one_email',
                    e.target.value
                  )
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Фамилия ЗП №2">
            {getFieldDecorator('parent_two_surname', {
              rules: [{ required: false, message: 'Введите' }],
              initialValue: clientForm.parent_two_surname
            })(
              <Input
                placeholder="Введите"
                autoComplete="off"
                onChange={e =>
                  this.props.setFieldForClient(
                    'parent_two_surname',
                    e.target.value
                  )
                }
              />
            )}
          </Form.Item>
          <Form.Item label="Имя ЗП №2">
            {getFieldDecorator('parent_two_name', {
              rules: [{ required: false, message: 'Введите' }],
              initialValue: clientForm.parent_two_name
            })(
              <Input
                placeholder="Введите"
                autoComplete="off"
                onChange={e =>
                  this.props.setFieldForClient(
                    'parent_two_name',
                    e.target.value
                  )
                }
              />
            )}
          </Form.Item>
          <Form.Item label="Отчество ЗП №2">
            {getFieldDecorator('parent_two_patronymic', {
              rules: [{ required: false, message: 'Введите' }],
              initialValue: clientForm.parent_two_patronymic
            })(
              <Input
                placeholder="Введите"
                autoComplete="off"
                onChange={e =>
                  this.props.setFieldForClient(
                    'parent_two_patronymic',
                    e.target.value
                  )
                }
              />
            )}
          </Form.Item>
          <Form.Item label="Телефон ЗП №2">
            {getFieldDecorator('parent_two_phone', {
              rules: [{ required: false, message: 'Введите' }],
              initialValue: clientForm.parent_two_phone
            })(
              <Input
                placeholder="Введите"
                autoComplete="off"
                onChange={e =>
                  this.props.setFieldForClient(
                    'parent_two_phone',
                    e.target.value
                  )
                }
              />
            )}
          </Form.Item>
          <Form.Item label="E-mail ЗП №2">
            {getFieldDecorator('parent_two_email', {
              rules: [{ required: false, message: 'Введите' }],
              initialValue: clientForm.parent_two_email
            })(
              <Input
                placeholder="Введите"
                autoComplete="off"
                onChange={e =>
                  this.props.setFieldForClient(
                    'parent_two_email',
                    e.target.value
                  )
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Адрес социального такси">
            {getFieldDecorator('social_taxi_address', {
              rules: [{ required: false }],
              initialValue: clientForm.social_taxi_address
            })(
              <TextArea
                rows={2}
                onChange={e =>
                  this.props.setFieldForClient(
                    'social_taxi_address',
                    e.target.value
                  )
                }
              />
            )}
          </Form.Item>
          <Form.Item label="Телефон">
            {getFieldDecorator('phone', {
              rules: [{ required: false, message: 'Введите' }],
              initialValue: clientForm.phone
            })(
              <Input
                placeholder="Введите"
                autoComplete="off"
                onChange={e =>
                  this.props.setFieldForClient('phone', e.target.value)
                }
              />
            )}
          </Form.Item>
          <Form.Item label="Соцсеть">
            {getFieldDecorator('social_url', {
              rules: [{ required: false, message: 'Введите' }],
              initialValue: clientForm.social_url
            })(
              <Input
                placeholder="Введите"
                autoComplete="off"
                onChange={e =>
                  this.props.setFieldForClient('social_url', e.target.value)
                }
              />
            )}
          </Form.Item>
          <Form.Item label="E-mail">
            {getFieldDecorator('email', {
              rules: [{ required: false, message: 'Введите' }],
              initialValue: clientForm.email
            })(
              <Input
                placeholder="Введите"
                autoComplete="off"
                onChange={e =>
                  this.props.setFieldForClient('email', e.target.value)
                }
              />
            )}
          </Form.Item>
          <Form.Item label="Индекс">
            {getFieldDecorator('post_index', {
              rules: [{ required: false, message: 'Введите' }],
              initialValue: clientForm.post_index
            })(
              <Input
                placeholder="Введите"
                autoComplete="off"
                onChange={e =>
                  this.props.setFieldForClient('post_index', e.target.value)
                }
              />
            )}
          </Form.Item>
          <Form.Item label="Страна">
            {getFieldDecorator('country', {
              rules: [{ required: true, message: 'Введите' }],
              initialValue: clientForm.country || undefined
            })(
              <Select
                style={{ width: '100%' }}
                placeholder="Выберите"
                showSearch
                onChange={value => {
                  this.props.setFieldForClient('country', value)

                  if (value === 'Россия') {
                    this.props.setFieldForClient('region', '')
                  }
                }}
              >
                <Option value="Австралия">Австралия</Option>
                <Option value="Австрия">Австрия</Option>
                <Option value="Азербайджан">Азербайджан</Option>
                <Option value="Албания">Албания</Option>
                <Option value="Алжир">Алжир</Option>
                <Option value="Американское Самоа">Американское Самоа</Option>
                <Option value="Ангилья">Ангилья</Option>
                <Option value="Ангола">Ангола</Option>
                <Option value="Андорра">Андорра</Option>
                <Option value="Антигуа и Барбуда">Антигуа и Барбуда</Option>
                <Option value="Аргентина">Аргентина</Option>
                <Option value="Армения">Армения</Option>
                <Option value="Аруба">Аруба</Option>
                <Option value="Афганистан">Афганистан</Option>
                <Option value="Багамы">Багамы</Option>
                <Option value="Бангладеш">Бангладеш</Option>
                <Option value="Барбадос">Барбадос</Option>
                <Option value="Бахрейн">Бахрейн</Option>
                <Option value="Беларусь">Беларусь</Option>
                <Option value="Белиз">Белиз</Option>
                <Option value="Бельгия">Бельгия</Option>
                <Option value="Бенин">Бенин</Option>
                <Option value="Бермуды">Бермуды</Option>
                <Option value="Болгария">Болгария</Option>
                <Option value="Боливия">Боливия</Option>
                <Option value="Бонайре, Синт-Эстатиус и Саба">
                  Бонайре, Синт-Эстатиус и Саба
                </Option>
                <Option value="Босния и Герцеговина">
                  Босния и Герцеговина
                </Option>
                <Option value="Ботсвана">Ботсвана</Option>
                <Option value="Бразилия">Бразилия</Option>
                <Option value="Бруней-Даруссалам">Бруней-Даруссалам</Option>
                <Option value="Буркина-Фасо">Буркина-Фасо</Option>
                <Option value="Бурунди">Бурунди</Option>
                <Option value="Бутан">Бутан</Option>
                <Option value="Вануату">Вануату</Option>
                <Option value="Ватикан">Ватикан</Option>
                <Option value="Великобритания">Великобритания</Option>
                <Option value="Венгрия">Венгрия</Option>
                <Option value="Венесуэла">Венесуэла</Option>
                <Option value="Виргинские острова, Британские">
                  Виргинские острова, Британские
                </Option>
                <Option value="Виргинские острова, США">
                  Виргинские острова, США
                </Option>
                <Option value="Восточный Тимор">Восточный Тимор</Option>
                <Option value="Вьетнам">Вьетнам</Option>
                <Option value="Габон">Габон</Option>
                <Option value="Гаити">Гаити</Option>
                <Option value="Гайана">Гайана</Option>
                <Option value="Гамбия">Гамбия</Option>
                <Option value="Гана">Гана</Option>
                <Option value="Гваделупа">Гваделупа</Option>
                <Option value="Гватемала">Гватемала</Option>
                <Option value="Гвинея">Гвинея</Option>
                <Option value="Гвинея-Бисау">Гвинея-Бисау</Option>
                <Option value="Германия">Германия</Option>
                <Option value="Гибралтар">Гибралтар</Option>
                <Option value="Гондурас">Гондурас</Option>
                <Option value="Гонконг">Гонконг</Option>
                <Option value="Гренада">Гренада</Option>
                <Option value="Гренландия">Гренландия</Option>
                <Option value="Греция">Греция</Option>
                <Option value="Грузия">Грузия</Option>
                <Option value="Гуам">Гуам</Option>
                <Option value="Дания">Дания</Option>
                <Option value="Джибути">Джибути</Option>
                <Option value="Доминика">Доминика</Option>
                <Option value="Доминиканская Республика">
                  Доминиканская Республика
                </Option>
                <Option value="Египет">Египет</Option>
                <Option value="Замбия">Замбия</Option>
                <Option value="Западная Сахара">Западная Сахара</Option>
                <Option value="Зимбабве">Зимбабве</Option>
                <Option value="Израиль">Израиль</Option>
                <Option value="Индия">Индия</Option>
                <Option value="Индонезия">Индонезия</Option>
                <Option value="Иордания">Иордания</Option>
                <Option value="Ирак">Ирак</Option>
                <Option value="Иран">Иран</Option>
                <Option value="Ирландия">Ирландия</Option>
                <Option value="Исландия">Исландия</Option>
                <Option value="Испания">Испания</Option>
                <Option value="Италия">Италия</Option>
                <Option value="Йемен">Йемен</Option>
                <Option value="Кабо-Верде">Кабо-Верде</Option>
                <Option value="Казахстан">Казахстан</Option>
                <Option value="Камбоджа">Камбоджа</Option>
                <Option value="Камерун">Камерун</Option>
                <Option value="Канада">Канада</Option>
                <Option value="Катар">Катар</Option>
                <Option value="Кения">Кения</Option>
                <Option value="Кипр">Кипр</Option>
                <Option value="Кирибати">Кирибати</Option>
                <Option value="Китай">Китай</Option>
                <Option value="Колумбия">Колумбия</Option>
                <Option value="Коморы">Коморы</Option>
                <Option value="Конго">Конго</Option>
                <Option value="Конго, демократическая республика">
                  Конго, демократическая республика
                </Option>
                <Option value="Коста-Рика">Коста-Рика</Option>
                <Option value="Кот д`Ивуар">Кот д`Ивуар</Option>
                <Option value="Куба">Куба</Option>
                <Option value="Кувейт">Кувейт</Option>
                <Option value="Кыргызстан">Кыргызстан</Option>
                <Option value="Кюрасао">Кюрасао</Option>
                <Option value="Лаос">Лаос</Option>
                <Option value="Латвия">Латвия</Option>
                <Option value="Лесото">Лесото</Option>
                <Option value="Либерия">Либерия</Option>
                <Option value="Ливан">Ливан</Option>
                <Option value="Ливия">Ливия</Option>
                <Option value="Литва">Литва</Option>
                <Option value="Лихтенштейн">Лихтенштейн</Option>
                <Option value="Люксембург">Люксембург</Option>
                <Option value="Маврикий">Маврикий</Option>
                <Option value="Мавритания">Мавритания</Option>
                <Option value="Мадагаскар">Мадагаскар</Option>
                <Option value="Макао">Макао</Option>
                <Option value="Македония">Македония</Option>
                <Option value="Малави">Малави</Option>
                <Option value="Малайзия">Малайзия</Option>
                <Option value="Мали">Мали</Option>
                <Option value="Мальдивы">Мальдивы</Option>
                <Option value="Мальта">Мальта</Option>
                <Option value="Марокко">Марокко</Option>
                <Option value="Мартиника">Мартиника</Option>
                <Option value="Маршалловы Острова">Маршалловы Острова</Option>
                <Option value="Мексика">Мексика</Option>
                <Option value="Микронезия, федеративные штаты">
                  Микронезия, федеративные штаты
                </Option>
                <Option value="Мозамбик">Мозамбик</Option>
                <Option value="Молдова">Молдова</Option>
                <Option value="Монако">Монако</Option>
                <Option value="Монголия">Монголия</Option>
                <Option value="Монтсеррат">Монтсеррат</Option>
                <Option value="Мьянма">Мьянма</Option>
                <Option value="Намибия">Намибия</Option>
                <Option value="Науру">Науру</Option>
                <Option value="Непал">Непал</Option>
                <Option value="Нигер">Нигер</Option>
                <Option value="Нигерия">Нигерия</Option>
                <Option value="Нидерланды">Нидерланды</Option>
                <Option value="Никарагуа">Никарагуа</Option>
                <Option value="Ниуэ">Ниуэ</Option>
                <Option value="Новая Зеландия">Новая Зеландия</Option>
                <Option value="Новая Каледония">Новая Каледония</Option>
                <Option value="Норвегия">Норвегия</Option>
                <Option value="Объединенные Арабские Эмираты">
                  Объединенные Арабские Эмираты
                </Option>
                <Option value="Оман">Оман</Option>
                <Option value="Остров Мэн">Остров Мэн</Option>
                <Option value="Остров Норфолк">Остров Норфолк</Option>
                <Option value="Острова Кайман">Острова Кайман</Option>
                <Option value="Острова Кука">Острова Кука</Option>
                <Option value="Острова Теркс и Кайкос">
                  Острова Теркс и Кайкос
                </Option>
                <Option value="Пакистан">Пакистан</Option>
                <Option value="Палау">Палау</Option>
                <Option value="Палестинская автономия">
                  Палестинская автономия
                </Option>
                <Option value="Панама">Панама</Option>
                <Option value="Папуа - Новая Гвинея">
                  Папуа - Новая Гвинея
                </Option>
                <Option value="Парагвай">Парагвай</Option>
                <Option value="Перу">Перу</Option>
                <Option value="Питкерн">Питкерн</Option>
                <Option value="Польша">Польша</Option>
                <Option value="Португалия">Португалия</Option>
                <Option value="Пуэрто-Рико">Пуэрто-Рико</Option>
                <Option value="Реюньон">Реюньон</Option>
                <Option value="Россия">Россия</Option>
                <Option value="Руанда">Руанда</Option>
                <Option value="Румыния">Румыния</Option>
                <Option value="США">США</Option>
                <Option value="Сальвадор">Сальвадор</Option>
                <Option value="Самоа">Самоа</Option>
                <Option value="Сан-Марино">Сан-Марино</Option>
                <Option value="Сан-Томе и Принсипи">Сан-Томе и Принсипи</Option>
                <Option value="Саудовская Аравия">Саудовская Аравия</Option>
                <Option value="Свазиленд">Свазиленд</Option>
                <Option value="Святая Елена">Святая Елена</Option>
                <Option value="Северная Корея">Северная Корея</Option>
                <Option value="Северные Марианские острова">
                  Северные Марианские острова
                </Option>
                <Option value="Сейшелы">Сейшелы</Option>
                <Option value="Сенегал">Сенегал</Option>
                <Option value="Сент-Винсент">Сент-Винсент</Option>
                <Option value="Сент-Китс и Невис">Сент-Китс и Невис</Option>
                <Option value="Сент-Люсия">Сент-Люсия</Option>
                <Option value="Сент-Пьер и Микелон">Сент-Пьер и Микелон</Option>
                <Option value="Сербия">Сербия</Option>
                <Option value="Сингапур">Сингапур</Option>
                <Option value="Синт-Мартен">Синт-Мартен</Option>
                <Option value="Сирийская Арабская Республика">
                  Сирийская Арабская Республика
                </Option>
                <Option value="Словакия">Словакия</Option>
                <Option value="Словения">Словения</Option>
                <Option value="Соломоновы Острова">Соломоновы Острова</Option>
                <Option value="Сомали">Сомали</Option>
                <Option value="Судан">Судан</Option>
                <Option value="Суринам">Суринам</Option>
                <Option value="Сьерра-Леоне">Сьерра-Леоне</Option>
                <Option value="Таджикистан">Таджикистан</Option>
                <Option value="Таиланд">Таиланд</Option>
                <Option value="Тайвань">Тайвань</Option>
                <Option value="Танзания">Танзания</Option>
                <Option value="Того">Того</Option>
                <Option value="Токелау">Токелау</Option>
                <Option value="Тонга">Тонга</Option>
                <Option value="Тринидад и Тобаго">Тринидад и Тобаго</Option>
                <Option value="Тувалу">Тувалу</Option>
                <Option value="Тунис">Тунис</Option>
                <Option value="Туркменистан">Туркменистан</Option>
                <Option value="Турция">Турция</Option>
                <Option value="Уганда">Уганда</Option>
                <Option value="Узбекистан">Узбекистан</Option>
                <Option value="Украина">Украина</Option>
                <Option value="Уоллис и Футуна">Уоллис и Футуна</Option>
                <Option value="Уругвай">Уругвай</Option>
                <Option value="Фарерские острова">Фарерские острова</Option>
                <Option value="Фиджи">Фиджи</Option>
                <Option value="Филиппины">Филиппины</Option>
                <Option value="Финляндия">Финляндия</Option>
                <Option value="Фолклендские острова">
                  Фолклендские острова
                </Option>
                <Option value="Франция">Франция</Option>
                <Option value="Французская Гвиана">Французская Гвиана</Option>
                <Option value="Французская Полинезия">
                  Французская Полинезия
                </Option>
                <Option value="Хорватия">Хорватия</Option>
                <Option value="Центрально-Африканская Республика">
                  Центрально-Африканская Республика
                </Option>
                <Option value="Чад">Чад</Option>
                <Option value="Черногория">Черногория</Option>
                <Option value="Чехия">Чехия</Option>
                <Option value="Чили">Чили</Option>
                <Option value="Швейцария">Швейцария</Option>
                <Option value="Швеция">Швеция</Option>
                <Option value="Шпицберген и Ян Майен">
                  Шпицберген и Ян Майен
                </Option>
                <Option value="Шри-Ланка">Шри-Ланка</Option>
                <Option value="Эквадор">Эквадор</Option>
                <Option value="Экваториальная Гвинея">
                  Экваториальная Гвинея
                </Option>
                <Option value="Эритрея">Эритрея</Option>
                <Option value="Эстония">Эстония</Option>
                <Option value="Эфиопия">Эфиопия</Option>
                <Option value="Южная Корея">Южная Корея</Option>
                <Option value="Южно-Африканская Республика">
                  Южно-Африканская Республика
                </Option>
                <Option value="Южный Судан">Южный Судан</Option>
                <Option value="Ямайка">Ямайка</Option>
                <Option value="Япония">Япония</Option>
              </Select>
            )}
          </Form.Item>
          {clientForm.country === 'Россия' && (
            <Form.Item label="Регион">
              {getFieldDecorator('region', {
                rules: [{ required: true, message: 'Введите' }],
                initialValue: clientForm.region || undefined
              })(
                <Select
                  style={{ width: '100%' }}
                  placeholder="Выберите"
                  showSearch
                  onChange={value => {
                    this.props.setFieldForClient('region', value)
                  }}
                >
                  <Option value="Адыгея">Республика Адыгея</Option>
                  <Option value="Алтай">Республика Алтай </Option>
                  <Option value="Башкортостан">Республика Башкортостан </Option>
                  <Option value="Бурятия">Республика Бурятия </Option>
                  <Option value="Дагестан">Республика Дагестан </Option>
                  <Option value="Донецкая Народная Республика">
                    Донецкая Народная Республика
                  </Option>
                  <Option value="Ингушетия">Республика Ингушетия </Option>
                  <Option value="Кабардино-Балкария">
                    Кабардино-Балкарская Республика
                  </Option>
                  <Option value="Калмыкия">Республика Калмыкия </Option>
                  <Option value="Карачаево-Черкессия">
                    Карачаево-Черкесская Республика
                  </Option>
                  <Option value="Карелия">Республика Карелия </Option>
                  <Option value="Коми">Республика Коми </Option>
                  <Option value="Крым">Республика Крым </Option>
                  <Option value="Луганская Народная Республика">
                    Луганская Народная Республика
                  </Option>
                  <Option value="Марий Эл">Республика Марий Эл </Option>
                  <Option value="Мордовия">Республика Мордовия</Option>
                  <Option value="Саха (Якутия)">
                    Республика Саха (Якутия){' '}
                  </Option>
                  <Option value="Северная Осетия - Алания">
                    Республика Северная Осетия - Алания{' '}
                  </Option>
                  <Option value="Татарстан">Республика Татарстан</Option>
                  <Option value="Тыва">Республика Тыва </Option>
                  <Option value="Удмуртская">Удмуртская Республика </Option>
                  <Option value="Хакасия">Республика Хакасия </Option>
                  <Option value="Чеченская">Чеченская Республика</Option>
                  <Option value="Чувашская Республика">
                    Чувашская Республика
                  </Option>
                  <Option value="Алтайский край">Алтайский край</Option>
                  <Option value="Забайкальский край">Забайкальский край</Option>
                  <Option value="Камчатский край">Камчатский край</Option>
                  <Option value="Краснодарский край">Краснодарский край</Option>
                  <Option value="Красноярский край">Красноярский край</Option>
                  <Option value="Пермский край">Пермский край</Option>
                  <Option value="Приморский край">Приморский край</Option>
                  <Option value="Ставропольский край">
                    Ставропольский край
                  </Option>
                  <Option value="Хабаровский край">Хабаровский край</Option>
                  <Option value="Амурская область">Амурская область</Option>
                  <Option value="Архангельская область">
                    Архангельская область
                  </Option>
                  <Option value="Астраханская область">
                    Астраханская область
                  </Option>
                  <Option value="Белгородская область">
                    Белгородская область
                  </Option>
                  <Option value="Брянская область">Брянская область </Option>
                  <Option value="Владимирская область">
                    Владимирская область{' '}
                  </Option>
                  <Option value="Волгоградская область">
                    Волгоградская область{' '}
                  </Option>
                  <Option value="Вологодская область">
                    Вологодская область{' '}
                  </Option>
                  <Option value="Воронежская область">
                    Воронежская область{' '}
                  </Option>
                  <Option value="Запорожская область">
                    Запорожская область{' '}
                  </Option>
                  <Option value="Ивановская область">
                    Ивановская область{' '}
                  </Option>
                  <Option value="Иркутская область">Иркутская область </Option>
                  <Option value="Калининградская область">
                    Калининградская область
                  </Option>
                  <Option value="Калужская область">Калужская область </Option>
                  <Option value="Кемеровская область">
                    Кемеровская область{' '}
                  </Option>
                  <Option value="Кировская область">Кировская область </Option>
                  <Option value="Костромская область">
                    Костромская область{' '}
                  </Option>
                  <Option value="Курганская область">
                    Курганская область{' '}
                  </Option>
                  <Option value="Курская область">Курская область </Option>
                  <Option value="Ленинградская область">
                    Ленинградская область{' '}
                  </Option>
                  <Option value="Липецкая область">Липецкая область </Option>
                  <Option value="Магаданская область">
                    Магаданская область
                  </Option>
                  <Option value="Московская область">
                    Московская область{' '}
                  </Option>
                  <Option value="Мурманская область">
                    Мурманская область{' '}
                  </Option>
                  <Option value="Нижегородская область">
                    Нижегородская область{' '}
                  </Option>
                  <Option value="Новгородская область">
                    Новгородская область{' '}
                  </Option>
                  <Option value="Новосибирская область">
                    Новосибирская область{' '}
                  </Option>
                  <Option value="Омская область">Омская область</Option>
                  <Option value="Оренбургская область">
                    Оренбургская область{' '}
                  </Option>
                  <Option value="Орловская область">Орловская область </Option>
                  <Option value="Пензенская область">
                    Пензенская область{' '}
                  </Option>
                  <Option value="Псковская область">Псковская область </Option>
                  <Option value="Ростовская область">
                    Ростовская область{' '}
                  </Option>
                  <Option value="Рязанская область">Рязанская область </Option>
                  <Option value="Самарская область">Самарская область </Option>
                  <Option value="Саратовская область">
                    Саратовская область{' '}
                  </Option>
                  <Option value="Сахалинская область">
                    Сахалинская область{' '}
                  </Option>
                  <Option value="Свердловская область">
                    Свердловская область{' '}
                  </Option>
                  <Option value="Смоленская область">
                    Смоленская область{' '}
                  </Option>
                  <Option value="Тамбовская область">
                    Тамбовская область{' '}
                  </Option>
                  <Option value="Тверская область">Тверская область </Option>
                  <Option value="Томская область">Томская область </Option>
                  <Option value="Тульская область">Тульская область</Option>
                  <Option value="Тюменская область">Тюменская область </Option>
                  <Option value="Ульяновская область">
                    Ульяновская область{' '}
                  </Option>
                  <Option value="Херсонская область">
                    Херсонская область{' '}
                  </Option>
                  <Option value="Челябинская область">
                    Челябинская область{' '}
                  </Option>
                  <Option value="Ярославская область">
                    Ярославская область
                  </Option>
                  <Option value="Москва">Москва</Option>
                  <Option value="Санкт-Петербург">Санкт-Петербург</Option>
                  <Option value="Севастополь">Севастополь</Option>
                  <Option value="Еврейская АО">Еврейская АО</Option>
                  <Option value="Ненецкий АО">Ненецкий АО</Option>
                  <Option value="Ханты-Мансийский АО">
                    Ханты-Мансийский АО
                  </Option>
                  <Option value="Чукотский АО">Чукотский АО</Option>
                  <Option value="Ямало-Ненецкий АО">Ямало-Ненецкий АО</Option>
                </Select>
              )}
            </Form.Item>
          )}
          <Form.Item label="Населенный пункт">
            {getFieldDecorator('city', {
              rules: [{ required: true, message: 'Введите' }],
              initialValue: clientForm.city
            })(
              <Input
                placeholder="Введите"
                autoComplete="off"
                onChange={e =>
                  this.props.setFieldForClient('city', e.target.value)
                }
              />
            )}
          </Form.Item>
          <Form.Item label="Улица">
            {getFieldDecorator('street', {
              rules: [{ required: true, message: 'Введите' }],
              initialValue: clientForm.street
            })(
              <Input
                placeholder="Введите"
                autoComplete="off"
                onChange={e =>
                  this.props.setFieldForClient('street', e.target.value)
                }
              />
            )}
          </Form.Item>
          <Form.Item label="Номер дома">
            {getFieldDecorator('home_number', {
              rules: [{ required: true, message: 'Введите' }],
              initialValue: clientForm.home_number
            })(
              <Input
                placeholder="Введите"
                autoComplete="off"
                onChange={e =>
                  this.props.setFieldForClient('home_number', e.target.value)
                }
              />
            )}
          </Form.Item>
          <Form.Item label="Корпус">
            {getFieldDecorator('home_corps', {
              rules: [{ required: true, message: 'Введите или поставьте «–»' }],
              initialValue: clientForm.home_corps
            })(
              <Input
                placeholder="Введите или поставьте «–»"
                autoComplete="off"
                onChange={e =>
                  this.props.setFieldForClient('home_corps', e.target.value)
                }
              />
            )}
          </Form.Item>
          <Form.Item label="Литера">
            {getFieldDecorator('home_letter', {
              rules: [{ required: true, message: 'Введите или поставьте «–»' }],
              initialValue: clientForm.home_letter
            })(
              <Input
                placeholder="Введите или поставьте «–»"
                autoComplete="off"
                onChange={e =>
                  this.props.setFieldForClient('home_letter', e.target.value)
                }
              />
            )}
          </Form.Item>
          <Form.Item label="Строение">
            {getFieldDecorator('home_structure', {
              rules: [{ required: true, message: 'Введите или поставьте «–»' }],
              initialValue: clientForm.home_structure
            })(
              <Input
                placeholder="Введите или поставьте «–»"
                autoComplete="off"
                onChange={e =>
                  this.props.setFieldForClient('home_structure', e.target.value)
                }
              />
            )}
          </Form.Item>
          <Form.Item label="Квартира">
            {getFieldDecorator('home_flat_number', {
              rules: [{ required: true, message: 'Введите или поставьте «–»' }],
              initialValue: clientForm.home_flat_number
            })(
              <Input
                placeholder="Введите или поставьте «–»"
                autoComplete="off"
                onChange={e =>
                  this.props.setFieldForClient(
                    'home_flat_number',
                    e.target.value
                  )
                }
              />
            )}
          </Form.Item>
          <Form.Item label="Разница с Мск">
            {getFieldDecorator('difference_in_time', {
              rules: [{ required: false, message: 'Введите' }],
              initialValue: clientForm.difference_in_time
            })(
              <Input
                placeholder="Введите"
                autoComplete="off"
                onChange={e =>
                  this.props.setFieldForClient(
                    'difference_in_time',
                    e.target.value
                  )
                }
              />
            )}
          </Form.Item>

          <Form.Item label="В архиве">
            <Switch
              checked={clientForm.is_archive === 1 ? true : false}
              onChange={value =>
                this.props.setFieldForClient('is_archive', value ? 1 : 0)
              }
            />
          </Form.Item>

          {clientForm.is_archive === 1 && (
            <Form.Item label="Причина попадания в архив">
              {getFieldDecorator('archive_cause', {
                rules: [{ required: false }],
                initialValue: clientForm.archive_cause
              })(
                <TextArea
                  rows={2}
                  onChange={e =>
                    this.props.setFieldForClient(
                      'archive_cause',
                      e.target.value
                    )
                  }
                />
              )}
            </Form.Item>
          )}

          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    ) : null
  }
}

const mapStateToProps = store => {
  return {
    client: store.client
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setFieldForClient: (field, value) =>
      dispatch(setFieldForClient(field, value)),
    createClient: formObj => dispatch(createClient(formObj)),
    updateClient: (id, formObj) => dispatch(updateClient(id, formObj))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'ClientForm' })(ClientForm))
