import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  Form,
  DatePicker,
  Button,
  Icon,
  Spin,
  Select,
  Table,
  Switch
} from 'antd'
import moment from 'moment'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import {
  setFieldForClient,
  createService,
  getUsersList,
  updateService,
  updateSpecialists,
  setTypeForSpecialist
} from '../../../../redux/actions/ClientActions'

const { Option } = Select

class ServiceForm extends PureComponent {
  state = {
    usersList: []
  }

  componentDidMount() {
    this.props.getUsersList()
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      usersList: nextProps.client.commonData.users_list
    })
  }

  searchUsers = value => {
    value = value.toLowerCase()
    let usersList = []
    this.props.client.commonData.users_list.forEach(item => {
      if (
        item.name.toLowerCase().includes(value) ||
        item.surname.toLowerCase().includes(value)
      ) {
        usersList.push(item)
      }
    })
    this.setState({ usersList })
  }

  handleSubmit = e => {
    e.preventDefault()

    const { clientId } = this.props

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { serviceForm } = this.props.client
        let formObj = {}

        Object.keys(serviceForm).forEach(item => {
          if (
            serviceForm[item] !== null &&
            item !== 'id' &&
            item !== 'isOpen' &&
            item !== 'isSending'
          ) {
            formObj[item] = serviceForm[item]
          }
        })

        if (parseInt(formObj.status) !== 2) {
          formObj.reason_for_absence = 0
        }

        if (!serviceForm.id) {
          // create
          this.props.createService(clientId, formObj)
        } else {
          // update
          this.props.updateService(serviceForm.id, formObj)
        }
      }
    })
  }

  getUserNameById(id) {
    const commonData = this.props.client.commonData

    const users = commonData.users_list.filter(item => item.id === id)

    return users.length > 0
      ? users[0].name + ' ' + users[0].surname
      : 'пользователь не найден'
  }

  disabledDate(current) {
    const { user, settings } = this.props
    if (settings.params.report_permanent_access.indexOf(user.id) !== -1)
      return false
    return current && moment(current).isBefore(settings.params.report_last_date)
  }

  render() {
    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />

    const serviceForm = this.props.client.serviceForm
    const { user, settings } = this.props

    const disableEditing =
      settings.params.report_permanent_access.indexOf(user.id) === -1 &&
      moment(serviceForm.date, 'YYYY-MM-DD HH:mm').isBefore(
        settings.params.report_last_date
      )

    const { getFieldDecorator } = this.props.form

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 }
      }
    }

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 20,
          offset: 6
        }
      }
    }

    const specialistsDataSource = serviceForm.specialists.map(item => ({
      key: item.id,
      name: this.getUserNameById(item.id),
      type: (
        <Select
          placeholder="Выберите"
          allowClear
          style={{ width: '200px' }}
          value={item.type}
          onChange={value => {
            this.props.setTypeForSpecialist(item.id, value)
          }}
          disabled={disableEditing}
        >
          <Option value={1}>Физический терапевт</Option>
          <Option value={2}>Эрготерапевт</Option>
          <Option value={3}>Специалист по коммуникации</Option>
          <Option value={4}>Психолог</Option>
          <Option value={5}>Подбор и адаптация ТСР</Option>
          <Option value={6}>Врач ФРМ</Option>
          <Option value={7}>Анализ походки</Option>
          <Option value={8}>Логопед</Option>
          <Option value={9}>Специалист программы ранней помощи</Option>
          <Option value={10}>Супервизор программы ранней помощи</Option>
        </Select>
      )
    }))

    const columns = [
      {
        title: 'Специалист',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: 'Тип',
        dataIndex: 'type',
        key: 'type'
      }
    ]

    return serviceForm.isOpen ? (
      <Spin spinning={serviceForm.isSending} indicator={antIcon}>
        <Form
          {...formItemLayout}
          onSubmit={this.handleSubmit}
          autoComplete="off"
        >
          <Form.Item label="Тип услуги">
            {getFieldDecorator('type', {
              rules: [{ required: true, message: 'Выберите' }],
              initialValue: serviceForm.type ? serviceForm.type : null
            })(
              <Select
                placeholder="Выберите"
                onChange={value => {
                  this.props.setFieldForClient('type', value)
                  if (value === 7) {
                    this.props.setFieldForClient('is_primary', 0)
                    this.props.setFieldForClient('length', 120)
                  } else {
                    this.props.setFieldForClient(
                      'length',
                      serviceForm.is_primary === 1 ? 90 : 60
                    )
                  }
                }}
                disabled={disableEditing}
              >
                <Option value={1}>Консультация в центре</Option>
                <Option value={2}>Консультация онлайн</Option>
                <Option value={3}>Консультация на дому</Option>
                <Option value={4}>Индивидуальные занятия в центре</Option>
                <Option value={8}>Индивидуальные занятия на дому</Option>
                <Option value={9}>Индивидуальные занятия онлайн</Option>
                <Option value={5}>Семейная встреча онлайн</Option>
                <Option value={6}>Семейная встреча в центре</Option>
                <Option value={7}>Групповые занятия</Option>
                <Option value={10}>Фитнес-группа онлайн</Option>
              </Select>
            )}
          </Form.Item>
          {serviceForm.type === 7 && (
            <Form.Item label="Длительность">
              <Select
                placeholder="Выберите"
                onChange={value => {
                  this.props.setFieldForClient('length', value)
                }}
                disabled={disableEditing}
                value={serviceForm.length}
              >
                <Option value={120}>2 часа</Option>
                <Option value={240}>4 часа</Option>
              </Select>
            </Form.Item>
          )}
          <Form.Item label="Дата">
            {getFieldDecorator('date', {
              rules: [{ required: true, message: 'Выберите дату' }],
              initialValue: serviceForm.date
                ? moment(serviceForm.date, 'YYYY-MM-DD HH:mm')
                : null
            })(
              <DatePicker
                showTime={{
                  format: 'HH:mm',
                  minuteStep: 15
                }}
                format={'DD.MM.YYYY HH:mm'}
                placeholder="Выберите дату"
                onChange={(date, dateString) =>
                  this.props.setFieldForClient(
                    'date',
                    moment(dateString, 'DD.MM.YYYY HH:mm').format(
                      'YYYY-MM-DD HH:mm'
                    )
                  )
                }
                disabledDate={this.disabledDate.bind(this)}
                disabled={disableEditing}
              />
            )}
          </Form.Item>
          <Form.Item
            label="Заключение (сайт)"
            extra="ВНИМАНИЕ! Заключение видно клиенту"
          >
            <CKEditor
              editor={ClassicEditor}
              data={serviceForm.conclusion}
              disabled={disableEditing}
              config={{
                toolbar: ['heading', 'bold', 'italic', 'link', 'blockQuote'],
                heading: {
                  options: [
                    {
                      model: 'paragraph',
                      title: 'Параграф',
                      class: 'ck-heading_paragraph'
                    },
                    {
                      model: 'heading2',
                      view: 'h2',
                      title: 'Заголовок',
                      class: 'ck-heading_heading2'
                    }
                  ]
                }
              }}
              onChange={(event, editor) => {
                const data = editor.getData()
                this.props.setFieldForClient('conclusion', data)
              }}
            />
          </Form.Item>
          <Form.Item label="Комментарий">
            <CKEditor
              editor={ClassicEditor}
              data={serviceForm.comment}
              disabled={disableEditing}
              config={{
                toolbar: ['heading', 'bold', 'italic', 'link', 'blockQuote'],
                heading: {
                  options: [
                    {
                      model: 'paragraph',
                      title: 'Параграф',
                      class: 'ck-heading_paragraph'
                    },
                    {
                      model: 'heading2',
                      view: 'h2',
                      title: 'Заголовок',
                      class: 'ck-heading_heading2'
                    }
                  ]
                }
              }}
              onChange={(event, editor) => {
                const data = editor.getData()
                this.props.setFieldForClient('comment', data)
              }}
            />
          </Form.Item>
          <Form.Item label="Статус">
            {getFieldDecorator('status', {
              rules: [{ required: true, message: 'Выберите' }],
              initialValue: serviceForm.status
            })(
              <Select
                placeholder="Выберите"
                onChange={value =>
                  this.props.setFieldForClient('status', value)
                }
                disabled={disableEditing}
              >
                <Option value={0}>предстоит</Option>
                <Option value={1}>посетил</Option>
                <Option value={2}>не посетил</Option>
              </Select>
            )}
          </Form.Item>
          {serviceForm.status === 2 && (
            <Form.Item label="Причина неявки">
              {getFieldDecorator('reason_for_absence', {
                rules: [{ required: true, message: 'Выберите' }],
                initialValue: serviceForm.reason_for_absence
                  ? serviceForm.reason_for_absence
                  : null
              })(
                <Select
                  placeholder="Выберите"
                  onChange={value =>
                    this.props.setFieldForClient('reason_for_absence', value)
                  }
                  disabled={disableEditing}
                >
                  <Option value={1}>уважительная</Option>
                  <Option value={2}>неуважительная</Option>
                </Select>
              )}
            </Form.Item>
          )}

          <Form.Item label="Специалисты">
            {getFieldDecorator('specialistIds', {
              rules: [{ required: true, message: 'Выберите специалиста' }],
              initialValue: serviceForm.specialistIds
                ? serviceForm.specialistIds
                : null
            })(
              <Select
                placeholder="Выберите"
                onChange={value => {
                  this.props.setFieldForClient('specialistIds', value)
                  this.props.updateSpecialists(value)
                }}
                showSearch
                mode="multiple"
                onSearch={this.searchUsers}
                filterOption={false}
                disabled={disableEditing}
              >
                {this.state.usersList.map(item => (
                  <Option key={item.id} value={item.id}>
                    {item.surname} {item.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item label={'Специальности'}>
            <Table
              dataSource={specialistsDataSource}
              columns={columns}
              pagination={false}
            />
          </Form.Item>

          {serviceForm.type && serviceForm.type !== 7 && (
            <Form.Item label="Первичная">
              <Switch
                checked={serviceForm.is_primary === 1 ? true : false}
                onChange={value => {
                  this.props.setFieldForClient('is_primary', value ? 1 : 0)
                  this.props.setFieldForClient('length', value ? 90 : 60)
                }}
              />
            </Form.Item>
          )}

          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit" disabled={disableEditing}>
              Сохранить
            </Button>
          </Form.Item>

          {serviceForm.id && (
            <Form.Item {...tailFormItemLayout}>
              <p>
                Создана: {serviceForm.user_creating.user_creating_name}{' '}
                {serviceForm.user_creating.user_creating_surname},{' '}
                {moment(serviceForm.date_creating).format('DD.MM.YYYY HH:mm')}
                <br />
                Последнее редактирование:{' '}
                {serviceForm.user_last_editing.user_last_editing_name}{' '}
                {serviceForm.user_last_editing.user_last_editing_surname},{' '}
                {moment(serviceForm.date_last_editing).format(
                  'DD.MM.YYYY HH:mm'
                )}
              </p>
            </Form.Item>
          )}
        </Form>
      </Spin>
    ) : null
  }
}

const mapStateToProps = store => {
  return {
    client: store.client,
    user: store.user,
    settings: store.settings
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    setFieldForClient: (field, value) =>
      dispatch(setFieldForClient(field, value, 'serviceForm')),
    updateSpecialists: value =>
      dispatch(updateSpecialists(value, 'serviceForm')),
    setTypeForSpecialist: (id, value) =>
      dispatch(setTypeForSpecialist(id, value, 'serviceForm')),
    createService: (clientId, formObj) =>
      dispatch(createService(clientId, formObj)),
    getUsersList: () => dispatch(getUsersList()),
    updateService: (id, formObj) => dispatch(updateService(id, formObj))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'ServiceForm' })(ServiceForm))
