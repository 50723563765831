import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Icon, Spin, Table, Badge, DatePicker, Button } from 'antd'
import moment from 'moment'

import { getCalendar } from '../../../../redux/actions/ScheduleActions'

const { RangePicker } = DatePicker

class CalendarTab extends PureComponent {
  state = {
    dateRange: [
      moment().format('YYYY-MM-DD'),
      moment()
        .add(13, 'd')
        .format('YYYY-MM-DD')
    ]
  }

  componentDidMount() {
    this.props.getCalendar(this.state.dateRange[0], this.state.dateRange[1])
  }

  getTimespanTypeName(type) {
    let typeName = ''

    switch (type) {
      case 0:
        typeName = 'онлайн'
        break
      case 1:
        typeName = 'очно'
        break
      case 2:
        typeName = 'онлайн/очно'
        break
      default:
        break
    }

    return typeName
  }

  generateCellData(timespans) {
    let badges = []

    let processedTimespans = {}
    timespans.forEach((item, index) => {
      if (
        processedTimespans[item.time_s] &&
        processedTimespans[item.time_s].type === 'service' &&
        processedTimespans[item.time_s].services_type === 7 &&
        item.type === 'service' &&
        item.info.service_type === 7
      ) {
        processedTimespans[item.time_s].services.push({ ...item.info })
      } else if (item.type === 'timespan') {
        processedTimespans[item.time_s] = {
          time_s: item.time_s,
          time_e: item.time_e,
          type: item.type,
          static_text: this.getTimespanTypeName(item.info.timespan_type),
          services: null,
          services_type: null
        }
      } else if (item.type === 'service') {
        processedTimespans[item.time_s] = {
          time_s: item.time_s,
          time_e: item.time_e,
          type: item.type,
          static_text: null,
          services: [{ ...item.info }],
          services_type: item.info.service_type,
          services_is_primary: item.info.is_primary
        }
      }
    })

    Object.keys(processedTimespans).forEach((key, index) => {
      const item = processedTimespans[key]
      let content = item.static_text

      if (item.services) {
        content = item.services.map((service, index) => (
          <Link
            to={`/client/${service.client_id}?tab=services&id=${service.service_id}`}
            key={service.service_id}
          >
            {service.client_name}&nbsp;({service.client_id})
            {index < item.services.length - 1 ? ', ' : ''}
          </Link>
        ))
      }

      let text = (
        <span>
          {item.time_s} – {item.time_e}, {content}
        </span>
      )

      let status = ''
      switch (item.type) {
        case 'timespan':
          status = 'success'
          break
        case 'service':
          status = item.services_is_primary === 1 ? 'error' : 'warning'
          break
        default:
          break
      }
      badges.push(
        <div key={index}>
          <Badge status={status} text={text} />
        </div>
      )
    })

    return badges.length > 0 ? badges : '–'
  }

  render() {
    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />

    const { calendar } = this.props.schedule

    let columns = [
      {
        title: 'Специалист',
        dataIndex: 'specialist',
        width: 225,
        fixed: 'left'
      }
    ]
    calendar.dates.forEach(date => {
      columns.push({
        title: moment(date, 'YYYY-MM-DD').format('DD.MM, ddd'),
        dataIndex: moment(date, 'YYYY-MM-DD').format('YYYY_MM_DD'),
        render: (text, record) => {
          return this.generateCellData(text)
        }
      })
    })

    const data = calendar.specialists.map(item => ({
      key: item.id,
      specialist: `${item.surname} ${item.name}`,
      ...item
    }))

    return (
      <Spin spinning={false} indicator={antIcon}>
        <RangePicker
          format={'DD.MM.YYYY'}
          allowClear={false}
          value={[
            this.state.dateRange[0]
              ? moment(this.state.dateRange[0], 'YYYY-MM-DD')
              : null,
            this.state.dateRange[1]
              ? moment(this.state.dateRange[1], 'YYYY-MM-DD')
              : null
          ]}
          onChange={(value, dateString) => {
            this.setState({
              dateRange: [
                dateString[0]
                  ? moment(dateString[0], 'DD.MM.YYYY').format('YYYY-MM-DD')
                  : null,
                dateString[1]
                  ? moment(dateString[1], 'DD.MM.YYYY').format('YYYY-MM-DD')
                  : null
              ]
            })
          }}
        />{' '}
        <Button
          onClick={() => {
            this.props.getCalendar(
              this.state.dateRange[0],
              this.state.dateRange[1]
            )
          }}
        >
          Применить
        </Button>
        <p></p>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{ x: `${columns.length * 275}px`, y: '500px' }}
        />
      </Spin>
    )
  }
}

const mapStateToProps = store => {
  return {
    schedule: store.schedule
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getCalendar: (dateS, dateE) => dispatch(getCalendar(dateS, dateE))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarTab)
