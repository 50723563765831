import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Tabs } from 'antd'
import qs from 'qs'

import { history } from '../../../../redux/configureStore'

// Communication
import FileTab from './Estimates/FileTabComponent'
import InterviewTab from './Estimates/InterviewTabComponent'

// Physical theropy
import GoniomentriyaTab from './Estimates/GoniomentriyaTabComponent'
import MobilityTab from './Estimates/MobilityTabComponent'
import HareTab from './Estimates/HareTabComponent'

// Occupational therapy
import FoodTab from './Estimates/FoodTabComponent'

// Weight
import WeightTab from './Estimates/WeightTabComponent'

const { TabPane } = Tabs

class EstimatesTab extends PureComponent {
  changeTab(key) {
    history.push({
      search: qs.stringify({ tab: 'estimates', section: key })
    })
  }

  changeSubTab(section, key) {
    history.push({
      search: qs.stringify({ tab: 'estimates', section, subsection: key })
    })
  }

  render() {
    let sectionTabsDefaultActiveKey = 'communication'
    const getVars = qs.parse(document.location.search.substr(1))
    if (getVars.section) {
      sectionTabsDefaultActiveKey = getVars.section
    }

    let subSectionTabsDefaultActiveKey = '1'
    if (getVars.subsection) {
      subSectionTabsDefaultActiveKey = getVars.subsection
    }

    return (
      <Tabs
        defaultActiveKey={sectionTabsDefaultActiveKey}
        type="card"
        onChange={key => this.changeTab(key)}
      >
        <TabPane tab="Коммуникация" key="communication">
          <Tabs
            defaultActiveKey={subSectionTabsDefaultActiveKey}
            destroyInactiveTabPane
            tabPosition="left"
            onChange={key => this.changeSubTab('communication', key)}
          >
            <TabPane tab="Матрица коммуникации" key="1">
              <FileTab
                type="communication"
                clientId={this.props.clientId}
                fileType="image"
                fileName="Файл"
                allowTypes={['jpg', 'pdf']}
                internalComment={true}
                siteComment={true}
              />
            </TabPane>
            <TabPane tab="Родительское интервью" key="2">
              <InterviewTab clientId={this.props.clientId} />
            </TabPane>
            <TabPane tab="Оценка взаимодействия" key="3">
              <FileTab
                type="interaction"
                clientId={this.props.clientId}
                fileType="file"
                fileName="Документ"
                allowTypes={['doc', 'docx']}
                internalComment={true}
                siteComment={true}
              />
            </TabPane>
            <TabPane tab="Векслер" key="4">
              <FileTab
                type="veksler"
                clientId={this.props.clientId}
                fileType="image"
                fileName="Изображение"
                allowTypes={['jpg']}
                internalComment={true}
                siteComment={false}
              />
            </TabPane>
            <TabPane tab="Дорожная карта" key="5">
              <FileTab
                type="roadmap"
                clientId={this.props.clientId}
                fileType="image"
                fileName="Изображение"
                allowTypes={['jpg']}
                internalComment={true}
                siteComment={false}
              />
            </TabPane>
            <TabPane tab="Оценка участия" key="6">
              <FileTab
                type="participation"
                clientId={this.props.clientId}
                fileType="image"
                fileName="Изображение"
                allowTypes={['jpg']}
                internalComment={true}
                siteComment={false}
              />
            </TabPane>
          </Tabs>
        </TabPane>
        <TabPane tab="Физическая терапия" key="ft">
          <Tabs
            defaultActiveKey={subSectionTabsDefaultActiveKey}
            destroyInactiveTabPane
            tabPosition="left"
            onChange={key => this.changeSubTab('ft', key)}
          >
            <TabPane tab="Гониометрия" key="1">
              <GoniomentriyaTab clientId={this.props.clientId} />
            </TabPane>
            <TabPane tab="Мобильность" key="2">
              <MobilityTab clientId={this.props.clientId} />
            </TabPane>
            <TabPane tab="Hare scale" key="3">
              <HareTab clientId={this.props.clientId} />
            </TabPane>
          </Tabs>
        </TabPane>
        <TabPane tab="Эрготерапия" key="et">
          <Tabs
            defaultActiveKey={subSectionTabsDefaultActiveKey}
            destroyInactiveTabPane
            tabPosition="left"
            onChange={key => this.changeSubTab('et', key)}
          >
            <TabPane tab="Прием пищи и жидкости" key="1">
              <FoodTab clientId={this.props.clientId} />
            </TabPane>
          </Tabs>
        </TabPane>
        <TabPane tab="Физические показатели" key="weight">
          <WeightTab clientId={this.props.clientId} />
        </TabPane>
      </Tabs>
    )
  }
}

export default connect()(EstimatesTab)
