import produce from 'immer'
import moment from 'moment'
// import uuid from 'uuid'

import {
  LIST_LOAD_REQUEST,
  LIST_LOAD_SUCCESS,
  DELETE_ITEM_REQUEST,
  CHANGE_FORM_VIEW,
  SET_FIELD,
  CREATE_ITEM_REQUEST,
  CREATE_ITEM_SUCCESS,
  OPEN_EDIT_FROM,
  UPDATE_ITEM_REQUEST,
  UPDATE_ITEM_SUCCESS,
  SET_INTERVIEW_ANSWER,
  SET_FOOD_ANSWER,
  UPDATE_TABLE_ITEM_REQUEST,
  UPDATE_TABLE_ITEM_SUCCESS,
  CREATE_TABLE_ITEM_REQUEST,
  CREATE_TABLE_ITEM_SUCCESS,
  TEST_CATEGORIES_LOAD_REQUEST,
  TEST_CATEGORIES_LOAD_SUCCESS,
  SET_MOBILITY_TEST_VALUE,
  REMOVE_MOBILITY_TEST,
  SET_WEIGHT_VALUE
} from '../actions/ClientEstimatesActions'

const initialState = {
  file: {
    isLoading: false,
    animation: false,
    type: '',
    items: []
  },
  fileForm: {
    isSending: false,
    isOpen: false,

    id: null,
    date: moment().format('YYYY-MM-DD'),
    internal_comment: '',
    site_comment: '',
    file_id: 0,
    file: {
      f_id: null,
      f_name: '',
      f_hash: ''
    }
  },
  interview: {
    isLoading: false,
    items: []
  },
  interviewForm: {
    isSending: false,
    isOpen: false,

    id: null,
    date: moment().format('YYYY-MM-DD'),
    sections: [],
    comment: ''
  },
  goniometriya: {
    items: [],
    requestParams: {},
    isLoading: false,
    editableIds: [],
    editableItems: {}
  },
  mobility: {
    testCategories: [],
    isLoadingTestCategories: false,

    items: [],
    requestParams: {},
    isLoading: false,
    editableIds: [],
    editableItems: {}
  },
  harescale: {
    items: [],
    requestParams: {},
    isLoading: false,
    editableIds: [],
    editableItems: {}
  },
  weight: {
    items: [],
    requestParams: {},
    isLoading: false,
    editableIds: [],
    editableItems: {}
  },
  food: {
    isLoading: false,
    items: []
  },
  foodForm: {
    isSending: false,
    isOpen: false,

    id: null,
    date: moment().format('YYYY-MM-DD'),
    tabs: []
  }
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_WEIGHT_VALUE:
      return produce(state, draftState => {
        draftState.weight.editableItems[action.payload.id][
          action.payload.field
        ] = action.payload.value
      })
    case REMOVE_MOBILITY_TEST:
      return produce(state, draftState => {
        Object.keys(draftState.mobility.editableItems).forEach(id => {
          draftState.mobility.editableItems[
            id
          ].estimates = draftState.mobility.editableItems[id].estimates.filter(
            estimate => estimate.test_id !== action.payload.testId
          )
        })
      })
    case SET_MOBILITY_TEST_VALUE:
      return produce(state, draftState => {
        let isFoundTest = false
        draftState.mobility.editableItems[action.payload.id].estimates.forEach(
          (estimate, index) => {
            if (estimate.test_id === action.payload.testId) {
              isFoundTest = true
              estimate[action.payload.field] = action.payload.value
            }
          }
        )
        if (!isFoundTest) {
          let estimateObj = {
            test_id: action.payload.testId,
            result: '',
            comment: '',
            emoji: ''
          }
          draftState.mobility.editableItems[action.payload.id].estimates.push({
            ...estimateObj,
            [action.payload.field]: action.payload.value
          })
        }
      })
    case TEST_CATEGORIES_LOAD_SUCCESS:
      return produce(state, draftState => {
        draftState.mobility.isLoading = false
        draftState.mobility.testCategories = action.payload.data
      })
    case TEST_CATEGORIES_LOAD_REQUEST:
      return produce(state, draftState => {
        draftState.mobility.isLoading = true
      })
    case CREATE_TABLE_ITEM_SUCCESS:
      return produce(state, draftState => {
        draftState[action.meta.previousAction.payload.type].isLoading = false
        draftState[action.meta.previousAction.payload.type].items.unshift(
          action.payload.data
        )
        draftState[action.meta.previousAction.payload.type].editableIds.push(
          action.payload.data.id
        )
        draftState[action.meta.previousAction.payload.type].editableItems[
          action.payload.data.id
        ] = action.payload.data
      })
    case CREATE_TABLE_ITEM_REQUEST:
      return produce(state, draftState => {
        draftState[action.payload.type].isLoading = true
      })
    case UPDATE_TABLE_ITEM_SUCCESS:
      return produce(state, draftState => {
        draftState[action.meta.previousAction.payload.type].isLoading = false

        let fileIndex = draftState[
          action.meta.previousAction.payload.type
        ].items.findIndex(item => item.id === action.payload.data.id)
        draftState[action.meta.previousAction.payload.type].items[fileIndex] =
          action.payload.data
      })
    case UPDATE_TABLE_ITEM_REQUEST:
      return produce(state, draftState => {
        draftState[action.payload.type].isLoading = true
      })
    case SET_INTERVIEW_ANSWER:
      return produce(state, draftState => {
        let { sectionIndex, questionIndex, answer } = action.payload
        draftState.interviewForm.sections[sectionIndex].questions[
          questionIndex
        ].answer = answer
      })
    case SET_FOOD_ANSWER:
      return produce(state, draftState => {
        let { tabIndex, questionIndex, answer } = action.payload
        draftState.foodForm.tabs[tabIndex].questions[
          questionIndex
        ].answer = answer
      })
    case UPDATE_ITEM_SUCCESS:
      return produce(state, draftState => {
        draftState[
          `${action.meta.previousAction.payload.mode}Form`
        ].isSending = true
        draftState[
          `${action.meta.previousAction.payload.mode}Form`
        ].isOpen = false

        let fileIndex = draftState[
          action.meta.previousAction.payload.mode
        ].items.findIndex(item => item.id === action.payload.data.id)
        draftState[action.meta.previousAction.payload.mode].items[fileIndex] =
          action.payload.data
      })
    case UPDATE_ITEM_REQUEST:
      return produce(state, draftState => {
        draftState[`${action.payload.mode}Form`].isSending = true
      })
    case OPEN_EDIT_FROM:
      return produce(state, draftState => {
        draftState[action.payload.type] = {
          ...action.payload.item,
          isOpen: true,
          isSending: false
        }

        if (action.payload.copy) {
          draftState[action.payload.type].id = null
        }
      })
    case CREATE_ITEM_SUCCESS:
      return produce(state, draftState => {
        draftState[
          `${action.meta.previousAction.payload.mode}Form`
        ].isSending = false
        draftState[
          `${action.meta.previousAction.payload.mode}Form`
        ].isOpen = false
        draftState[action.meta.previousAction.payload.mode].items.unshift(
          action.payload.data
        )
      })
    case CREATE_ITEM_REQUEST:
      return produce(state, draftState => {
        draftState[action.payload.mode].animation = true
        draftState[`${action.payload.mode}Form`].isSending = true
      })
    case SET_FIELD:
      return produce(state, draftState => {
        draftState[action.payload.type][action.payload.field] =
          action.payload.value
      })
    case CHANGE_FORM_VIEW:
      return produce(state, draftState => {
        draftState[action.payload.type] = {
          ...initialState[action.payload.type],
          isOpen: action.payload.isOpen
        }
      })
    case DELETE_ITEM_REQUEST:
      return produce(state, draftState => {
        draftState[action.payload.mode].animation = true
        for (let i = 0; i < draftState[action.payload.mode].items.length; i++) {
          if (
            draftState[action.payload.mode].items[i].id === action.payload.id
          ) {
            draftState[action.payload.mode].items.splice(i, 1)
            break
          }
        }
      })
    case LIST_LOAD_REQUEST:
      return produce(state, draftState => {
        draftState[action.payload.mode].isLoading = true
        draftState[action.payload.mode].animation = false
        draftState[action.payload.mode].requestParams =
          action.payload.request.params
      })
    case LIST_LOAD_SUCCESS:
      return produce(state, draftState => {
        draftState[action.meta.previousAction.payload.mode].isLoading = false
        draftState[action.meta.previousAction.payload.mode].items =
          action.payload.data
      })
    default:
      return state
  }
}
