import React, { PureComponent } from 'react'
import {
  Icon,
  Button,
  Spin,
  Drawer,
  Modal,
  Select,
  DatePicker,
  notification
} from 'antd'
import { Helmet } from 'react-helmet'
import { EditOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import moment from 'moment'

import AnimatedTable from '../../../components/AnimatedTableComponent'

import {
  getRatesList,
  openRateForm,
  openEditRateForm,
  closeRateForm,
  syncRates
} from '../../../redux/actions/RatesActions'

import RateForm from './Components/RateFormComponent'

const { Option } = Select

class Rates extends PureComponent {
  state = {
    isOpenModal: false,
    date: moment().format('YYYY-MM-DD'),
    rateIds: []
  }

  componentDidMount() {
    this.cancelSyncModal = this.cancelSyncModal.bind(this)

    this.props.getRatesList(1, this.props.rates.sort)
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = e => {
    if (this.props.rates.isLoading || this.props.rates.isTotalReached) {
      return
    }

    const body = document.body,
      html = document.documentElement

    const wholePageHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    )

    const scrollTop = window.scrollY
    const windowHeight = window.innerHeight

    if (scrollTop > wholePageHeight - windowHeight * 1.05) {
      const pageNumber = this.props.rates.pageNumber + 1
      this.props.getRatesList(pageNumber, this.props.rates.sort)
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    let sort = null
    if (Object.keys(sorter).length > 0) {
      sort = {
        field: sorter.field,
        type: sorter.order === 'ascend' ? 'asc' : 'desc'
      }
    }
    this.props.getRatesList(1, sort)
  }

  cancelSyncModal() {
    this.setState({
      isOpenModal: false,
      rateIds: [],
      date: moment().format('YYYY-MM-DD')
    })
  }

  render() {
    const { rates } = this.props

    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />

    let tableStyle = null
    if (rates.isLoading && rates.pageNumber === 1) {
      tableStyle = { display: 'none' }
    }

    let columns = [
      {
        title: 'Номер',
        dataIndex: 'id',
        width: 125,
        sorter: true,
        className: 'Clients__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            const rate = rates.data.find(item => item.id === record.id)
            this.props.openEditRateForm(rate)
          }
        })
      },
      {
        title: 'Название',
        dataIndex: 'name',
        sorter: true,
        className: 'Clients__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            const rate = rates.data.find(item => item.id === record.id)
            this.props.openEditRateForm(rate)
          }
        })
      },
      {
        title: '',
        key: 'action',
        width: 50,
        render: (text, record) => (
          <span className="Clients__control-buttons">
            <a
              href="/"
              onClick={e => {
                e.preventDefault()
                const rate = rates.data.find(item => item.id === record.id)
                this.props.openEditRateForm(rate)
              }}
            >
              <EditOutlined />
            </a>
          </span>
        )
      }
    ]

    const data = rates.data.map(item => ({
      key: item.id,
      id: item.id,
      name: item.name
    }))

    return (
      <div>
        <Helmet>
          <title>Тарифы</title>
        </Helmet>

        <h1>
          Тарифы
          <Button
            type="primary"
            className="Clients__add-button"
            onClick={this.props.openRateForm}
          >
            Создать
          </Button>
          <Button
            type="default"
            className="Clients__add-button"
            onClick={() => this.setState({ isOpenModal: true })}
          >
            Синхронизировать
          </Button>
          <Modal
            title="Параметры синхронизации"
            visible={this.state.isOpenModal}
            onOk={() =>
              this.props
                .syncRates(this.state.date, this.state.rateIds)
                .then(action => {
                  notification.success({
                    message: 'Тарифы синхронизированы',
                    description: `Обновлено услуг: ${action.payload.data}`
                  })

                  this.cancelSyncModal()
                })
            }
            onCancel={this.cancelSyncModal}
            okText="Применить"
            okButtonProps={{
              loading: rates.syncRates.isLoading,
              disabled: this.state.rateIds.length === 0
            }}
          >
            <div className="Client__modal-div">
              <div className="Client__modal-div-title">
                Дата оказания услуг с
              </div>
              <DatePicker
                placeholder="Выберите"
                format={'DD.MM.YYYY'}
                value={moment(this.state.date, 'YYYY-MM-DD')}
                allowClear={false}
                onChange={(date, dateString) => {
                  this.setState({ date: date.format('YYYY-MM-DD') })
                }}
              />
            </div>

            <div className="Client__modal-div">
              <div className="Client__modal-div-title">Тарифы</div>
              <Select
                style={{ width: '100%' }}
                mode="multiple"
                placeholder="Выберите"
                value={this.state.rateIds}
                onChange={value => this.setState({ rateIds: value })}
              >
                {rates.data.map(item => (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </div>
          </Modal>
        </h1>

        <AnimatedTable
          columns={columns}
          dataSource={data}
          pagination={false}
          onChange={this.handleTableChange}
          style={tableStyle}
          animation={rates.animation}
        />

        {rates.isLoading && (
          <div className="Clients__loader">
            <Spin indicator={antIcon} />
          </div>
        )}

        <Drawer
          title={!this.props.rates.rateForm.id ? 'Создание' : 'Редактирование'}
          width={'700'}
          onClose={this.props.closeRateForm}
          visible={this.props.rates.rateForm.isOpen}
        >
          <RateForm />
        </Drawer>
      </div>
    )
  }
}

const mapStateToProps = store => {
  return {
    rates: store.rates
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getRatesList: (pageNumber = 1, sort = null) =>
      dispatch(getRatesList(pageNumber, sort)),
    openRateForm: () => dispatch(openRateForm()),
    closeRateForm: () => dispatch(closeRateForm()),
    openEditRateForm: item => dispatch(openEditRateForm(item)),
    syncRates: (date, rateIds) => dispatch(syncRates(date, rateIds))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Rates)
