import produce from 'immer'
import uuid from 'uuid'

import {
  ADD_TIMESPAN,
  DELETE_TIMESPAN,
  EDIT_TIMESPAN,
  GET_TIMESPANS_REQUEST,
  GET_TIMESPANS_SUCCESS,
  UPDATE_TIMESPANS_REQUEST,
  UPDATE_TIMESPANS_SUCCESS,
  GET_CALENDAR_REQUEST,
  GET_CALENDAR_SUCCESS
} from '../actions/ScheduleActions'

const initialState = {
  calendar: {
    isLoading: false,
    dates: [],
    specialists: []
  },
  mySchedule: {
    isLoading: false,
    items: []
  }
}

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_CALENDAR_SUCCESS:
      return produce(state, draftState => {
        draftState.calendar.isLoading = false
        draftState.calendar.dates = action.payload.data.dates
        draftState.calendar.specialists = action.payload.data.specialists
      })
    case GET_CALENDAR_REQUEST:
      return produce(state, draftState => {
        draftState.calendar.isLoading = true
      })
    case UPDATE_TIMESPANS_SUCCESS:
      return produce(state, draftState => {
        draftState.mySchedule.isLoading = false
        draftState.mySchedule.items = action.payload.data
      })
    case UPDATE_TIMESPANS_REQUEST:
      return produce(state, draftState => {
        draftState.mySchedule.isLoading = true
      })
    case GET_TIMESPANS_SUCCESS:
      return produce(state, draftState => {
        draftState.mySchedule.isLoading = false
        draftState.mySchedule.items = action.payload.data
      })
    case GET_TIMESPANS_REQUEST:
      return produce(state, draftState => {
        draftState.mySchedule.isLoading = true
      })
    case EDIT_TIMESPAN:
      return produce(state, draftState => {
        draftState.mySchedule.items.some((item, index) => {
          if (item.date === action.payload.date) {
            let timespanIndex = item.timespans.findIndex(
              timespan => timespan.id === action.payload.id
            )
            draftState.mySchedule.items[index].timespans[timespanIndex][
              action.payload.field
            ] = action.payload.value
            return true
          }
          return false
        })
      })
    case DELETE_TIMESPAN:
      return produce(state, draftState => {
        draftState.mySchedule.items.some((item, index) => {
          if (item.date === action.payload.date) {
            let timespanIndex = item.timespans.findIndex(
              timespan => timespan.id === action.payload.id
            )
            draftState.mySchedule.items[index].timespans.splice(
              timespanIndex,
              1
            )
            return true
          }
          return false
        })
      })
    case ADD_TIMESPAN:
      return produce(state, draftState => {
        draftState.mySchedule.items.some((item, index) => {
          if (item.date === action.payload.date) {
            draftState.mySchedule.items[index].timespans.push({
              time_s: undefined,
              time_e: undefined,
              type: 0,
              id: uuid()
            })
            return true
          }
          return false
        })
      })
    default:
      return state
  }
}
