import produce from 'immer'
import moment from 'moment'

import {
  OPEN_EMPLOYMENT_FORM,
  OPEN_EDIT_EMPLOYMENT_FROM,
  CLOSE_EMPLOYMENT_FORM,
  SET_FIELD_FOR_EMPLOYMENT,
  EMPLOYMENT_LOAD_REQUEST,
  EMPLOYMENT_LOAD_SUCCESS,
  APPLY_EMPLOYMENT_FILTERS,
  RESET_EMPLOYMENT_FILTERS,
  CREATE_EMPLOYMENT_REQUEST,
  CREATE_EMPLOYMENT_SUCCESS,
  UPDATE_EMPLOYMENT_REQUEST,
  UPDATE_EMPLOYMENT_SUCCESS,
  DELETE_EMPLOYMENT_REQUEST
} from '../actions/EmploymentActions'

const initialState = {
  isLoadingItems: false,
  items: [],
  animation: true,
  pageNumber: 1,
  isTotalReached: false,
  sort: {
    field: 'date',
    type: 'desc'
  },
  filters: {
    date_from: null,
    date_to: null,
    categories: [],
    user_ids: [],
    service_types: [],
    task_types: [],
    methodical_work_types: []
  },
  counter: {
    minutes: 0,
    items: 0
  },

  employmentForm: {
    isOpen: false,
    isSending: false,

    id: null,
    date: moment().format('YYYY-MM-DD'),
    length: null,
    category: 'task',
    type: null,
    comment: null
  }
}

export default function(state = initialState, action) {
  switch (action.type) {
    case APPLY_EMPLOYMENT_FILTERS:
      return produce(state, draftState => {
        draftState.filters = action.payload.filters
      })
    case RESET_EMPLOYMENT_FILTERS:
      return produce(state, draftState => {
        draftState.filters = initialState.filters
      })
    case EMPLOYMENT_LOAD_REQUEST:
      return produce(state, draftState => {
        draftState.isLoadingItems = true
        draftState.isTotalReached = false

        const pageNumber = action.payload.request.params['page']
        if (pageNumber === 1) {
          draftState.items = []
        }
        draftState.pageNumber = pageNumber

        if (action.payload.request.params['sort']) {
          draftState.sort.field = action.payload.request.params['sort']
          draftState.sort.type = action.payload.request.params['sort_type']
        } else {
          draftState.sort = initialState.sort
        }
      })
    case EMPLOYMENT_LOAD_SUCCESS:
      return produce(state, draftState => {
        draftState.isLoadingItems = false
        draftState.animation = false

        if (draftState.pageNumber === 1) {
          draftState.items = action.payload.data.items
        } else {
          draftState.isTotalReached = action.payload.data.items.length === 0

          draftState.items = Array.from(
            new Map(
              [...draftState.items, ...action.payload.data.items].map(obj => [
                obj.id,
                obj
              ])
            ).values()
          )
        }

        draftState.counter = action.payload.data.total
      })
    case OPEN_EDIT_EMPLOYMENT_FROM:
      return produce(state, draftState => {
        draftState.employmentForm = {
          ...action.payload.item,
          id: !action.payload.copy ? action.payload.item.id : null,

          isOpen: true,
          isSending: false
        }
      })
    case CLOSE_EMPLOYMENT_FORM:
      return produce(state, draftState => {
        draftState.employmentForm.isOpen = false
      })
    case OPEN_EMPLOYMENT_FORM:
      return produce(state, draftState => {
        draftState.employmentForm = {
          ...initialState.employmentForm,
          isOpen: true
        }
      })
    case SET_FIELD_FOR_EMPLOYMENT:
      return produce(state, draftState => {
        draftState.employmentForm[action.payload.field] = action.payload.value
      })
    case CREATE_EMPLOYMENT_SUCCESS:
      return produce(state, draftState => {
        draftState.employmentForm.isSending = false
        draftState.employmentForm.isOpen = false
        draftState.items.unshift(action.payload.data)

        draftState.counter.minutes += action.payload.data.length
        draftState.counter.items++
      })
    case CREATE_EMPLOYMENT_REQUEST:
      return produce(state, draftState => {
        draftState.employmentForm.isSending = true
        draftState.animation = true
      })
    case UPDATE_EMPLOYMENT_SUCCESS:
      return produce(state, draftState => {
        draftState.employmentForm.isSending = false
        draftState.employmentForm.isOpen = false

        let dataIndex = draftState.items.findIndex(
          item => item.id === action.payload.data.id
        )

        draftState.counter.minutes -= draftState.items[dataIndex].length
        draftState.counter.minutes += action.payload.data.length

        draftState.items[dataIndex] = action.payload.data
      })
    case UPDATE_EMPLOYMENT_REQUEST:
      return produce(state, draftState => {
        draftState.employmentForm.isSending = true
      })
    case DELETE_EMPLOYMENT_REQUEST:
      return produce(state, draftState => {
        draftState.animation = true

        for (let i = 0; i < draftState.items.length; i++) {
          if (draftState.items[i].id === action.payload.id) {
            draftState.counter.minutes -= draftState.items[i].length
            draftState.counter.items--

            draftState.items.splice(i, 1)
            break
          }
        }
      })
    default:
      return state
  }
}
