export const LIST_LOAD_REQUEST = 'CLIENT_ESTIMATES/LIST_LOAD_REQUEST'
export const LIST_LOAD_SUCCESS = 'CLIENT_ESTIMATES/LIST_LOAD_SUCCESS'

export function getList(
  id,
  type = 'communication',
  mode = 'file',
  params = {}
) {
  return dispatch => {
    dispatch({
      types: [LIST_LOAD_REQUEST, LIST_LOAD_SUCCESS],
      payload: {
        mode,
        request: {
          method: 'GET',
          url: `/${type}/get-list/${id}`,
          params
        }
      }
    })
  }
}

export const DELETE_ITEM_REQUEST = 'CLIENT_ESTIMATES/DELETE_ITEM_REQUEST'
export const DELETE_ITEM_SUCCESS = 'CLIENT_ESTIMATES/DELETE_ITEM_SUCCESS'

export function deleteItem(id, type = 'communication', mode = 'file') {
  return dispatch =>
    dispatch({
      types: [DELETE_ITEM_REQUEST, DELETE_ITEM_SUCCESS],
      payload: {
        id,
        mode,
        request: {
          method: 'POST',
          url: `/${type}/delete/${id}`
        }
      }
    })
}

export const CHANGE_FORM_VIEW = 'CLIENT_ESTIMATES/CHANGE_FORM_VIEW'

export function changeFormView(type = 'fileForm', isOpen = true) {
  return dispatch => {
    dispatch({
      type: CHANGE_FORM_VIEW,
      payload: {
        type,
        isOpen
      }
    })
  }
}

export const SET_FIELD = 'CLIENT_ESTIMATES/SET_FIELD'

export function setField(field, value, type = 'fileForm') {
  return dispatch => {
    dispatch({
      type: SET_FIELD,
      payload: {
        field,
        value,
        type
      }
    })
  }
}

export const CREATE_ITEM_REQUEST = 'CLIENT_ESTIMATES/CREATE_ITEM_REQUEST'
export const CREATE_ITEM_SUCCESS = 'CLIENT_ESTIMATES/CREATE_ITEM_SUCCESS'

export function createItem(
  clientId,
  type = 'communication',
  mode = 'file',
  formObj
) {
  return dispatch => {
    dispatch({
      types: [CREATE_ITEM_REQUEST, CREATE_ITEM_SUCCESS],
      payload: {
        mode,
        request: {
          method: 'POST',
          url: `/${type}/create`,
          data: {
            client_id: clientId,
            ...formObj
          }
        }
      }
    })
  }
}

export const OPEN_EDIT_FROM = 'CLIENT_ESTIMATES/OPEN_EDIT_FROM'

export function openEditForm(item, type = 'fileForm', copy = false) {
  return dispatch => {
    dispatch({
      type: OPEN_EDIT_FROM,
      payload: {
        item,
        type,
        copy
      }
    })
  }
}

export const UPDATE_ITEM_REQUEST = 'CLIENT_ESTIMATES/UPDATE_ITEM_REQUEST'
export const UPDATE_ITEM_SUCCESS = 'CLIENT_ESTIMATES/UPDATE_ITEM_SUCCESS'

export function updateItem(id, type = 'communication', mode = 'file', formObj) {
  return dispatch =>
    dispatch({
      types: [UPDATE_ITEM_REQUEST, UPDATE_ITEM_SUCCESS],
      payload: {
        mode,
        request: {
          method: 'POST',
          url: `/${type}/update/${id}`,
          data: {
            ...formObj
          }
        }
      }
    })
}

export const SET_INTERVIEW_ANSWER = 'CLIENT_ESTIMATES/SET_INTERVIEW_ANSWER'

export function setInterviewAnswer(sectionIndex, questionIndex, answer) {
  return dispatch => {
    dispatch({
      type: SET_INTERVIEW_ANSWER,
      payload: {
        sectionIndex,
        questionIndex,
        answer
      }
    })
  }
}

export const SET_FOOD_ANSWER = 'CLIENT_ESTIMATES/SET_FOOD_ANSWER'

export function setFoodAnswer(tabIndex, questionIndex, answer) {
  return dispatch => {
    dispatch({
      type: SET_FOOD_ANSWER,
      payload: {
        tabIndex,
        questionIndex,
        answer
      }
    })
  }
}

export const UPDATE_TABLE_ITEM_REQUEST =
  'CLIENT_ESTIMATES/UPDATE_TABLE_ITEM_REQUEST'
export const UPDATE_TABLE_ITEM_SUCCESS =
  'CLIENT_ESTIMATES/UPDATE_TABLE_ITEM_SUCCESS'

export function updateTableItem(id, type = 'goniometriya', formObj) {
  return dispatch =>
    dispatch({
      types: [UPDATE_TABLE_ITEM_REQUEST, UPDATE_TABLE_ITEM_SUCCESS],
      payload: {
        type,
        request: {
          method: 'POST',
          url: `/${type}/update/${id}`,
          data: {
            ...formObj
          }
        }
      }
    })
}

export const CREATE_TABLE_ITEM_REQUEST =
  'CLIENT_ESTIMATES/CREATE_TABLE_ITEM_REQUEST'
export const CREATE_TABLE_ITEM_SUCCESS =
  'CLIENT_ESTIMATES/CREATE_TABLE_ITEM_SUCCESS'

export function createTableItem(clientId, type = 'goniometriya', formObj) {
  return dispatch => {
    dispatch({
      types: [CREATE_TABLE_ITEM_REQUEST, CREATE_TABLE_ITEM_SUCCESS],
      payload: {
        type,
        request: {
          method: 'POST',
          url: `/${type}/create`,
          data: {
            client_id: clientId,
            ...formObj
          }
        }
      }
    })
  }
}

export const TEST_CATEGORIES_LOAD_REQUEST =
  'CLIENT_ESTIMATES/TEST_CATEGORIES_LOAD_REQUEST'
export const TEST_CATEGORIES_LOAD_SUCCESS =
  'CLIENT_ESTIMATES/TEST_CATEGORIES_LOAD_SUCCESS'

export function getTestCategories() {
  return dispatch => {
    dispatch({
      types: [TEST_CATEGORIES_LOAD_REQUEST, TEST_CATEGORIES_LOAD_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: `/mobility/get-test-categories`
        }
      }
    })
  }
}

export const SET_MOBILITY_TEST_VALUE =
  'CLIENT_ESTIMATES/SET_MOBILITY_TEST_VALUE'

export function setMobilityTestValue(id, testId, field, value) {
  return dispatch => {
    dispatch({
      type: SET_MOBILITY_TEST_VALUE,
      payload: {
        id,
        testId,
        field,
        value
      }
    })
  }
}

export const REMOVE_MOBILITY_TEST = 'CLIENT_ESTIMATES/REMOVE_MOBILITY_TEST'

export function removeMobilityTest(testId) {
  return dispatch => {
    dispatch({
      type: REMOVE_MOBILITY_TEST,
      payload: {
        testId
      }
    })
  }
}

export const SET_WEIGHT_VALUE = 'CLIENT_ESTIMATES/SET_WEIGHT_VALUE'

export function setWeightValue(id, field, value) {
  return dispatch => {
    dispatch({
      type: SET_WEIGHT_VALUE,
      payload: {
        id,
        field,
        value
      }
    })
  }
}
