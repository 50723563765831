export const CLIENTS_LOAD_REQUEST = 'CLIENTS/CLIENTS_LOAD_REQUEST'
export const CLIENTS_LOAD_SUCCESS = 'CLIENTS/CLIENTS_LOAD_SUCCESS'

export function getClientsList(pageNumber = 1, sort = null, filter = null) {
  return dispatch => {
    let additionalParams = {}

    if (sort) {
      additionalParams.sort = sort.field
      additionalParams.sort_type = sort.type
    }

    if (filter) {
      additionalParams.status = filter.status
      additionalParams.search = filter.search
    }

    dispatch({
      types: [CLIENTS_LOAD_REQUEST, CLIENTS_LOAD_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: '/clients/get-clients-list',
          params: {
            page: pageNumber,
            ...additionalParams
          }
        }
      }
    })
  }
}

export const COUNTER_LOAD_REQUEST = 'CLIENTS/COUNTER_LOAD_REQUEST'
export const COUNTER_LOAD_SUCCESS = 'CLIENTS/COUNTER_LOAD_SUCCESS'

export function getCounter() {
  return dispatch => {
    dispatch({
      types: [COUNTER_LOAD_REQUEST, COUNTER_LOAD_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: '/clients/get-counter'
        }
      }
    })
  }
}
