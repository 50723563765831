import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Form, Button, Icon, DatePicker, Upload, Spin, message } from 'antd'
import moment from 'moment'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import {
  setField,
  createItem,
  updateItem
} from '../../../../../redux/actions/ClientEstimatesActions'

import checkFileType from '../../../../../utils/checkFileType'

class FileForm extends PureComponent {
  handleSubmit = e => {
    e.preventDefault()

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { fileForm } = this.props.clientEstimates
        const { type, clientId } = this.props
        let formObj = {}

        Object.keys(fileForm).forEach(item => {
          if (
            fileForm[item] !== null &&
            item !== 'id' &&
            item !== 'isOpen' &&
            item !== 'isSending'
          ) {
            formObj[item] = fileForm[item]
          }
        })

        if (!fileForm.id) {
          // create
          this.props.createItem(clientId, type, formObj)
        } else {
          // update
          this.props.updateItem(fileForm.id, type, formObj)
        }
      }
    })
  }

  render() {
    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />
    const { getFieldDecorator } = this.props.form

    const {
      fileName,
      internalComment,
      siteComment,
      clientEstimates
    } = this.props
    const { fileForm } = clientEstimates

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 }
      }
    }

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 20,
          offset: 6
        }
      }
    }

    let fileList = []
    if (
      clientEstimates.fileForm.file.f_id ||
      clientEstimates.fileForm.file.f_uid
    ) {
      fileList.push({
        name: clientEstimates.fileForm.file.f_name,
        uid: clientEstimates.fileForm.file.f_uid
          ? clientEstimates.fileForm.file.f_uid
          : clientEstimates.fileForm.file.f_id
      })
    }

    const self = this
    const props = {
      name: 'file',
      action: `${process.env.REACT_APP_API_HOST}/file/add`,
      headers: {
        authorization: 'Bearer ' + this.props.user.attributes.token
      },
      beforeUpload: file => checkFileType(file, this.props.allowTypes),
      fileList,
      onChange: info => {
        if (info.fileList.length === 0) {
          self.props.setField('file_id', 0)
          self.props.setField('file', {
            f_id: null,
            f_name: '',
            f_hash: ''
          })
          return
        }

        if (info.file.status === 'done') {
          self.props.setField('file', {
            status: 'done',
            f_id: info.file.response.data.id,
            f_name: info.file.response.data.name,
            f_hash: info.file.response.data.hash
          })
          self.props.setField('file_id', info.file.response.data.id)
        } else {
          self.props.setField('file', {
            f_uid: info.fileList[0].uid,
            f_name: info.fileList[0].name,
            ...info.fileList[0]
          })

          if (info.file.error) {
            if (info.file.error.status === 415) {
              message.error(`${info.file.name} неверный формат файла`)
            } else if (info.file.error.status === 413) {
              message.error(`${info.file.name} превышен размер файла`)
            } else {
              message.error(
                `${info.file.name} загрузка прервана. Код ошибки ${info.file.error.status}`
              )
            }
          }
        }
      }
    }

    return (
      <Spin spinning={fileForm.isSending} indicator={antIcon}>
        <Form
          {...formItemLayout}
          onSubmit={this.handleSubmit}
          autoComplete="off"
        >
          <Form.Item label="Дата">
            {getFieldDecorator('date', {
              rules: [{ required: true, message: 'Выберите дату' }],
              initialValue: fileForm.date
                ? moment(fileForm.date, 'YYYY-MM-DD')
                : null
            })(
              <DatePicker
                format={'DD.MM.YYYY'}
                placeholder="Выберите дату"
                onChange={(date, dateString) =>
                  this.props.setField(
                    'date',
                    moment(dateString, 'DD.MM.YYYY').format('YYYY-MM-DD')
                  )
                }
              />
            )}
          </Form.Item>

          <Form.Item label={fileName}>
            <Upload {...props}>
              <Button disabled={fileList.length > 0}>
                <Icon type="upload" /> Загрузить
              </Button>
            </Upload>
          </Form.Item>

          {internalComment && (
            <Form.Item label="Специалистам">
              <CKEditor
                editor={ClassicEditor}
                data={fileForm.internal_comment}
                config={{
                  toolbar: [
                    'heading',
                    'bold',
                    'italic',
                    'link',
                    'blockQuote',
                    'bulletedList',
                    'numberedList'
                  ],
                  heading: {
                    options: [
                      {
                        model: 'paragraph',
                        title: 'Параграф',
                        class: 'ck-heading_paragraph'
                      },
                      {
                        model: 'heading2',
                        view: 'h2',
                        title: 'Заголовок',
                        class: 'ck-heading_heading2'
                      }
                    ]
                  }
                }}
                onChange={(event, editor) => {
                  const data = editor.getData()
                  this.props.setField('internal_comment', data)
                }}
              />
            </Form.Item>
          )}

          {siteComment && (
            <Form.Item
              label="Комментарий (сайт)"
              extra="ВНИМАНИЕ! Комментарий виден клиенту"
            >
              <CKEditor
                editor={ClassicEditor}
                data={fileForm.site_comment}
                config={{
                  toolbar: [
                    'heading',
                    'bold',
                    'italic',
                    'link',
                    'blockQuote',
                    'bulletedList',
                    'numberedList'
                  ],
                  heading: {
                    options: [
                      {
                        model: 'paragraph',
                        title: 'Параграф',
                        class: 'ck-heading_paragraph'
                      },
                      {
                        model: 'heading2',
                        view: 'h2',
                        title: 'Заголовок',
                        class: 'ck-heading_heading2'
                      }
                    ]
                  }
                }}
                onChange={(event, editor) => {
                  const data = editor.getData()
                  this.props.setField('site_comment', data)
                }}
              />
            </Form.Item>
          )}

          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>

          {fileForm.id && (
            <Form.Item {...tailFormItemLayout}>
              <p>
                Создана: {fileForm.user_creating.user_creating_name}{' '}
                {fileForm.user_creating.user_creating_surname},{' '}
                {moment(fileForm.date_creating).format('DD.MM.YYYY HH:mm')}
                <br />
                Последнее редактирование:{' '}
                {fileForm.user_last_editing.user_last_editing_name}{' '}
                {fileForm.user_last_editing.user_last_editing_surname},{' '}
                {moment(fileForm.date_last_editing).format('DD.MM.YYYY HH:mm')}
              </p>
            </Form.Item>
          )}
        </Form>
      </Spin>
    )
  }
}

const mapStateToProps = store => {
  return {
    clientEstimates: store.clientEstimates,
    user: store.user
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    setField: (field, value) => dispatch(setField(field, value, 'fileForm')),
    createItem: (clientId, type, formObj) =>
      dispatch(createItem(clientId, type, 'file', formObj)),
    updateItem: (id, type, formObj) =>
      dispatch(updateItem(id, type, 'file', formObj))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'FileForm' })(FileForm))
