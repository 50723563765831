export const SET_DATE_RANGE = 'UNLOADING/SET_DATE_RANGE'

export function setDateRange(type, dateString, mode = 'clients') {
  return dispatch => {
    dispatch({
      type: SET_DATE_RANGE,
      payload: {
        type,
        date_from: dateString[0],
        date_to: dateString[1],
        mode
      }
    })
  }
}

export const SET_FIELD_FOR_UNLOADING = 'UNLOADING/SET_FIELD_FOR_UNLOADING'

export function setFieldForUnloading(field, value, mode = 'clients') {
  return dispatch => {
    dispatch({
      type: SET_FIELD_FOR_UNLOADING,
      payload: {
        field,
        value,
        mode
      }
    })
  }
}

export const RESET_FILTERS = 'UNLOADING/RESET_FILTERS'

export function resetFilters(mode = 'clients') {
  return dispatch => {
    dispatch({
      type: RESET_FILTERS,
      payload: {
        mode
      }
    })
  }
}
