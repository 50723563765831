export const RATES_LOAD_REQUEST = 'RATES/RATES_LOAD_REQUEST'
export const RATES_LOAD_SUCCESS = 'RATES/RATES_LOAD_SUCCESS'

export function getRatesList(pageNumber = 1, sort = null, filter = null) {
  return dispatch => {
    let additionalParams = {}

    if (sort) {
      additionalParams.sort = sort.field
      additionalParams.sort_type = sort.type
    }

    dispatch({
      types: [RATES_LOAD_REQUEST, RATES_LOAD_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: '/rate/get-rates',
          params: {
            page: pageNumber,
            ...additionalParams
          }
        }
      }
    })
  }
}

export const OPEN_RATE_FORM = 'RATES/OPEN_RATE_FORM'

export function openRateForm() {
  return dispatch => {
    dispatch({
      type: OPEN_RATE_FORM
    })
  }
}

export const OPEN_EDIT_RATE_FROM = 'RATES/OPEN_EDIT_RATE_FROM'

export function openEditRateForm(item) {
  return dispatch => {
    dispatch({
      type: OPEN_EDIT_RATE_FROM,
      payload: {
        item
      }
    })
  }
}

export const CLOSE_RATE_FORM = 'RATES/CLOSE_RATE_FORM'

export function closeRateForm() {
  return dispatch => {
    dispatch({
      type: CLOSE_RATE_FORM
    })
  }
}

export const SET_FIELD_FOR_RATE = 'RATES/SET_FIELD_FOR_RATE'

export function setFieldForRate(field, value) {
  return dispatch => {
    dispatch({
      type: SET_FIELD_FOR_RATE,
      payload: {
        field,
        value
      }
    })
  }
}

export const CREATE_RATE_REQUEST = 'RATES/CREATE_RATE_REQUEST'
export const CREATE_RATE_SUCCESS = 'RATES/CREATE_RATE_SUCCESS'

export function createRate(formObj) {
  return dispatch => {
    dispatch({
      types: [CREATE_RATE_REQUEST, CREATE_RATE_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/rate/create`,
          data: {
            ...formObj
          }
        }
      }
    })
  }
}

export const UPDATE_RATE_REQUEST = 'RATES/UPDATE_RATE_REQUEST'
export const UPDATE_RATE_SUCCESS = 'RATES/UPDATE_RATE_SUCCESS'

export function updateRate(id, formObj) {
  return dispatch => {
    dispatch({
      types: [UPDATE_RATE_REQUEST, UPDATE_RATE_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/rate/update/${id}`,
          data: {
            ...formObj
          }
        }
      }
    })
  }
}

export const SET_PRICE_FOR_RATE = 'RATES/SET_PRICE_FOR_RATE'

export function setPriceForRate(type, specialist_type, price) {
  return dispatch => {
    dispatch({
      type: SET_PRICE_FOR_RATE,
      payload: {
        type,
        specialist_type,
        price
      }
    })
  }
}

export const SYNC_RATES_REQUEST = 'RATES/SYNC_RATES_REQUEST'
export const SYNC_RATES_SUCCESS = 'RATES/SYNC_RATES_SUCCESS'

export function syncRates(date, rateIds) {
  return dispatch =>
    dispatch({
      types: [SYNC_RATES_REQUEST, SYNC_RATES_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/service/sync-rates`,
          data: {
            date,
            rate_ids: rateIds
          }
        }
      }
    })
}
