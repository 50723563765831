import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Icon,
  Drawer,
  Menu,
  Dropdown,
  Popconfirm,
  Divider,
  Spin
} from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'

import {
  getList,
  deleteItem,
  changeFormView,
  openEditForm
} from '../../../../../redux/actions/ClientEstimatesActions'

import InterviewForm from './InterviewFormComponent'

class InterviewTab extends PureComponent {
  state = {
    currentDataIndex: 0
  }

  componentDidMount() {
    const { clientId } = this.props
    this.props.getList(clientId)
  }

  render() {
    const { clientEstimates } = this.props

    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />

    const menuItems = clientEstimates.interview.items.map((item, index) => (
      <Menu.Item key={item.id}>
        <a
          href="/"
          onClick={e => {
            e.preventDefault()
            this.setState({ currentDataIndex: index })
          }}
        >
          {this.state.currentDataIndex === index ? (
            <b>{item.date}</b>
          ) : (
            <span>{item.date}</span>
          )}
        </a>
      </Menu.Item>
    ))
    const menu = <Menu>{menuItems}</Menu>

    return (
      <Spin spinning={clientEstimates.interview.isLoading} indicator={antIcon}>
        <Button type="primary" onClick={() => this.props.changeFormView(true)}>
          Создать
        </Button>
        <p></p>

        {clientEstimates.interview.items.length > 0 && (
          <div>
            <Dropdown overlay={menu} className="Interview__dropdown">
              <a
                href="/"
                className="ant-dropdown-link"
                onClick={e => e.preventDefault()}
              >
                {
                  clientEstimates.interview.items[this.state.currentDataIndex]
                    .date
                }{' '}
                <Icon type="down" />
              </a>
            </Dropdown>
            <span className="Clients__control-buttons">
              <a
                href="/"
                onClick={e => {
                  e.preventDefault()
                  this.props.openEditForm(
                    clientEstimates.interview.items[this.state.currentDataIndex]
                  )
                }}
              >
                <EditOutlined />
              </a>
              <Divider type="vertical" />
              <Popconfirm
                title="Вы уверены, что хотите удалить запись?"
                onConfirm={e => {
                  this.props.deleteItem(
                    clientEstimates.interview.items[this.state.currentDataIndex]
                      .id
                  )
                  this.setState({ currentDataIndex: 0 })
                }}
                okText="Да"
                cancelText="Нет"
              >
                <a href="/">
                  <DeleteOutlined />
                </a>
              </Popconfirm>
            </span>
            {clientEstimates.interview.items[
              this.state.currentDataIndex
            ].sections.map(section => (
              <div key={section.uuid}>
                <h3 className="Interview__header">{section.name}</h3>
                {section.questions.map((question, questionIndex) => (
                  <div className="Interview__answer-block" key={question.uuid}>
                    <div className="Interview__question">
                      <b>
                        {questionIndex + 1}. {question.question}
                      </b>
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: question.answer
                          ? question.answer.replace(/(?:\r\n|\r|\n)/g, '<br>')
                          : '–'
                      }}
                    ></div>
                  </div>
                ))}
              </div>
            ))}
            {clientEstimates.interview.items[this.state.currentDataIndex]
              .comment !== '' && (
              <div>
                <h3 className="Interview__header">Комментарий</h3>
                <div
                  className="Interview__answer-block"
                  dangerouslySetInnerHTML={{
                    __html:
                      clientEstimates.interview.items[
                        this.state.currentDataIndex
                      ].comment
                  }}
                ></div>
              </div>
            )}
          </div>
        )}

        <Drawer
          title={
            clientEstimates.interviewForm.id ? 'Редактирование' : 'Создание'
          }
          width={'700'}
          onClose={() => this.props.changeFormView(false)}
          visible={clientEstimates.interviewForm.isOpen}
        >
          {clientEstimates.interviewForm.isOpen && (
            <InterviewForm clientId={this.props.clientId} />
          )}
        </Drawer>
      </Spin>
    )
  }
}

const mapStateToProps = store => {
  return {
    clientEstimates: store.clientEstimates
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    getList: id => dispatch(getList(id, 'interview', 'interview')),
    deleteItem: id => dispatch(deleteItem(id, 'interview', 'interview')),
    changeFormView: (isOpen = true) =>
      dispatch(changeFormView('interviewForm', isOpen)),
    openEditForm: item => dispatch(openEditForm(item, 'interviewForm'))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InterviewTab)
