import produce from 'immer'
import moment from 'moment'
import uuid from 'uuid'

import {
  OPEN_CLIENT_FORM,
  CLOSE_CLIENT_FORM,
  SET_FIELD_FOR_CLIENT,
  CREATE_CLIENT_REQUEST,
  CREATE_CLIENT_SUCCESS,
  OPEN_EDIT_CLIENT_FROM,
  UPDATE_CLIENT_REQUEST,
  UPDATE_CLIENT_SUCCESS,
  DATA_LOAD_REQUEST,
  DATA_LOAD_SUCCESS,
  GENERAL_DATA_LOAD_REQUEST,
  GENERAL_DATA_LOAD_SUCCESS,
  UPDATE_GENERAL_DATA_REQUEST,
  UPDATE_GENERAL_DATA_SUCCESS,
  MOVE_TO_ARCHIVE,
  PROJECTS_LIST_SUCCESS,
  SPONSORS_LIST_SUCCESS,
  CLIENTS_LIST_SUCCESS,
  SITE_USERS_LIST_SUCCESS,
  DIAGNOSES_LIST_SUCCESS,
  USERS_LIST_SUCCESS,
  COPM_TABLES_LOAD_REQUEST,
  COPM_TABLES_LOAD_SUCCESS,
  CREATE_COPM_TABLE_REQUEST,
  CREATE_COPM_TABLE_SUCCESS,
  ADD_COPM_ITEM,
  CANCEL_COPM_ITEM,
  EDIT_COPM_ITEM,
  UPDADE_COPM_ITEM_FIELD,
  CREATE_COPM_ITEM_REQUEST,
  CREATE_COPM_ITEM_SUCCESS,
  UPDATE_COPM_ITEM_REQUEST,
  UPDATE_COPM_ITEM_SUCCESS,
  DELETE_COPM_ITEM_REQUEST,
  DELETE_COPM_ITEM_SUCCESS,
  DATA_LIST_LOAD_REQUEST,
  DATA_LIST_LOAD_SUCCESS,
  CREATE_DATA_REQUEST,
  CREATE_DATA_SUCCESS,
  PROBLEMS_LIST_LOAD_SUCCESS,
  LAST_COPM_ITEMS_LOAD_SUCCESS,
  UPDATE_DATA_REQUEST,
  UPDATE_DATA_SUCCESS,
  DELETE_DATA_REQUEST,
  SERVICES_LOAD_REQUEST,
  SERVICES_LOAD_SUCCESS,
  CREATE_SERVICE_REQUEST,
  CREATE_SERVICE_SUCCESS,
  UPDATE_SERVICE_REQUEST,
  UPDATE_SERVICE_SUCCESS,
  DELETE_SERVICE_REQUEST,
  WAY_LOAD_REQUEST,
  WAY_LOAD_SUCCESS,
  ANKETA_LOAD_REQUEST,
  ANKETA_LOAD_SUCCESS,
  DELETE_ANKETA_REQUEST,
  DELETE_ANKETA_SUCCESS,
  CREATE_ANKETA_REQUEST,
  CREATE_ANKETA_SUCCESS,
  UPDATE_SPECIALISTS,
  SET_TYPE_FOR_SPECIALIST,
  GET_SERVICE_REQUEST,
  GET_SERVICE_SUCCESS,
  SERVICES_SPECIALISTS_LOAD_SUCCESS
} from '../actions/ClientActions'

const initialState = {
  clientForm: {
    isOpen: false,
    isSending: false,

    id: null,
    name: '',
    surname: '',
    date_of_birth: '',
    parent_one_name: '',
    parent_one_surname: '',
    parent_one_patronymic: '',
    parent_one_phone: '',
    parent_one_email: '',
    parent_two_name: '',
    parent_two_surname: '',
    parent_two_patronymic: '',
    parent_two_phone: '',
    parent_two_email: '',
    social_taxi_address: '',
    email: '',
    phone: '',
    social_url: '',
    post_index: '',
    region: '',
    city: '',
    street: '',
    home_number: '',
    home_corps: '',
    home_letter: '',
    home_structure: '',
    home_flat_number: '',
    difference_in_time: '',
    is_archive: 0,
    archive_cause: ''
  },
  clientData: {
    isLoading: false,

    id: null,
    name: '',
    surname: '',
    date_of_birth: '',
    parent_one_name: '',
    parent_one_surname: '',
    parent_one_patronymic: '',
    parent_one_phone: '',
    parent_one_email: '',
    parent_two_name: '',
    parent_two_surname: '',
    parent_two_patronymic: '',
    parent_two_phone: '',
    parent_two_email: '',
    social_taxi_address: '',
    email: '',
    phone: '',
    social_url: '',
    post_index: '',
    region: '',
    city: '',
    street: '',
    home_number: '',
    home_corps: '',
    home_letter: '',
    home_structure: '',
    home_flat_number: '',
    difference_in_time: '',
    is_archive: 0,
    archive_cause: ''
  },
  commonClientForm: {
    isSending: false,
    isSuccess: false,

    diagnosis_ids: [],
    gmfcs: null,
    macs: null,
    cfcs: null,
    edacs: null,
    edacs_type: null,
    arfid: null,
    vfcs: null,
    project_id: null,
    project_start_date: '',
    projects_archive: [],
    sponsor_id: null,
    sponsor_start_date: '',
    sponsors_archive: [],
    site_user_id: null,
    comment: ''
  },
  copm: {
    isLoading: false,
    date: moment().format('YYYY-MM-DD'),
    author: 0,
    newItems: 1,
    tables: [],
    tmpItems: {}
  },
  data: {
    isLoading: false,
    animation: false,
    mode: '',
    items: [],
    problemsList: [],
    lastCopmItems: []
  },
  dataForm: {
    isSending: false,
    isOpen: false,

    id: null,
    text: '',
    specialist_type: null,
    date: moment().format('YYYY-MM-DD'),
    status: 0,
    date_of_achievement: null,
    problem_id: null, // for target only
    mcf_icf: '', // for target only
    comment: '' // for target only
  },
  service: {
    isLoading: false,
    animation: false,
    items: [],
    total: 0,
    services_specialists: []
  },
  way: {
    isLoading: false,
    items: [],
    total: 0
  },
  serviceForm: {
    isSending: false,
    isOpen: false,

    id: null,
    date: moment().format('YYYY-MM-DD HH:mm'),
    status: 0,
    type: null,
    is_primary: 0,
    length: 60,
    conclusion: '',
    comment: '',
    reason_for_absence: 0,
    specialistIds: [],
    specialists: []
  },
  anketa: {
    isLoading: false,
    items: []
  },
  anketaForm: {
    isLoading: false,
    isOpen: false,
    date: moment().format('YYYY-MM-DD'),
    questions: []
  },
  commonData: {
    projects_list: [],
    sponsors_list: [],
    clients_list: [],
    diagnoses_list: [],
    users_list: [],
    site_users_list: []
  }
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_TYPE_FOR_SPECIALIST:
      return produce(state, draftState => {
        draftState[action.payload.mode].specialists.forEach((item, index) => {
          if (item.id === action.payload.id) {
            draftState[action.payload.mode].specialists[index].type =
              action.payload.value
          }
        })
      })
    case UPDATE_SPECIALISTS:
      return produce(state, draftState => {
        let newList = []
        let currentIds = []

        draftState[action.payload.mode].specialists.forEach(item => {
          if (action.payload.value.includes(item.id)) {
            newList.push(item)
          }
          currentIds.push(item.id)
        })

        action.payload.value.forEach(id => {
          if (!currentIds.includes(id)) {
            newList.push({
              id: id,
              type: null
            })
          }
        })

        draftState[action.payload.mode].specialists = newList
      })
    case CREATE_ANKETA_REQUEST:
      return produce(state, draftState => {
        draftState.anketaForm.isLoading = true
      })
    case CREATE_ANKETA_SUCCESS:
      return produce(state, draftState => {
        draftState.anketaForm.isLoading = false
        draftState.anketaForm.isOpen = false
        draftState.anketa.items.unshift(action.payload.data)
      })
    case DELETE_ANKETA_REQUEST:
      return produce(state, draftState => {
        draftState.anketaForm.isLoading = true
      })
    case DELETE_ANKETA_SUCCESS:
      return produce(state, draftState => {
        draftState.anketaForm.isLoading = false
        draftState.anketaForm.questions = []
      })
    case ANKETA_LOAD_REQUEST:
      return produce(state, draftState => {
        draftState.anketa.isLoading = true
      })
    case ANKETA_LOAD_SUCCESS:
      return produce(state, draftState => {
        draftState.anketa.isLoading = false
        draftState.anketa.items = action.payload.data
      })
    case WAY_LOAD_REQUEST:
      return produce(state, draftState => {
        draftState.way.isLoading = true
      })
    case WAY_LOAD_SUCCESS:
      return produce(state, draftState => {
        draftState.way.isLoading = false
        draftState.way.items = action.payload.data.items
        draftState.way.total = action.payload.data.total
      })
    case DELETE_SERVICE_REQUEST:
      return produce(state, draftState => {
        draftState.service.animation = true
        for (let i = 0; i < draftState.service.items.length; i++) {
          if (draftState.service.items[i].id === action.payload.id) {
            draftState.service.items.splice(i, 1)
            break
          }
        }
      })
    case UPDATE_SERVICE_SUCCESS:
      return produce(state, draftState => {
        draftState.serviceForm.isSending = true
        draftState.serviceForm.isOpen = false

        let dateIndex = draftState.service.items.findIndex(
          item => item.id === action.payload.data.id
        )
        draftState.service.items[dateIndex] = action.payload.data
      })
    case UPDATE_SERVICE_REQUEST:
      return produce(state, draftState => {
        draftState.serviceForm.isSending = true
      })
    case CREATE_SERVICE_SUCCESS:
      return produce(state, draftState => {
        draftState.serviceForm.isSending = false
        draftState.serviceForm.isOpen = false
        draftState.service.items.unshift(action.payload.data)
      })
    case CREATE_SERVICE_REQUEST:
      return produce(state, draftState => {
        draftState.service.animation = true
        draftState.serviceForm.isSending = true
      })
    case GET_SERVICE_SUCCESS:
      return produce(state, draftState => {
        draftState.serviceForm = {
          ...action.payload.data,
          isOpen: true,
          isSending: false
        }

        draftState.serviceForm.specialistIds = action.payload.data.specialists.map(
          item => item.id
        )
      })
    case GET_SERVICE_REQUEST:
      return produce(state, draftState => {
        draftState.serviceForm.isSending = true
        draftState.serviceForm.isOpen = true
      })
    case SERVICES_LOAD_REQUEST:
      return produce(state, draftState => {
        draftState.service.isLoading = true
        draftState.service.animation = false
      })
    case SERVICES_LOAD_SUCCESS:
      return produce(state, draftState => {
        draftState.service.isLoading = false
        draftState.service.items = action.payload.data.items
        draftState.service.total = action.payload.data.total
      })
    case SERVICES_SPECIALISTS_LOAD_SUCCESS:
      return produce(state, draftState => {
        draftState.service.services_specialists = action.payload.data
      })
    case DELETE_DATA_REQUEST:
      return produce(state, draftState => {
        draftState.data.animation = true
        for (let i = 0; i < draftState.data.items.length; i++) {
          if (draftState.data.items[i].id === action.payload.id) {
            draftState.data.items.splice(i, 1)
            break
          }
        }
      })
    case UPDATE_DATA_SUCCESS:
      return produce(state, draftState => {
        draftState.dataForm.isSending = true
        draftState.dataForm.isOpen = false

        let dateIndex = draftState.data.items.findIndex(
          item => item.id === action.payload.data.id
        )
        draftState.data.items[dateIndex] = action.payload.data
      })
    case UPDATE_DATA_REQUEST:
      return produce(state, draftState => {
        draftState.dataForm.isSending = true
      })
    case PROBLEMS_LIST_LOAD_SUCCESS:
      return produce(state, draftState => {
        draftState.data.problemsList = action.payload.data
      })
    case LAST_COPM_ITEMS_LOAD_SUCCESS:
      return produce(state, draftState => {
        draftState.data.lastCopmItems = action.payload.data
      })
    case CREATE_DATA_SUCCESS:
      return produce(state, draftState => {
        draftState.dataForm.isSending = false
        draftState.dataForm.isOpen = false
        draftState.data.items.unshift(action.payload.data)
      })
    case CREATE_DATA_REQUEST:
      return produce(state, draftState => {
        draftState.data.animation = true
        draftState.dataForm.isSending = true
      })
    case DATA_LIST_LOAD_REQUEST:
      return produce(state, draftState => {
        draftState.data.isLoading = true
        draftState.data.animation = false
        draftState.data.mode = action.payload.mode
      })
    case DATA_LIST_LOAD_SUCCESS:
      return produce(state, draftState => {
        draftState.data.isLoading = false
        draftState.data.items = action.payload.data
      })
    case DELETE_COPM_ITEM_REQUEST:
      return produce(state, draftState => {
        draftState.copm.isLoading = true
        let table = draftState.copm.tables.find(
          item => item.id === action.payload.copmId
        )
        for (let i = 0; i < table.items.length; i++) {
          if (table.items[i].id === action.payload.id) {
            table.items.splice(i, 1)
            break
          }
        }
      })
    case DELETE_COPM_ITEM_SUCCESS:
      return produce(state, draftState => {
        draftState.copm.isLoading = false
      })
    case UPDATE_COPM_ITEM_SUCCESS:
      return produce(state, draftState => {
        draftState.copm.isLoading = false
      })
    case UPDATE_COPM_ITEM_REQUEST:
      return produce(state, draftState => {
        draftState.copm.isLoading = true

        const copmId = action.payload.request.data.copm_id
        const id = action.payload.request.data.id

        let table = draftState.copm.tables.find(item => item.id === copmId)

        for (let i = 0; i < table.items.length; i++) {
          if (table.items[i].id === id) {
            table.items[i] = draftState.copm.tmpItems[id]
            table.items[i].editable = false
            delete draftState.copm.tmpItems[id]

            break
          }
        }
      })
    case CREATE_COPM_ITEM_SUCCESS:
      return produce(state, draftState => {
        draftState.copm.isLoading = false

        let table = draftState.copm.tables.find(
          item => item.id === action.payload.data.copm_id
        )

        table.items.unshift(action.payload.data)
      })
    case CREATE_COPM_ITEM_REQUEST:
      return produce(state, draftState => {
        draftState.copm.isLoading = true

        const copmId = action.payload.request.data.copm_id
        const uuid = action.payload.request.data.uuid

        let table = draftState.copm.tables.find(item => item.id === copmId)

        for (let i = 0; i < table.items.length; i++) {
          if (table.items[i].uuid === uuid) {
            table.items.splice(i, 1)
            delete draftState.copm.tmpItems[uuid]

            break
          }
        }
      })
    case UPDADE_COPM_ITEM_FIELD:
      return produce(state, draftState => {
        draftState.copm.tmpItems[action.payload.tmpCopmItemId][
          action.payload.field
        ] = action.payload.value
      })
    case EDIT_COPM_ITEM:
      return produce(state, draftState => {
        let table = draftState.copm.tables.find(
          item => item.id === action.payload.copmId
        )
        for (let i = 0; i < table.items.length; i++) {
          if (action.payload.id && table.items[i].id === action.payload.id) {
            table.items[i].editable = true
            draftState.copm.tmpItems[table.items[i].id] = table.items[i]
          }
        }
      })
    case CANCEL_COPM_ITEM:
      return produce(state, draftState => {
        let table = draftState.copm.tables.find(
          item => item.id === action.payload.copmId
        )
        for (let i = 0; i < table.items.length; i++) {
          if (
            !action.payload.id &&
            action.payload.uuid &&
            table.items[i].uuid === action.payload.uuid
          ) {
            table.items.splice(i, 1)
            delete draftState.copm.tmpItems[action.payload.uuid]
            break
          }

          if (action.payload.id && table.items[i].id === action.payload.id) {
            table.items[i].editable = false
            break
          }
        }
      })
    case ADD_COPM_ITEM:
      return produce(state, draftState => {
        for (let i = 0; i < draftState.copm.tables.length; i++) {
          if (draftState.copm.tables[i].id === action.payload.copmId) {
            let uuidVar = uuid()
            draftState.copm.tables[i].items.unshift({
              id: null,
              uuid: uuidVar,
              name: '',
              mcf_icf: '',
              fulfillment: 0,
              satisfaction: 0,
              fulfillment_two: 0,
              satisfaction_two: 0,
              editable: true
            })
            draftState.copm.tmpItems[uuidVar] =
              draftState.copm.tables[i].items[0]
            break
          }
        }
      })
    case CREATE_COPM_TABLE_REQUEST:
      return produce(state, draftState => {
        draftState.copm.isLoading = true
      })
    case CREATE_COPM_TABLE_SUCCESS:
      return produce(state, draftState => {
        draftState.copm.isLoading = false
        let data = action.payload.data

        if (draftState.copm.newItems > 0) {
          while (draftState.copm.newItems > 0) {
            let uuidVar = uuid()
            data.items.unshift({
              id: null,
              uuid: uuidVar,
              name: '',
              mcf_icf: '',
              fulfillment: 0,
              satisfaction: 0,
              fulfillment_two: 0,
              satisfaction_two: 0,
              editable: true
            })
            draftState.copm.tmpItems[uuidVar] = data.items[0]
            draftState.copm.newItems--
          }

          draftState.copm.newItems = 1
        }

        draftState.copm.tables.unshift(data)
      })
    case COPM_TABLES_LOAD_REQUEST:
      return produce(state, draftState => {
        draftState.copm.isLoading = true
      })
    case COPM_TABLES_LOAD_SUCCESS:
      return produce(state, draftState => {
        draftState.copm.isLoading = false
        draftState.copm.tables = action.payload.data
      })
    case PROJECTS_LIST_SUCCESS:
      return produce(state, draftState => {
        draftState.commonData.projects_list = action.payload.data
      })
    case SPONSORS_LIST_SUCCESS:
      return produce(state, draftState => {
        draftState.commonData.sponsors_list = action.payload.data
      })
    case CLIENTS_LIST_SUCCESS:
      return produce(state, draftState => {
        draftState.commonData.clients_list = action.payload.data
      })
    case SITE_USERS_LIST_SUCCESS:
      return produce(state, draftState => {
        draftState.commonData.site_users_list = action.payload.data
      })
    case USERS_LIST_SUCCESS:
      return produce(state, draftState => {
        draftState.commonData.users_list = action.payload.data
      })
    case DIAGNOSES_LIST_SUCCESS:
      return produce(state, draftState => {
        draftState.commonData.diagnoses_list = action.payload.data
      })
    case UPDATE_GENERAL_DATA_SUCCESS:
      return produce(state, draftState => {
        draftState.commonClientForm = {
          isSending: false,
          isSuccess: true,
          ...action.payload.data
        }

        draftState.commonClientForm.project_id =
          action.payload.data.project_id === 0
            ? ''
            : action.payload.data.project_id

        draftState.commonClientForm.sponsor_id =
          action.payload.data.sponsor_id === 0
            ? ''
            : action.payload.data.sponsor_id

        draftState.commonClientForm.site_user_id =
          action.payload.data.site_user_id === 0
            ? ''
            : action.payload.data.site_user_id
      })
    case UPDATE_GENERAL_DATA_REQUEST:
      return produce(state, draftState => {
        draftState.commonClientForm.isSending = true
        draftState.commonClientForm.isSuccess = false
      })
    case MOVE_TO_ARCHIVE:
      return produce(state, draftState => {
        let type = action.payload.type
        draftState.commonClientForm[`${type}s_archive`].unshift({
          id: draftState.commonClientForm[`${type}_id`],
          name: action.payload.name,
          start_date: draftState.commonClientForm[`${type}_start_date`],
          end_date: action.payload.date
        })

        draftState.commonClientForm[`${type}_id`] = ''
        draftState.commonClientForm[`${type}_start_date`] = '1970-01-01'
      })
    case GENERAL_DATA_LOAD_SUCCESS:
      return produce(state, draftState => {
        draftState.commonClientForm = {
          isSending: false,
          isSuccess: false,
          ...action.payload.data
        }

        draftState.commonClientForm.project_id =
          action.payload.data.project_id === 0
            ? ''
            : action.payload.data.project_id

        draftState.commonClientForm.sponsor_id =
          action.payload.data.sponsor_id === 0
            ? ''
            : action.payload.data.sponsor_id

        draftState.commonClientForm.site_user_id =
          action.payload.data.site_user_id === 0
            ? ''
            : action.payload.data.site_user_id
      })
    case GENERAL_DATA_LOAD_REQUEST:
      return produce(state, draftState => {
        draftState.commonClientForm.isSending = true
        draftState.commonClientForm.isSuccess = false
      })
    case DATA_LOAD_REQUEST:
      return produce(state, draftState => {
        draftState.clientData.isLoading = true
      })
    case DATA_LOAD_SUCCESS:
      return produce(state, draftState => {
        draftState.clientData = {
          isLoading: false,
          ...action.payload.data
        }
      })
    case UPDATE_CLIENT_SUCCESS:
      return produce(state, draftState => {
        draftState.clientForm.isSending = false
        draftState.clientForm.isOpen = false

        draftState.clientData = {
          isLoading: false,
          ...action.payload.data
        }
      })
    case UPDATE_CLIENT_REQUEST:
      return produce(state, draftState => {
        draftState.clientForm.isSending = true
      })
    case OPEN_EDIT_CLIENT_FROM:
      return produce(state, draftState => {
        draftState[action.payload.mode] = {
          ...action.payload.item,
          isOpen: true,
          isSending: false
        }

        if (action.payload.copy) {
          draftState[action.payload.mode].id = null
        }

        if (action.payload.item.problem) {
          draftState[action.payload.mode].problem_id =
            action.payload.item.problem.problem_id
        }

        if (action.payload.item.specialist) {
          draftState[action.payload.mode].specialist_id =
            action.payload.item.specialist.specialist_id
        }

        if (action.payload.item.specialists) {
          draftState[
            action.payload.mode
          ].specialistIds = action.payload.item.specialists.map(item => item.id)
        }
      })
    case CREATE_CLIENT_SUCCESS:
      return produce(state, draftState => {
        draftState.clientForm.isSending = false
        draftState.clientForm.isOpen = false
      })
    case CREATE_CLIENT_REQUEST:
      return produce(state, draftState => {
        draftState.clientForm.isSending = true
      })
    case OPEN_CLIENT_FORM:
      return produce(state, draftState => {
        draftState[action.payload.mode] = {
          ...initialState[action.payload.mode],
          isOpen: true
        }
      })
    case CLOSE_CLIENT_FORM:
      return produce(state, draftState => {
        draftState[action.payload.mode].isOpen = false
      })
    case SET_FIELD_FOR_CLIENT:
      return produce(state, draftState => {
        draftState[action.payload.mode].isSuccess = false
        draftState[action.payload.mode][action.payload.field] =
          action.payload.value
      })
    default:
      return state
  }
}
