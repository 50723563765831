import React, { PureComponent } from 'react'
import { Router, Switch, Route, Link, Redirect } from 'react-router-dom'
import { history } from '../../redux/configureStore'
import { Layout, Menu, message } from 'antd'

import NotFound from '../Pages/NotFound/NotFoundComponent'
import Clients from '../Pages/Clients/ClientsComponent'
import Client from '../Pages/Client/ClientComponent'
import Unloading from '../Pages/Unloading/UnloadingComponent'
import Rates from '../Pages/Rates/RatesComponent'
import Settings from '../Pages/Settings/SettingsComponent'
import Schedule from '../Pages/Schedule/ScheduleComponent'
import Employment from '../Pages/Employment/EmploymentComponent'

import { connect } from 'react-redux'
import { logOutUser } from '../../redux/actions/UserActions'
import { getSettings } from '../../redux/actions/SettingsActions'

import '@fancyapps/ui/dist/fancybox.css'
require('@fancyapps/ui')

class MainLayout extends PureComponent {
  logOut(e) {
    e.preventDefault()
    this.props.logOutUser()
    document.location = '/login'
  }

  componentDidMount() {
    this.checkAuth()
    this.props.getSettings()
  }

  componentDidUpdate() {
    if (this.props.server.error.status) {
      if (this.props.server.error.status !== 404) {
        message.error(
          this.props.server.error.status + ': ' + this.props.server.error.text
        )
      } else {
        history.push(`/not-found`)
      }
    }

    this.checkAuth()
  }

  checkAuth() {
    if (!this.props.user.authorized) {
      document.location = '/login'
    }
  }

  render() {
    const { user } = this.props
    const pathname = document.location.pathname

    if (!user.authorized) {
      return null
    }

    const { Header, Content, Footer } = Layout
    const date = new Date()

    let selectKey = '1'
    switch (pathname) {
      case '/':
        selectKey = '1'
        break
      case '/timeline':
        selectKey = '2'
        break
      case '/rates':
        selectKey = '3'
        break
      case '/settings':
        selectKey = '4'
        break
      case '/schedule':
        selectKey = '5'
        break
      case '/employment':
        selectKey = '6'
        break
      default:
        selectKey = '0'
        break
    }

    return (
      <Layout>
        <Header className="Header">
          <div className="Header__userInfo">
            {user.attributes.name}
            <Link
              className="Header__exit-link"
              onClick={this.logOut.bind(this)}
              to=""
            >
              Выход
            </Link>
          </div>
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={[selectKey]}
            style={{ lineHeight: '64px' }}
          >
            <Menu.Item key="1">
              <Link to="/">Клиенты</Link>
            </Menu.Item>
            <Menu.Item key="5">
              <Link to="/schedule">Расписание</Link>
            </Menu.Item>
            <Menu.Item key="6">
              <Link to="/employment">Занятость</Link>
            </Menu.Item>
            <Menu.Item key="2">
              <Link to="/unloading">Выгрузки</Link>
            </Menu.Item>
            {user.type === 'admin' && (
              <Menu.Item key="3">
                <Link to="/rates">Тарифы</Link>
              </Menu.Item>
            )}
            {(user.type === 'admin' ||
              user.attributes.settings_access === 1) && (
              <Menu.Item key="4">
                <Link to="/settings">Настройки</Link>
              </Menu.Item>
            )}
          </Menu>
        </Header>
        <Content className="Content">
          <div className="Content__container">
            <Router history={history}>
              <Switch>
                <Redirect from="/login" to="/" />
                <Route exact path="/" component={Clients} />
                <Route exact path="/schedule" component={Schedule} />
                <Route exact path="/employment" component={Employment} />
                <Route exact path="/client/:id" component={Client} />
                <Route exact path="/unloading" component={Unloading} />

                {user.type === 'admin' && (
                  <Route exact path="/rates" component={Rates} />
                )}
                {(user.type === 'admin' ||
                  user.attributes.settings_access === 1) && (
                  <Route exact path="/settings" component={Settings} />
                )}

                <Route component={NotFound} />
              </Switch>
            </Router>
          </div>
        </Content>
        <Footer className="Footer">
          © {date.getFullYear()},{' '}
          <a
            href="https://physrehab.ru/"
            rel="noopener noreferrer"
            target="_blank"
          >
            АНО Физическая реабилитация
          </a>
        </Footer>
      </Layout>
    )
  }
}

const mapStateToProps = store => {
  return {
    user: store.user,
    server: store.server
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logOutUser: () => dispatch(logOutUser()),
    getSettings: () => dispatch(getSettings())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout)
