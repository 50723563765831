import React, { PureComponent } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { EditOutlined } from '@ant-design/icons'
import { Drawer, Icon, Tabs, Spin, Alert } from 'antd'
import qs from 'qs'

import { history } from '../../../redux/configureStore'

import {
  closeClientForm,
  openEditClientForm,
  getWay
} from '../../../redux/actions/ClientActions'
import { getData, getDataList } from '../../../redux/actions/ClientActions'

import ClientForm from '../Clients/Components/ClientFormComponent'
import ClientStatus from '../../../components/ClientStatusComponent'

import InfoTab from './Components/InfoTabComponent'
import CommonTab from './Components/CommonTabComponent'
import CopmTab from './Components/CopmTabComponent'
import EstimatesTab from './Components/EstimatesTabComponent'
import ServicesTab from './Components/ServicesTabComponent'
import DataTab from './Components/DataTabComponent'
import WayTab from './Components/WayTabComponent'

const { TabPane } = Tabs

class Client extends PureComponent {
  componentDidMount() {
    this.props.getData(this.props.match.params.id)
  }

  changeTab(key) {
    const id = this.props.match.params.id

    history.push({
      pathname: `/client/${id}`,
      search: qs.stringify({ tab: key })
    })
  }

  render() {
    const { client } = this.props
    const clientData = client.clientData
    const clientId = this.props.match.params.id

    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />

    let defaultActiveKey = 'info'
    const getVars = qs.parse(document.location.search.substr(1))
    if (getVars.tab) {
      defaultActiveKey = getVars.tab
    }

    const requiredFieldNames = {
      name: 'имя',
      surname: 'фамилия',
      date_of_birth: 'дата рождения',
      parent_one_surname: 'фамилия ЗН №1',
      parent_one_name: 'имя ЗН №1',
      parent_one_email: 'e-mail ЗН №1',
      parent_one_phone: 'телефон ЗН №1',
      country: 'страна',
      city: 'населенный пункт',
      street: 'улица',
      home_number: 'номер дома',
      home_corps: 'корпус',
      home_letter: 'литера',
      home_structure: 'строение',
      home_flat_number: 'квартира'
    }
    let requiredEmptyFields = []

    Object.keys(requiredFieldNames).forEach(key => {
      if (clientData[key] === '') {
        requiredEmptyFields.push(requiredFieldNames[key])
      }
    })

    return (
      <Spin spinning={clientData.isLoading} indicator={antIcon}>
        <Helmet>
          <title>{`Клиент №${clientData.id ? clientData.id : ''}`}</title>
        </Helmet>

        <h1>
          <ClientStatus client={clientData} /> Клиент №{clientData.id}{' '}
          <span className="Client__edit-link">
            <a
              href="/"
              onClick={e => {
                e.preventDefault()
                this.props.openEditClientForm(clientData)
              }}
            >
              <EditOutlined />
            </a>
          </span>
        </h1>

        {!clientData.isLoading && requiredEmptyFields.length > 0 && (
          <Alert
            message={
              <>
                Не заполнены обязательные поля: {requiredEmptyFields.join(', ')}
              </>
            }
            type="warning"
            showIcon
          />
        )}

        <Tabs
          defaultActiveKey={defaultActiveKey}
          onChange={this.changeTab.bind(this)}
          destroyInactiveTabPane
        >
          <TabPane tab="Информация" key="info">
            <InfoTab />
          </TabPane>
          <TabPane tab="Общие сведения" key="common">
            <CommonTab clientId={clientId} />
          </TabPane>
          <TabPane tab="COPM" key="copm">
            <CopmTab clientId={clientId} />
          </TabPane>
          <TabPane tab="Оценки" key="estimates">
            <EstimatesTab clientId={clientId} />
          </TabPane>
          <TabPane tab="Проблемы" key="problems">
            <DataTab mode="problem" clientId={clientId} />
          </TabPane>
          <TabPane tab="Цели" key="goals">
            <DataTab mode="target" clientId={clientId} />
          </TabPane>
          <TabPane tab="Услуги" key="services">
            <ServicesTab clientId={clientId} />
          </TabPane>
          <TabPane tab="Путь" key="way">
            <WayTab clientId={clientId} />
          </TabPane>
        </Tabs>

        <Drawer
          title={
            !this.props.client.clientForm.id ? 'Создание' : 'Редактирование'
          }
          width={'700'}
          onClose={this.props.closeClientForm}
          visible={this.props.client.clientForm.isOpen}
        >
          <ClientForm />
        </Drawer>
      </Spin>
    )
  }
}

const mapStateToProps = store => {
  return {
    client: store.client
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openEditClientForm: item => dispatch(openEditClientForm(item)),
    closeClientForm: () => dispatch(closeClientForm()),
    getData: id => dispatch(getData(id)),
    getDataList: (id, mode) => dispatch(getDataList(id, mode)),
    getWay: clientId => dispatch(getWay(clientId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Client)
