import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Table,
  Divider,
  Popconfirm,
  Input,
  InputNumber,
  Modal,
  DatePicker,
  Icon,
  Spin,
  Select
} from 'antd'
import moment from 'moment'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'

import {
  getCopmTalbes,
  createCopmTable,
  setFieldForClient,
  addCopmItem,
  cancelCopmItem,
  editCopmItem,
  updateCopmItemField,
  createCopmItem,
  updateCopmItem,
  deleteCopmItem
} from '../../../../redux/actions/ClientActions'

const { Option } = Select

class CopmTab extends PureComponent {
  state = {
    isOpenModal: false
  }

  constructor(props) {
    super(props)

    this.handleOk = this.handleOk.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.openClientForm = this.openClientForm.bind(this)
  }

  handleOk() {
    this.setState({ isOpenModal: false })
    this.props.createCopmTable(this.props.clientId, {
      date: this.props.client.copm.date,
      author: this.props.client.copm.author
    })
  }

  handleCancel() {
    this.setState({ isOpenModal: false })
  }

  openClientForm() {
    this.setState({ isOpenModal: true })
  }

  componentDidMount() {
    this.props.getCopmTalbes(this.props.clientId)
  }

  generateTableDataSource(items, copmId) {
    let counter = 0
    let totalFulfillment = 0
    let totalSatisfaction = 0
    let totalFulfillmentTwo = 0
    let totalSatisfactionTwo = 0
    let dataSource = []

    items.forEach(tableItem => {
      dataSource.push({
        key: tableItem.id ? tableItem.id : tableItem.uuid,
        uuid: tableItem.uuid ? tableItem.uuid : null,
        id: tableItem.id,
        copmId,
        name: tableItem.name,
        mcf_icf: tableItem.mcf_icf,
        fulfillment: tableItem.fulfillment,
        satisfaction: tableItem.satisfaction,
        fulfillment_two: tableItem.fulfillment_two,
        satisfaction_two: tableItem.satisfaction_two,
        editable: tableItem.editable ? tableItem.editable : false
      })
      counter++
      totalFulfillment += tableItem.fulfillment
      totalSatisfaction += tableItem.satisfaction
      totalFulfillmentTwo += tableItem.fulfillment_two
      totalSatisfactionTwo += tableItem.satisfaction_two
    })

    if (items.length > 0) {
      dataSource.push({
        key: `total-${copmId}`,
        editable: false,
        staticRaw: true,

        name: <b>Всего</b>,
        mcf_icf: <b>{counter}</b>,
        fulfillment: <b>{totalFulfillment}</b>,
        satisfaction: <b>{totalSatisfaction}</b>,
        fulfillment_two: <b>{totalFulfillmentTwo}</b>,
        satisfaction_two: <b>{totalSatisfactionTwo}</b>
      })

      dataSource.push({
        key: `total-score-${copmId}`,
        editable: false,
        staticRaw: true,

        name: <b>Общий балл</b>,
        mcf_icf: null,
        fulfillment: <b>{(totalFulfillment / counter).toFixed(1)}</b>,
        satisfaction: <b>{(totalSatisfaction / counter).toFixed(1)}</b>,
        fulfillment_two: <b>{(totalFulfillmentTwo / counter).toFixed(1)}</b>,
        satisfaction_two: <b>{(totalSatisfactionTwo / counter).toFixed(1)}</b>
      })
    }

    return dataSource
  }

  render() {
    const { copm } = this.props.client

    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />

    const columns = [
      {
        title: 'Название',
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => {
          return record.editable ? (
            <Input
              value={copm.tmpItems[record.id ? record.id : record.uuid].name}
              placeholder="Введите"
              onChange={e =>
                this.props.updateCopmItemField(
                  record.id ? record.id : record.uuid,
                  'name',
                  e.target.value
                )
              }
            />
          ) : (
            text
          )
        }
      },
      {
        title: 'МКФ',
        dataIndex: 'mcf_icf',
        key: 'mcf_icf',
        render: (text, record) => {
          return record.editable ? (
            <Input
              value={copm.tmpItems[record.id ? record.id : record.uuid].mcf_icf}
              placeholder="Введите"
              maxLength={7}
              style={{ width: '100px' }}
              onChange={e =>
                this.props.updateCopmItemField(
                  record.id ? record.id : record.uuid,
                  'mcf_icf',
                  e.target.value
                )
              }
            />
          ) : (
            text
          )
        }
      },
      {
        title: 'Выполнение',
        dataIndex: 'fulfillment',
        key: 'fulfillment',
        render: (text, record) => {
          return record.editable ? (
            <InputNumber
              value={
                copm.tmpItems[record.id ? record.id : record.uuid].fulfillment
              }
              onChange={value =>
                this.props.updateCopmItemField(
                  record.id ? record.id : record.uuid,
                  'fulfillment',
                  value
                )
              }
              min={0}
              placeholder="Введите"
            />
          ) : (
            text
          )
        }
      },
      {
        title: 'Удовлетворенность',
        dataIndex: 'satisfaction',
        key: 'satisfaction',
        render: (text, record) => {
          return record.editable ? (
            <InputNumber
              value={
                copm.tmpItems[record.id ? record.id : record.uuid].satisfaction
              }
              onChange={value =>
                this.props.updateCopmItemField(
                  record.id ? record.id : record.uuid,
                  'satisfaction',
                  value
                )
              }
              min={0}
              placeholder="Введите"
            />
          ) : (
            text
          )
        }
      },
      {
        title: 'Выполнение повт.',
        dataIndex: 'fulfillment_two',
        key: 'fulfillment_two',
        render: (text, record) => {
          return record.editable ? (
            <InputNumber
              value={
                copm.tmpItems[record.id ? record.id : record.uuid]
                  .fulfillment_two
              }
              placeholder="Введите"
              onChange={value =>
                this.props.updateCopmItemField(
                  record.id ? record.id : record.uuid,
                  'fulfillment_two',
                  value
                )
              }
              min={0}
            />
          ) : (
            text
          )
        }
      },
      {
        title: 'Удовлетворенность  повт.',
        dataIndex: 'satisfaction_two',
        key: 'satisfaction_two',
        render: (text, record) => {
          return record.editable ? (
            <InputNumber
              value={
                copm.tmpItems[record.id ? record.id : record.uuid]
                  .satisfaction_two
              }
              placeholder="Введите"
              onChange={value =>
                this.props.updateCopmItemField(
                  record.id ? record.id : record.uuid,
                  'satisfaction_two',
                  value
                )
              }
              min={0}
            />
          ) : (
            text
          )
        }
      },
      {
        title: '',
        key: 'action',
        width: 110,
        render: (text, record) => {
          if (record.staticRaw) return null
          return record.editable ? (
            <span>
              <a
                href="/"
                onClick={e => {
                  e.preventDefault()

                  if (record.id) {
                    // update
                    this.props.updateCopmItem(
                      record.id,
                      copm.tmpItems[record.id]
                    )
                  } else {
                    // create
                    this.props.createCopmItem(
                      record.copmId,
                      copm.tmpItems[record.uuid]
                    )
                  }
                  //this.props.openEditEventForm(record.type_name, record.id)
                }}
              >
                Сохранить
              </a>
              <a
                href="/"
                onClick={e => {
                  e.preventDefault()
                  this.props.cancelCopmItem(
                    record.copmId,
                    record.id ? record.id : null,
                    record.uuid ? record.uuid : null
                  )
                }}
              >
                Отмена
              </a>
            </span>
          ) : (
            <span className="Clients__control-buttons">
              <a
                href="/"
                onClick={e => {
                  e.preventDefault()
                  this.props.editCopmItem(record.copmId, record.id)
                }}
              >
                <EditOutlined />
              </a>
              <Divider type="vertical" />
              <Popconfirm
                title="Вы уверены, что хотите удалить запись?"
                onConfirm={e => {
                  this.props.deleteCopmItem(record.copmId, record.id)
                }}
                okText="Да"
                cancelText="Нет"
              >
                <a href="/">
                  <DeleteOutlined />
                </a>
              </Popconfirm>
            </span>
          )
        }
      }
    ]

    return (
      <Spin spinning={copm.isLoading} indicator={antIcon}>
        <Button type="primary" onClick={this.openClientForm}>
          Добавить таблицу
        </Button>
        <Modal
          title="Параметры оценки"
          visible={this.state.isOpenModal}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <div className="Client__modal-div">
            Дата
            <br />
            <DatePicker
              placeholder="Выберите дату"
              format={'DD.MM.YYYY'}
              value={copm.date ? moment(copm.date, 'YYYY-MM-DD') : null}
              allowClear={false}
              onChange={(date, dateString) => {
                this.props.setFieldForClient(
                  'date',
                  moment(dateString, 'DD.MM.YYYY').format('YYYY-MM-DD')
                )
              }}
            />
          </div>
          <div className="Client__modal-div">
            Автор:
            <br />
            <Select
              placeholder="Выберите"
              onChange={value => this.props.setFieldForClient('author', value)}
              value={copm.author}
              style={{ width: '200px' }}
            >
              <Option value={0}>Клиент</Option>
              <Option value={1}>Законный представитель</Option>
            </Select>
          </div>
          <div className="Client__modal-div">
            Строк:
            <br />
            <InputNumber
              min={0}
              max={10}
              value={copm.newItems}
              onChange={value =>
                this.props.setFieldForClient('newItems', value)
              }
            />
          </div>
        </Modal>
        {copm.tables.map(item => (
          <div key={item.id}>
            <h3 style={{ marginTop: '20px' }}>
              {moment(item.date).format('DD.MM.YYYY')},{' '}
              {item.author === 0 ? 'клиент' : 'законный представитель'}
            </h3>
            <Button
              style={{ marginBottom: '10px' }}
              onClick={() => this.props.addCopmItem(item.id)}
            >
              Добавить
            </Button>
            <Table
              dataSource={this.generateTableDataSource(item.items, item.id)}
              columns={columns}
              pagination={false}
              rowClassName={record =>
                record.staticRaw
                  ? 'Client__table-static-row ant-table-row-selected'
                  : ''
              }
            />
          </div>
        ))}
      </Spin>
    )
  }
}

const mapStateToProps = store => {
  return {
    client: store.client
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getCopmTalbes: id => dispatch(getCopmTalbes(id)),
    createCopmTable: (clientId, formObj) =>
      dispatch(createCopmTable(clientId, formObj)),
    setFieldForClient: (field, value) =>
      dispatch(setFieldForClient(field, value, 'copm')),
    addCopmItem: copmId => dispatch(addCopmItem(copmId)),
    cancelCopmItem: (copmId, id = null, uuid = null) =>
      dispatch(cancelCopmItem(copmId, id, uuid)),
    editCopmItem: (copmId, id) => dispatch(editCopmItem(copmId, id)),
    updateCopmItemField: (tmpCopmItemId, field, value) =>
      dispatch(updateCopmItemField(tmpCopmItemId, field, value)),
    createCopmItem: (copmId, formObj) =>
      dispatch(createCopmItem(copmId, formObj)),
    updateCopmItem: (id, formObj) => dispatch(updateCopmItem(id, formObj)),
    deleteCopmItem: (copmId, id) => dispatch(deleteCopmItem(copmId, id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CopmTab)
