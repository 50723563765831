import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  Icon,
  Spin,
  Table,
  Divider,
  Popconfirm,
  InputNumber,
  Button,
  Modal,
  DatePicker,
  Row,
  Col,
  Select
} from 'antd'
import moment from 'moment'
import {
  EditOutlined,
  DeleteOutlined,
  SaveOutlined,
  CloseOutlined
} from '@ant-design/icons'

import DateRangeLoad from '../../../../../components/DateRangeLoadComponent'

import {
  getList,
  updateTableItem,
  deleteItem,
  createTableItem,
  setField,
  setWeightValue
} from '../../../../../redux/actions/ClientEstimatesActions'
import { getData } from '../../../../../redux/actions/ClientActions'

const { Option } = Select

class WeightTab extends PureComponent {
  state = {
    isOpenModal: false,
    createDate: moment().format('YYYY-MM-DD')
  }

  componentDidMount() {
    this.props.getList(this.props.clientId)
  }

  calculateImt(id) {
    const { weight } = this.props.clientEstimates
    const item = weight.editableItems[id]

    if (item.height > 0) {
      let heightInMeters = item.height / 100

      this.props.setWeightValue(
        id,
        'imt',
        Math.round((item.weight / (heightInMeters * heightInMeters)) * 10) / 10
      )
    }
  }

  getEditField(measurement, item) {
    const { weight } = this.props.clientEstimates

    let field = null

    switch (measurement.type) {
      case 'integer':
        field = (
          <InputNumber
            min={0}
            step={1}
            value={weight.editableItems[item.id][measurement.index]}
            onChange={value => {
              this.props.setWeightValue(
                item.id,
                measurement.index,
                Math.round(value)
              )
              this.calculateImt(item.id)
            }}
          />
        )
        break
      case 'float':
        field = (
          <InputNumber
            min={0}
            step={0.1}
            value={weight.editableItems[item.id][measurement.index]}
            onChange={value => {
              this.props.setWeightValue(
                item.id,
                measurement.index,
                Math.round(value * 10) / 10
              )
              this.calculateImt(item.id)
            }}
          />
        )
        break
      case 'select':
        field = (
          <Select
            style={{ width: '90px' }}
            value={weight.editableItems[item.id][measurement.index]}
            onChange={value =>
              this.props.setWeightValue(item.id, measurement.index, value)
            }
          >
            {measurement.options.map(color => (
              <Option
                value={color}
                key={`${item.id}-${measurement.index}-${color}`}
              >
                <span
                  className={`Weight__color-mark Weight__color-mark-${color}`}
                ></span>
              </Option>
            ))}
          </Select>
        )
        break
      default:
        break
    }

    return field
  }

  render() {
    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />

    const { weight } = this.props.clientEstimates

    let columns = [
      {
        title: '',
        dataIndex: 'measurement',
        width: 200,
        fixed: 'left'
      }
    ]

    weight.items.forEach(item => {
      columns.push({
        title: (
          <>
            <span style={{ marginRight: '8px' }}>
              {moment(item.date, 'YYYY-MM-DD').format('DD.MM.YYYY')}
            </span>
            {weight.editableIds.indexOf(item.id) === -1 ? (
              <span className="Clients__control-buttons">
                <a
                  href="/"
                  onClick={e => {
                    e.preventDefault()
                    this.props.setField('editableIds', [
                      ...weight.editableIds,
                      item.id
                    ])
                    this.props.setField('editableItems', {
                      ...weight.editableItems,
                      [item.id]: item
                    })
                  }}
                >
                  <EditOutlined />
                </a>
                <Divider type="vertical" />
                <Popconfirm
                  title="Вы уверены, что хотите удалить оценки?"
                  onConfirm={e => {
                    this.props.deleteItem(item.id).then(() => {
                      this.props.getData(this.props.clientId)
                    })
                  }}
                  okText="Да"
                  cancelText="Нет"
                >
                  <a href="/">
                    <DeleteOutlined />
                  </a>
                </Popconfirm>
              </span>
            ) : (
              <span className="Clients__control-buttons">
                <a
                  href="/"
                  onClick={e => {
                    e.preventDefault()
                    this.props.setField(
                      'editableIds',
                      weight.editableIds.filter(value => value !== item.id)
                    )
                    this.props
                      .updateTableItem(item.id, weight.editableItems[item.id])
                      .then(() => {
                        this.props.getData(this.props.clientId)
                      })
                  }}
                >
                  <SaveOutlined />
                </a>
                <Divider type="vertical" />
                <a
                  href="/"
                  onClick={e => {
                    e.preventDefault()
                    this.props.setField(
                      'editableIds',
                      weight.editableIds.filter(value => value !== item.id)
                    )
                  }}
                >
                  <CloseOutlined />
                </a>
              </span>
            )}
          </>
        ),
        dataIndex: `weight_${item.id}`,
        width: 150
      })
    })

    const measurements = [
      {
        key: 1,
        name: 'Рост, см',
        index: 'height',
        type: 'float'
      },
      {
        key: 2,
        name: 'Вес, кг',
        index: 'weight',
        type: 'float'
      },
      {
        key: 3,
        name: 'ИМТ',
        index: 'imt',
        type: 'float'
      },
      {
        key: 4,
        name: 'КЖСТ, мм',
        index: 'kjst',
        type: 'integer'
      },
      {
        key: 5,
        name: 'Цвет',
        index: 'color',
        type: 'select',
        options: ['green', 'yellow', 'red']
      }
    ]

    let data = []
    if (weight.items.length > 0) {
      measurements.forEach(measurement => {
        let rowObj = {
          key: measurement.key,
          measurement: measurement.name
        }

        weight.items.forEach(item => {
          let viewValue = item[measurement.index]

          if (measurement.type === 'select') {
            viewValue = (
              <span
                className={`Weight__color-mark Weight__color-mark-${
                  item[measurement.index]
                }`}
              ></span>
            )
          }

          rowObj[`weight_${item.id}`] =
            weight.editableIds.indexOf(item.id) === -1
              ? viewValue
              : this.getEditField(measurement, item)
        })

        data.push(rowObj)
      })
    }

    return (
      <Spin spinning={weight.isLoading} indicator={antIcon}>
        <Button
          type="primary"
          onClick={() => this.setState({ isOpenModal: true })}
        >
          Создать
        </Button>
        <Row gutter={16} className="Clients__settings" align="middle">
          <Col span={23}>
            <DateRangeLoad
              clientId={this.props.clientId}
              getList={this.props.getList}
            />
          </Col>
        </Row>
        <Modal
          title="Параметры оценки"
          visible={this.state.isOpenModal}
          onOk={() => {
            this.props.createTableItem(this.props.clientId, {
              date: this.state.createDate
            })
            this.setState({
              isOpenModal: false,
              createDate: moment().format('YYYY-MM-DD')
            })
          }}
          onCancel={() => this.setState({ isOpenModal: false })}
        >
          <div className="Client__modal-div">
            Дата
            <br />
            <DatePicker
              placeholder="Выберите дату"
              format={'DD.MM.YYYY'}
              value={moment(this.state.createDate, 'YYYY-MM-DD')}
              allowClear={false}
              onChange={(date, dateString) => {
                this.setState({
                  createDate: moment(dateString, 'DD.MM.YYYY').format(
                    'YYYY-MM-DD'
                  )
                })
              }}
            />
          </div>
        </Modal>
        <p></p>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{ x: `${columns.length * 200}px` }}
        />
      </Spin>
    )
  }
}

const mapStateToProps = store => {
  return {
    clientEstimates: store.clientEstimates
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    getList: (id, params = {}) =>
      dispatch(getList(id, 'weight', 'weight', params)),
    setField: (field, value) => dispatch(setField(field, value, 'weight')),
    deleteItem: id => dispatch(deleteItem(id, 'weight', 'weight')),
    updateTableItem: (id, formObj) =>
      dispatch(updateTableItem(id, 'weight', formObj)),
    setWeightValue: (id, field, value) =>
      dispatch(setWeightValue(id, field, value)),
    createTableItem: (clientId, formObj) =>
      dispatch(createTableItem(clientId, 'weight', formObj)),
    getData: id => dispatch(getData(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WeightTab)
