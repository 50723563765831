import produce from 'immer'

import {
  CLIENTS_LOAD_REQUEST,
  CLIENTS_LOAD_SUCCESS,
  COUNTER_LOAD_SUCCESS
} from '../actions/ClientsActions'

import {
  CREATE_CLIENT_REQUEST,
  CREATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_SUCCESS
} from '../actions/ClientActions'

const initialState = {
  isLoadingClients: false,
  clients: [],
  animation: true,
  pageNumber: 1,
  isTotalReached: false,
  sort: {
    field: 'id',
    type: 'desc'
  },
  filter: {
    status: 'active',
    search: ''
  },
  counter: {
    all: 0,
    new: 0,
    active: 0,
    archive: 0
  }
}

export default function(state = initialState, action) {
  switch (action.type) {
    case COUNTER_LOAD_SUCCESS:
      return produce(state, draftState => {
        draftState.counter = action.payload.data
      })
    case UPDATE_CLIENT_SUCCESS:
      return produce(state, draftState => {
        let clientIndex = draftState.clients.findIndex(
          item => item.id === action.payload.data.id
        )
        draftState.clients[clientIndex] = action.payload.data
      })
    case CREATE_CLIENT_SUCCESS:
      return produce(state, draftState => {
        draftState.clients.unshift(action.payload.data)
      })
    case CREATE_CLIENT_REQUEST:
      return produce(state, draftState => {
        draftState.animation = true
      })
    case CLIENTS_LOAD_REQUEST:
      return produce(state, draftState => {
        draftState.isLoadingClients = true
        draftState.isTotalReached = false

        const pageNumber = action.payload.request.params['page']
        if (pageNumber === 1) {
          draftState.clients = []
        }
        draftState.pageNumber = pageNumber

        if (action.payload.request.params['status']) {
          draftState.filter.status = action.payload.request.params['status']
        } else {
          draftState.filter.status = initialState.filter.status
        }

        if (action.payload.request.params['search']) {
          draftState.filter.search = action.payload.request.params['search']
        } else {
          draftState.filter.search = ''
        }

        if (action.payload.request.params['sort']) {
          draftState.sort.field = action.payload.request.params['sort']
          draftState.sort.type = action.payload.request.params['sort_type']
        } else {
          draftState.sort = initialState.sort
        }
      })
    case CLIENTS_LOAD_SUCCESS:
      return produce(state, draftState => {
        draftState.isLoadingClients = false
        draftState.animation = false

        if (draftState.pageNumber === 1) {
          draftState.clients = action.payload.data
        } else {
          draftState.isTotalReached = action.payload.data.length === 0
          draftState.clients = draftState.clients.concat(action.payload.data)
        }
      })
    default:
      return state
  }
}
