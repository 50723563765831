import produce from 'immer'

import {
  SET_DATE_RANGE,
  SET_FIELD_FOR_UNLOADING,
  RESET_FILTERS
} from '../actions/UnloadingActions'

const initialState = {
  clients: {
    date_of_birth_from: null,
    date_of_birth_to: null,
    service_date_from: null,
    service_date_to: null,
    diagnosis_ids: [],
    service_types: [],
    service_specialist_types: [],
    service_specialist_ids: [],
    project_ids: [],
    sponsor_ids: [],
    gmfcs: [],
    macs: [],
    cfcs: [],
    edacs: [],
    vfcs: []
  },
  clients_common_info: {
    is_archive: [],
    has_complete_services: []
  },
  specialists: {
    service_date_from: null,
    service_date_to: null,
    service_specialist_ids: [],
    service_types: [],
    service_specialist_types: [],
    project_ids: [],
    sponsor_ids: [],
    statuses: [],
    reasons_for_absence: []
  },
  sponsors: {
    service_date_from: null,
    service_date_to: null,
    service_specialist_ids: [],
    service_types: [],
    service_specialist_types: [],
    project_ids: [],
    sponsor_ids: [],
    client_ids: [],
    statuses: [],
    reasons_for_absence: [],
    type: 1
  },
  employment_and_statistics: {
    service_date_from: null,
    service_date_to: null,
    service_types: [],
    service_specialist_ids: [],
    service_specialist_types: [],
    project_ids: [],
    sponsor_ids: [],
    state: 0
  },
  calendar: {
    clients: [],
    specialists: []
  }
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_DATE_RANGE:
      return produce(state, draftState => {
        if (action.payload.type === 'date_of_birth') {
          draftState[action.payload.mode].date_of_birth_from =
            action.payload.date_from
          draftState[action.payload.mode].date_of_birth_to =
            action.payload.date_to
        } else {
          draftState[action.payload.mode].service_date_from =
            action.payload.date_from
          draftState[action.payload.mode].service_date_to =
            action.payload.date_to
        }
      })
    case SET_FIELD_FOR_UNLOADING:
      return produce(state, draftState => {
        draftState[action.payload.mode][action.payload.field] =
          action.payload.value
      })
    case RESET_FILTERS:
      return produce(state, draftState => {
        draftState[action.payload.mode] = initialState[action.payload.mode]
      })
    default:
      return state
  }
}
