import produce from 'immer'
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_NOT_FOUND,
  USER_LOGIN_SERVER_ERROR,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_UPDATE_SETTINGS
} from '../actions/UserActions'

const initialState = {
  __persist: true,
  isLoading: false,
  authorized: false,
  type: '',
  id: '',
  attributes: {
    login: '',
    name: '',
    token: '',
    settings_access: 0,
    settings_report_date_access: 0,
    show_in_schedule_table: 0
  },
  error: {
    code: null,
    text: ''
  },
  settings: {
    tables: {}
  }
}

export default function(state = initialState, action) {
  switch (action.type) {
    case USER_UPDATE_SETTINGS:
      return produce(state, draftState => {
        draftState.settings[action.payload.section][action.payload.field] =
          action.payload.value
      })
    case USER_LOGIN_REQUEST:
      return produce(state, draftState => {
        draftState.isLoading = true
      })
    case USER_LOGIN_NOT_FOUND:
      return produce(state, draftState => {
        draftState.isLoading = false
        draftState.error = {
          code: 404,
          text:
            'Введен неверный логин или пароль. После пяти неудачных попыток входа доступ в модуль будет заблокирован'
        }
      })
    case USER_LOGIN_SERVER_ERROR:
      return produce(state, draftState => {
        draftState.isLoading = false
        draftState.authorized = false
        draftState.error = {
          code: 500,
          text:
            'Возникла проблема со входом. Попробуйте еще раз и обратитесь в службу поддержки, если проблема повторится'
        }
      })
    case USER_LOGIN_SUCCESS:
      const { data } = action.payload
      return produce(state, draftState => {
        draftState.isLoading = false
        draftState.authorized = true
        draftState.error = {
          code: null,
          text: ''
        }
        draftState.id = data.id
        draftState.type = data.type
        draftState.attributes.login = data.login
        draftState.attributes.token = data.token
        draftState.attributes.name = data.name + ' ' + data.surname
        draftState.attributes.settings_access = data.settings_access
        draftState.attributes.settings_report_date_access =
          data.settings_report_date_access
        draftState.attributes.show_in_schedule_table =
          data.show_in_schedule_table
      })
    case USER_LOGOUT:
      return initialState
    default:
      return state
  }
}
