import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  Form,
  Button,
  TreeSelect,
  Icon,
  Spin,
  Select,
  Switch,
  message,
  DatePicker,
  Modal
} from 'antd'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import moment from 'moment'

import {
  setFieldForClient,
  getGeneralData,
  updateGeneralData,
  moveToArchive,
  getProjectsList,
  getSponsorsList,
  getDiagnosesList,
  getSiteUsersList
} from '../../../../redux/actions/ClientActions'

import AnketaForm from './AnketaFormComponent'

const { Option } = Select
const { TreeNode } = TreeSelect

class CommonTab extends PureComponent {
  state = {
    archiveModal: {
      isOpen: false,
      type: 'project',
      date: moment().format('YYYY-MM-DD')
    }
  }

  componentDidMount() {
    this.props.getGeneralData(this.props.clientId)
    this.props.getDiagnosesList()
    this.props.getProjectsList()
    this.props.getSponsorsList()
    this.props.getSiteUsersList()
  }

  componentDidUpdate() {
    const { commonClientForm } = this.props.client

    if (commonClientForm.isSuccess) {
      message.success('Изменения сохранены')
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { commonClientForm } = this.props.client
        let formObj = {}

        Object.keys(commonClientForm).forEach(item => {
          if (
            commonClientForm[item] !== null &&
            item !== 'isSending' &&
            item !== 'isSuccess'
          ) {
            formObj[item] = commonClientForm[item]
          }
        })

        this.props.updateGeneralData(this.props.clientId, formObj)
      }
    })
  }

  render() {
    const commonClientForm = this.props.client.commonClientForm
    const commonData = this.props.client.commonData

    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />
    const { getFieldDecorator } = this.props.form

    const formItemLayout = {
      labelCol: {
        sm: { span: 4 }
      },
      wrapperCol: {
        sm: { span: 8 }
      }
    }

    const tailFormItemLayout = {
      wrapperCol: {
        sm: {
          span: 16,
          offset: 4
        }
      }
    }

    const { archiveModal } = this.state

    return (
      <div>
        <h3>Общая информация</h3>
        <Spin spinning={commonClientForm.isSending} indicator={antIcon}>
          <Form
            {...formItemLayout}
            onSubmit={this.handleSubmit}
            autoComplete="off"
            labelAlign="left"
          >
            <Form.Item label="Диагноз">
              {getFieldDecorator('diagnosis_ids', {
                rules: [{ required: false, message: 'Выберите' }],
                initialValue: commonClientForm.diagnosis_ids
              })(
                <TreeSelect
                  placeholder="Выберите"
                  allowClear
                  treeDefaultExpandAll
                  multiple
                  onChange={value =>
                    this.props.setFieldForCommonClient('diagnosis_ids', value)
                  }
                >
                  {commonData.diagnoses_list.map(item => (
                    <TreeNode key={item.id} value={item.id} title={item.name}>
                      {item.children.map(child => (
                        <TreeNode
                          key={child.id}
                          value={child.id}
                          title={child.name}
                        />
                      ))}
                    </TreeNode>
                  ))}
                </TreeSelect>
              )}
            </Form.Item>

            <Form.Item label="GMFCS">
              {getFieldDecorator('gmfcs', {
                rules: [{ required: false, message: 'Выберите' }],
                initialValue: commonClientForm.gmfcs
                  ? commonClientForm.gmfcs
                  : undefined
              })(
                <Select
                  placeholder="Выберите"
                  onChange={value =>
                    this.props.setFieldForCommonClient('gmfcs', parseInt(value))
                  }
                >
                  <Option key="1" value="1">
                    I
                  </Option>
                  <Option key="2" value="2">
                    II
                  </Option>
                  <Option key="3" value="3">
                    III
                  </Option>
                  <Option key="4" value="4">
                    IV
                  </Option>
                  <Option key="5" value="5">
                    V
                  </Option>
                </Select>
              )}
            </Form.Item>

            <Form.Item label="MACS">
              {getFieldDecorator('macs', {
                rules: [{ required: false, message: 'Выберите' }],
                initialValue: commonClientForm.macs
                  ? commonClientForm.macs
                  : undefined
              })(
                <Select
                  placeholder="Выберите"
                  onChange={value =>
                    this.props.setFieldForCommonClient('macs', parseInt(value))
                  }
                >
                  <Option key="1" value="1">
                    I
                  </Option>
                  <Option key="2" value="2">
                    II
                  </Option>
                  <Option key="3" value="3">
                    III
                  </Option>
                  <Option key="4" value="4">
                    IV
                  </Option>
                  <Option key="5" value="5">
                    V
                  </Option>
                </Select>
              )}
            </Form.Item>

            <Form.Item label="CFCS">
              {getFieldDecorator('cfcs', {
                rules: [{ required: false, message: 'Выберите' }],
                initialValue: commonClientForm.cfcs
                  ? commonClientForm.cfcs
                  : undefined
              })(
                <Select
                  placeholder="Выберите"
                  onChange={value =>
                    this.props.setFieldForCommonClient('cfcs', parseInt(value))
                  }
                >
                  <Option key="1" value="1">
                    I
                  </Option>
                  <Option key="2" value="2">
                    II
                  </Option>
                  <Option key="3" value="3">
                    III
                  </Option>
                  <Option key="4" value="4">
                    IV
                  </Option>
                  <Option key="5" value="5">
                    V
                  </Option>
                </Select>
              )}
            </Form.Item>

            <Form.Item label="EDACS">
              {getFieldDecorator('edacs', {
                rules: [{ required: false, message: 'Выберите' }],
                initialValue: commonClientForm.edacs
                  ? commonClientForm.edacs
                  : undefined
              })(
                <Select
                  placeholder="Выберите"
                  onChange={value =>
                    this.props.setFieldForCommonClient('edacs', parseInt(value))
                  }
                >
                  <Option key="1" value="1">
                    I
                  </Option>
                  <Option key="2" value="2">
                    II
                  </Option>
                  <Option key="3" value="3">
                    III
                  </Option>
                  <Option key="4" value="4">
                    IV
                  </Option>
                  <Option key="5" value="5">
                    V
                  </Option>
                </Select>
              )}
            </Form.Item>

            {commonClientForm.edacs && (
              <Form.Item label="Состояние">
                {getFieldDecorator('edacs_type', {
                  rules: [{ required: false, message: 'Выберите' }],
                  initialValue: commonClientForm.edacs_type
                    ? commonClientForm.edacs_type
                    : undefined
                })(
                  <Select
                    placeholder="Выберите"
                    onChange={value =>
                      this.props.setFieldForCommonClient(
                        'edacs_type',
                        parseInt(value)
                      )
                    }
                  >
                    <Option value={1}>Независимый (Н)</Option>
                    <Option value={2}>Нуждается в помощи (НП)</Option>
                    <Option value={3}>Полностью зависим (ПЗ)</Option>
                  </Select>
                )}
              </Form.Item>
            )}

            <Form.Item label="ARFID">
              {getFieldDecorator('arfid', {
                rules: [{ required: false, message: 'Выберите' }],
                initialValue: commonClientForm.arfid === 1,
                valuePropName: 'checked'
              })(
                <Switch
                  onChange={value =>
                    this.props.setFieldForCommonClient('arfid', value ? 1 : 0)
                  }
                />
              )}
            </Form.Item>

            <Form.Item label="VFCS">
              {getFieldDecorator('vfcs', {
                rules: [{ required: false, message: 'Выберите' }],
                initialValue: commonClientForm.vfcs
                  ? commonClientForm.vfcs
                  : undefined
              })(
                <Select
                  placeholder="Выберите"
                  onChange={value =>
                    this.props.setFieldForCommonClient('vfcs', parseInt(value))
                  }
                >
                  <Option key="1" value="1">
                    I
                  </Option>
                  <Option key="2" value="2">
                    II
                  </Option>
                  <Option key="3" value="3">
                    III
                  </Option>
                  <Option key="4" value="4">
                    IV
                  </Option>
                  <Option key="5" value="5">
                    V
                  </Option>
                </Select>
              )}
            </Form.Item>

            <Form.Item label="Текущий проект">
              <Select
                placeholder="Выберите"
                showSearch
                optionFilterProp="children"
                value={
                  commonClientForm.project_id
                    ? commonClientForm.project_id
                    : undefined
                }
                onChange={value => {
                  if (
                    !commonClientForm.project_id ||
                    commonClientForm.project_start_date === '1970-01-01'
                  ) {
                    this.props.setFieldForCommonClient(
                      'project_start_date',
                      moment().format('YYYY-MM-DD')
                    )
                  }

                  this.props.setFieldForCommonClient(
                    'project_id',
                    parseInt(value)
                  )
                }}
              >
                {commonData.projects_list.map(item => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
              {commonClientForm.project_id && (
                <>
                  <DatePicker
                    placeholder="Дата начала проекта"
                    format={'DD.MM.YYYY'}
                    style={{ width: '100%' }}
                    value={moment(
                      commonClientForm.project_start_date,
                      'YYYY-MM-DD'
                    )}
                    onChange={(date, dateString) =>
                      this.props.setFieldForCommonClient(
                        'project_start_date',
                        moment(dateString, 'DD.MM.YYYY').format('YYYY-MM-DD')
                      )
                    }
                  />
                  <Button
                    type="default"
                    onClick={() => {
                      this.setState({
                        archiveModal: {
                          type: 'project',
                          isOpen: true,
                          date: moment().format('YYYY-MM-DD')
                        }
                      })
                    }}
                  >
                    Перенести проект в архив
                  </Button>
                </>
              )}
            </Form.Item>

            {commonClientForm.projects_archive.length > 0 && (
              <Form.Item label="Архив проектов">
                <ul className="Client__form-ul">
                  {commonClientForm.projects_archive.map(item => (
                    <li>
                      {item.name} (
                      {moment(item.start_date, 'YYYY-MM-DD').format(
                        'DD.MM.YYYY'
                      )}{' '}
                      –{' '}
                      {moment(item.end_date, 'YYYY-MM-DD').format('DD.MM.YYYY')}
                      )
                    </li>
                  ))}
                </ul>
              </Form.Item>
            )}

            <Form.Item label="Текущий спонсор">
              <Select
                placeholder="Выберите"
                showSearch
                optionFilterProp="children"
                value={
                  commonClientForm.sponsor_id
                    ? commonClientForm.sponsor_id
                    : undefined
                }
                onChange={value => {
                  if (
                    !commonClientForm.sponsor_id ||
                    commonClientForm.sponsor_start_date === '1970-01-01'
                  ) {
                    this.props.setFieldForCommonClient(
                      'sponsor_start_date',
                      moment().format('YYYY-MM-DD')
                    )
                  }

                  this.props.setFieldForCommonClient(
                    'sponsor_id',
                    parseInt(value)
                  )
                }}
              >
                {commonData.sponsors_list.map(item => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
              {commonClientForm.sponsor_id && (
                <>
                  <DatePicker
                    placeholder="Дата начала работы со спонсором"
                    format={'DD.MM.YYYY'}
                    style={{ width: '100%' }}
                    value={moment(
                      commonClientForm.sponsor_start_date,
                      'YYYY-MM-DD'
                    )}
                    onChange={(date, dateString) =>
                      this.props.setFieldForCommonClient(
                        'sponsor_start_date',
                        moment(dateString, 'DD.MM.YYYY').format('YYYY-MM-DD')
                      )
                    }
                  />
                  <Button
                    type="default"
                    onClick={() => {
                      this.setState({
                        archiveModal: {
                          type: 'sponsor',
                          isOpen: true,
                          date: moment().format('YYYY-MM-DD')
                        }
                      })
                    }}
                  >
                    Перенести спонсора в архив
                  </Button>
                </>
              )}
            </Form.Item>

            {commonClientForm.sponsors_archive.length > 0 && (
              <Form.Item label="Архив спонсоров">
                <ul className="Client__form-ul">
                  {commonClientForm.sponsors_archive.map(item => (
                    <li>
                      {item.name} (
                      {moment(item.start_date, 'YYYY-MM-DD').format(
                        'DD.MM.YYYY'
                      )}{' '}
                      –{' '}
                      {moment(item.end_date, 'YYYY-MM-DD').format('DD.MM.YYYY')}
                      )
                    </li>
                  ))}
                </ul>
              </Form.Item>
            )}

            <Form.Item label="Пользователь сайта">
              {getFieldDecorator('site_user_id', {
                rules: [{ required: false, message: 'Выберите' }],
                initialValue: commonClientForm.site_user_id
                  ? commonClientForm.site_user_id
                  : undefined
              })(
                <Select
                  placeholder="Выберите"
                  showSearch
                  optionFilterProp="children"
                  onChange={value =>
                    this.props.setFieldForCommonClient(
                      'site_user_id',
                      parseInt(value)
                    )
                  }
                >
                  {commonData.site_users_list.map(item => (
                    <Option key={item.id} value={item.id}>
                      {item.surname} {item.name} ({item.email})
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>

            <Form.Item label="Комментарий">
              <CKEditor
                editor={ClassicEditor}
                data={commonClientForm.comment}
                config={{
                  toolbar: ['heading', 'bold', 'italic', 'link', 'blockQuote'],
                  heading: {
                    options: [
                      {
                        model: 'paragraph',
                        title: 'Параграф',
                        class: 'ck-heading_paragraph'
                      },
                      {
                        model: 'heading2',
                        view: 'h2',
                        title: 'Заголовок',
                        class: 'ck-heading_heading2'
                      }
                    ]
                  }
                }}
                onChange={(event, editor) => {
                  const data = editor.getData()
                  this.props.setFieldForCommonClient('comment', data)
                }}
              />
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" htmlType="submit">
                Сохранить
              </Button>
            </Form.Item>
          </Form>
        </Spin>

        <Modal
          title="Дата добавления в архив"
          visible={archiveModal.isOpen}
          onOk={() => {
            this.setState({
              archiveModal: {
                ...archiveModal,
                isOpen: false
              }
            })

            let type = archiveModal.type

            const item = commonData[`${type}s_list`].find(
              item => item.id === commonClientForm[`${type}_id`]
            )
            this.props.moveToArchive(type, item.name, archiveModal.date)
          }}
          onCancel={() =>
            this.setState({
              archiveModal: {
                ...archiveModal,
                isOpen: false
              }
            })
          }
          okText="Применить"
          cancelText="Отмена"
        >
          <DatePicker
            placeholder="Дата добавления в архив"
            format={'DD.MM.YYYY'}
            style={{ width: '100%' }}
            value={moment(archiveModal.date, 'YYYY-MM-DD')}
            onChange={(date, dateString) =>
              this.setState({
                archiveModal: {
                  ...archiveModal,
                  date: moment(dateString, 'DD.MM.YYYY').format('YYYY-MM-DD')
                }
              })
            }
          />
        </Modal>

        <h3>Анкета</h3>
        <AnketaForm clientId={this.props.clientId} />
      </div>
    )
  }
}

const mapStateToProps = store => {
  return {
    client: store.client
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setFieldForCommonClient: (field, value) =>
      dispatch(setFieldForClient(field, value, 'commonClientForm')),
    getGeneralData: id => dispatch(getGeneralData(id)),
    updateGeneralData: (id, formObj) =>
      dispatch(updateGeneralData(id, formObj)),
    moveToArchive: (type = 'project', name, date) =>
      dispatch(moveToArchive(type, name, date)),
    getProjectsList: () => dispatch(getProjectsList()),
    getSponsorsList: () => dispatch(getSponsorsList()),
    getDiagnosesList: () => dispatch(getDiagnosesList()),
    getSiteUsersList: () => dispatch(getSiteUsersList())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'CommonClientForm' })(CommonTab))
