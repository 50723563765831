import React, { PureComponent } from 'react'
import { Icon, Tooltip } from 'antd'

class ClientStatus extends PureComponent {
  render() {
    const { client } = this.props

    let statusIcon = null

    if (client.is_archive) {
      statusIcon = (
        <Icon
          type="folder"
          className="Clients__consistent-event"
          title="Архивный"
        />
      )
    } else {
      statusIcon = (
        <Icon
          type="check-circle"
          className="Clients__agreed-event"
          title="Действующий"
        />
      )
    }

    let weightStatusIcon = null

    if (client.last_estimates_weight_color) {
      let tip = 'Недостаток веса'

      switch (client.last_estimates_weight_color) {
        case 'green':
          tip = 'Вес в норме'
          break
        case 'yellow':
          tip = 'Мониторинг веса'
          break
        default:
          break
      }
      weightStatusIcon = (
        <Tooltip title={tip}>
          <Icon
            type="medicine-box"
            className={`Clients__weight-status Clients__weight-status-${client.last_estimates_weight_color}`}
          />
        </Tooltip>
      )
    }

    return (
      <>
        {statusIcon}
        {weightStatusIcon}
      </>
    )
  }
}

export default ClientStatus
