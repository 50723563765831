import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Modal, Select, Tooltip, Icon, Button } from 'antd'

import { updateSettings } from '../redux/actions/UserActions'

const { Option } = Select

class TableSettings extends PureComponent {
  state = {
    isOpenModal: false,
    value: [],
    isValueChanged: false
  }

  componentDidMount() {
    const { section, columns, user } = this.props

    if (!user.settings.tables[section]) {
      this.props.updateSettings(
        'tables',
        section,
        columns.filter(item => item.isViewByDefault).map(item => item.key)
      )
    }
  }

  render() {
    const { section, columns, user } = this.props

    if (!user.settings.tables[section]) {
      return null
    }

    const options = columns.map(item => (
      <Option key={item.key} value={item.key}>
        {item.title}
      </Option>
    ))

    return (
      <>
        <Tooltip
          placement="bottom"
          mouseEnterDelay={0.75}
          title="Настройка отображаемых столбцов"
        >
          <Button
            shape="circle"
            size="large"
            onClick={() => this.setState({ isOpenModal: true })}
            className="Client__settings-button"
          >
            <Icon type="setting" className="Client__calendar-icon" />
          </Button>
        </Tooltip>

        <Modal
          title="Настройка отображения"
          visible={this.state.isOpenModal}
          onOk={() => {
            this.setState({ isOpenModal: false })
            if (this.state.isValueChanged) {
              this.props.updateSettings('tables', section, this.state.value)
            }
          }}
          onCancel={() =>
            this.setState({
              isOpenModal: false,
              value: [],
              isValueChanged: false
            })
          }
          okText="Сохранить"
          cancelText="Отмена"
        >
          <Select
            style={{ width: '100%' }}
            mode="multiple"
            placeholder="Выберите столбцы для отображения"
            value={
              this.state.value.length === 0
                ? user.settings.tables[section]
                : this.state.value
            }
            onChange={value => this.setState({ value, isValueChanged: true })}
          >
            {options}
          </Select>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = store => {
  return {
    user: store.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateSettings: (section, field, value) =>
      dispatch(updateSettings(section, field, value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableSettings)
