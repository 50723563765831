import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Icon,
  DatePicker,
  Spin,
  Form,
  Input,
  Tabs,
  Select,
  InputNumber,
  Checkbox,
  Upload,
  message,
  Alert
} from 'antd'
import moment from 'moment'

import {
  setField,
  setFoodAnswer,
  createItem,
  updateItem
} from '../../../../../redux/actions/ClientEstimatesActions'

import checkFileType from '../../../../../utils/checkFileType'

const { TabPane } = Tabs
const { TextArea } = Input
const { Option } = Select

let updateTimer

class FoodForm extends PureComponent {
  /*
    Question params: 
    answer: text || list as text with ; || file obj {id: null, name: '', hash: ''}
    type: static || input-number || textarea || select (show as simple text) || 
      multiple-select (split by ;) || file (make link to download)
    options: use for select and multiple-select questions
    onChange: for input-number and select
    required: for all fields
    disableCheckbox: for textarea
    disableCheckboxText: for textarea
    allowTypes: for file
  */
  state = {
    formValidationErrorTabs: [],
    tabs: [
      {
        key: '1',
        name: 'Состояние здоровья',
        questions: [
          {
            key: '1-1',
            question: 'Возраст ребенка на дату приёма (полных лет)',
            type: 'select',
            required: true,
            options: [
              1,
              2,
              3,
              4,
              5,
              6,
              7,
              8,
              9,
              10,
              11,
              12,
              13,
              14,
              15,
              16,
              17,
              18
            ],
            onChange: (value, answersWithKeys) => {
              this.calculateWater(value, answersWithKeys['1-2'])
            }
          },
          {
            key: '1-2',
            question: 'Вес (кг)',
            type: 'input-number',
            required: true,
            onChange: (value, answersWithKeys) => {
              this.calculateImt(answersWithKeys['1-3'], value)
              this.calculateWater(answersWithKeys['1-1'], value)
            }
          },
          {
            key: '1-3',
            question: 'Рост (см)',
            type: 'input-number',
            required: true,
            onChange: (value, answersWithKeys) => {
              this.calculateImt(value, answersWithKeys['1-2'])
            }
          },
          {
            key: '1-4',
            question: 'ИМТ',
            type: 'static'
          },
          {
            key: '1-5',
            question: 'Необходимое количество жидкости (мл)',
            type: 'static'
          },
          {
            key: '1-6',
            question: 'Реальное примерное количество жидкости (мл)',
            type: 'input-number',
            required: true,
            onChange: (value, answersWithKeys) => {
              this.calculateDifferenceInWater(value, answersWithKeys['1-5'])
            }
          },
          {
            key: '1-7',
            question:
              'Разница необходимого и реального количества жидкости (мл)',
            type: 'static'
          },
          {
            key: '1-8',
            question: 'Признаки обезвоживания',
            type: 'multiple-select',
            required: true,
            options: [
              'нет',
              'шелушение кожи тела',
              'пересыхание губ и ротовой полости',
              'памперс более шести-восьми часов остается сухим',
              'цвет мочи более темный, чем обычно, ее запах резкий и неприятный',
              'сниженный тургор (эластичность) тканей – собранная в складку кожа не возвращается в прежнее состояние мгновенно',
              'другое'
            ]
          },
          {
            key: '1-9',
            question: 'Признаки обезвоживания (примечание)',
            type: 'textarea',
            required: false
          },
          {
            key: '1-10',
            question: 'Состояние дыхания',
            type: 'multiple-select',
            required: true,
            options: [
              'трудностей нет',
              'открытый рот в состоянии покоя',
              'частый насморк',
              'увеличенные аденоиды',
              'бронхиты',
              'пневмонии',
              'другое'
            ]
          },
          {
            key: '1-11',
            question: 'Состояние дыхания (примечание)',
            type: 'textarea',
            required: false
          },
          {
            key: '1-12',
            question: 'Признаки аспирации',
            type: 'multiple-select',
            required: true,
            options: [
              'отсутствуют',
              'кашель во время еды',
              'затрудненное дыхание во время еды при отсутствии трудностей вне приема пищи',
              'любые дополнительные звуки: свист, хрип, шум, бульканье и пр.',
              'изменение высоты голоса после глотка: во время речи, вокализации, крика',
              'изменение цвета лица',
              'раскоординированный глоток',
              'дополнительные глотки после приема пищи',
              'другое'
            ]
          },
          {
            key: '1-13',
            question: 'Признаки аспирации (примечание)',
            type: 'textarea',
            required: false
          },
          {
            key: '1-14',
            question: 'Состояние ЖКТ',
            type: 'multiple-select',
            required: true,
            options: [
              'трудностей нет',
              'отрыжка',
              'рефлюкс',
              'рвота после еды',
              'запоры',
              'жидкий стул',
              'кусочки еды в каловых массах',
              'другое'
            ]
          },
          {
            key: '1-15',
            question: 'Состояние ЖКТ (примечание)',
            type: 'textarea',
            required: false
          },
          {
            key: '1-16',
            question: 'Аллергии',
            type: 'multiple-select',
            required: true,
            options: ['нет', 'молоко', 'глютен', 'другое']
          },
          {
            key: '1-17',
            question: 'Аллергии (примечание)',
            type: 'textarea',
            required: false
          },
          {
            key: '1-18',
            question: 'Принимаемые лекарства',
            type: 'textarea',
            required: true,
            disableCheckbox: true
          }
        ]
      },
      {
        key: '2',
        name: 'Пищевое поведение',
        questions: [
          {
            key: '2-1',
            question: 'Негативный опыт',
            type: 'textarea',
            disableCheckbox: true,
            required: true
          },
          {
            key: '2-2',
            question: 'Сигналы голода',
            type: 'textarea',
            disableCheckbox: true,
            required: true
          },
          {
            key: '2-3',
            question: 'Сигналы сытости',
            type: 'textarea',
            disableCheckbox: true,
            required: true
          },
          {
            key: '2-4',
            question: 'Развлечения во время еды',
            type: 'textarea',
            disableCheckbox: true,
            disableCheckboxText: 'Не нуждается',
            required: true
          },
          {
            key: '2-5',
            question: 'Любимые продукты',
            type: 'textarea',
            disableCheckbox: true,
            required: true
          },
          {
            key: '2-6',
            question: 'Нелюбимые продукты',
            type: 'textarea',
            disableCheckbox: true,
            required: true
          },
          {
            key: '2-7',
            question: 'Возможность употребнять пищу и напитки вне дома',
            type: 'textarea',
            disableCheckbox: true,
            disableCheckboxText: 'Есть',
            required: true
          },
          {
            key: '2-8',
            question: 'Пищевой дневник',
            type: 'file',
            allowTypes: ['jpg', 'pdf', 'doc', 'docx']
          },
          {
            key: '2-9',
            question: 'Пищевой дневник (комментарий)',
            type: 'textarea',
            required: false
          }
        ]
      },
      {
        key: '3',
        name: 'Самовосприятие и активность ребенка',
        questions: [
          {
            key: '3-1',
            question: 'Прикосновения к телу',
            type: 'textarea',
            required: true,
            disableCheckbox: true,
            disableCheckboxText: 'Без особенностей'
          },
          {
            key: '3-2',
            question: 'Прикосновения к голове',
            type: 'textarea',
            required: true,
            disableCheckbox: true,
            disableCheckboxText: 'Без особенностей'
          },
          {
            key: '3-3',
            question: 'Прикосновения к лицу',
            type: 'textarea',
            required: true,
            disableCheckbox: true,
            disableCheckboxText: 'Без особенностей'
          },
          {
            key: '3-4',
            question: 'Чистка зубов',
            type: 'textarea',
            required: true,
            disableCheckbox: true,
            disableCheckboxText: 'Без особенностей'
          },
          {
            key: '3-5',
            question: 'Координация «рука-глаз-рот»',
            type: 'textarea',
            required: true,
            disableCheckbox: true,
            disableCheckboxText: 'Достаточно хорошо развита'
          },
          {
            key: '3-6',
            question: 'Cпособность поддерживать беседы о еде',
            type: 'textarea',
            required: true
          },
          {
            key: '3-7',
            question: 'Участие в организации приема пищи',
            type: 'textarea',
            required: true
          },
          {
            key: '3-8',
            question: 'Дополнения',
            type: 'textarea',
            required: false
          }
        ]
      },
      {
        key: '4',
        name: 'Оценка ротовой стадии глотания',
        questions: [
          {
            key: '4-1',
            question: 'Анатомии ротовой полости',
            type: 'textarea',
            required: true,
            disableCheckbox: true,
            disableCheckboxText: 'Без особенностей'
          },
          {
            key: '4-2',
            question: 'Состояние зубов',
            type: 'select',
            required: true,
            options: [
              'норма, регулярно посещает профилактические осмотры',
              'норма, у стоматолога не наблюдаются'
            ]
          },
          {
            key: '4-3',
            question: 'Состояние зубов (примечание)',
            type: 'textarea',
            required: false
          },
          {
            key: '4-4',
            question: 'Физиология ротовой полости',
            type: 'textarea',
            required: true,
            disableCheckbox: true,
            disableCheckboxText: 'Без особенностей'
          },
          {
            key: '4-5',
            question: 'Доступные (безопасные) консистенции по IDDSI',
            type: 'multiple-select',
            required: true,
            options: [
              '0 обычная жидкость',
              '1 незначительно загущенная',
              '2 слабо загущенная',
              '3 жидкое пюре',
              '4 пюре',
              '5 пища молотая увлажненная',
              '6 мягкая кусочками',
              '7 обычная мягкая пища',
              '7 обычная пища',
              'гастростома',
              'зонд'
            ]
          },
          {
            key: '4-6',
            question:
              'Доступные (безопасные) консистенции по IDDSI (примечание)',
            type: 'textarea',
            required: false
          }
        ]
      },
      {
        key: '5',
        name: 'Взаимодействие с родителями',
        questions: [
          {
            key: '5-1',
            question: 'Оценка домашнего видео',
            type: 'textarea',
            required: false
          },
          {
            key: '5-2',
            question: 'Обычное место для приема пищи и жидкости',
            type: 'textarea',
            required: true
          },
          {
            key: '5-3',
            question: 'Поза ребенка',
            type: 'textarea',
            required: true
          },
          {
            key: '5-4',
            question: 'Поза взрослого',
            type: 'textarea',
            required: true
          },
          {
            key: '5-5',
            question: 'Коммуникация в паре',
            type: 'textarea',
            required: true
          },
          {
            key: '5-6',
            question: 'Привычная посуда',
            type: 'textarea',
            required: true
          },
          {
            key: '5-7',
            question: 'Комментарий',
            type: 'textarea',
            required: false
          },
          {
            key: '5-8',
            question: 'Запрос',
            type: 'multiple-select',
            required: true,
            options: [
              'нормализация пищевого поведения',
              'восстановление питания через рот',
              'переход от ГВ к ложке',
              'переход от бутылки к ложке',
              'умение пить',
              'жевание',
              'самостоятельная еда ложкой',
              'снижение рисков аспирации',
              'другое'
            ]
          },
          {
            key: '5-9',
            question: 'Запрос (примечание)',
            type: 'textarea',
            required: false
          },
          {
            key: '5-10',
            question: 'Установки близких взрослых',
            type: 'textarea',
            required: true
          },
          {
            key: '5-11',
            question: 'Дополнения',
            type: 'textarea',
            required: false
          }
        ]
      }
    ]
  }

  calculateImt(height, weight) {
    if (!height || !weight) {
      this.props.setFoodAnswer(0, 3, undefined)
      return
    }

    let heightInMeters = height / 100
    this.props.setFoodAnswer(
      0,
      3,
      Math.round((weight / (heightInMeters * heightInMeters)) * 10) / 10
    )
  }

  calculateWater(age, weight) {
    if (!age || !weight) {
      this.props.setFoodAnswer(0, 4, undefined)
      return
    }

    if (age <= 3) {
      this.props.setFoodAnswer(0, 4, weight * 100)
    } else if (age > 3 && age <= 6) {
      this.props.setFoodAnswer(0, 4, weight * 90)
    } else if (age > 6 && age <= 10) {
      this.props.setFoodAnswer(0, 4, weight * 70)
    } else if (age > 10 && age < 15) {
      this.props.setFoodAnswer(0, 4, weight * 50)
    } else {
      this.props.setFoodAnswer(0, 4, weight * 50)
    }
  }

  calculateDifferenceInWater(real, necessary) {
    if (!real || !necessary) {
      this.props.setFoodAnswer(0, 6, undefined)
      return
    }

    this.props.setFoodAnswer(0, 6, necessary - real)
  }

  handleSubmit = e => {
    e.preventDefault()

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { foodForm } = this.props.clientEstimates
        const { clientId } = this.props
        let formObj = {}

        Object.keys(foodForm).forEach(item => {
          if (
            foodForm[item] !== null &&
            item !== 'id' &&
            item !== 'isOpen' &&
            item !== 'isSending'
          ) {
            formObj[item] = foodForm[item]
          }
        })

        if (!foodForm.id) {
          this.props.createItem(clientId, formObj)
        } else {
          this.props.updateItem(foodForm.id, formObj)
        }
      } else {
        let errorTabKeys = []

        Object.keys(err).forEach(key => {
          let tabKey = key.split('-')[0]
          if (!errorTabKeys.includes(tabKey)) {
            errorTabKeys.push(tabKey)
          }
        })
        this.setState({ formValidationErrorTabs: errorTabKeys })
      }
    })
  }

  componentDidMount() {
    const { tabs } = this.state

    const answersWithKeys = this.getAnswersWithKeys()

    let cleanTabs = []

    tabs.forEach(tab => {
      cleanTabs.push({
        ...tab,
        questions: tab.questions.map(question => ({
          key: question.key,
          question: question.question,
          answer: answersWithKeys[question.key],
          type: question.type
        }))
      })
    })

    this.props.setField('tabs', cleanTabs)
  }

  renderFormItem(question, answersWithKeys, tabIndex, questionIndex) {
    const { getFieldDecorator, setFieldsValue } = this.props.form

    let formItem = null

    switch (question.type) {
      case 'static':
        formItem = (
          <Input
            value={answersWithKeys[question.key]}
            disabled
            placeholder={
              question.placeholder
                ? question.placeholder
                : 'Рассчитывается автоматически'
            }
          />
        )
        break
      case 'input-number':
        formItem = getFieldDecorator(question.key, {
          rules: [
            {
              required: question.required ? question.required : false,
              message: 'Введите'
            }
          ],
          initialValue: answersWithKeys[question.key]
        })(
          <InputNumber
            min={0}
            step={0.1}
            placeholder={
              question.placeholder ? question.placeholder : 'Введите'
            }
            style={{ width: '100%' }}
            onBlur={e => {
              let formattedValue = Math.round(e.target.value * 10) / 10
              setFieldsValue({ [question.key]: formattedValue })
            }}
            onChange={value => {
              let formattedValue = Math.round(value * 10) / 10
              this.props.setFoodAnswer(tabIndex, questionIndex, formattedValue)

              if (question.onChange) {
                question.onChange(formattedValue, answersWithKeys)
              }
            }}
          />
        )
        break
      case 'textarea':
        let disableCheckboxText = question.disableCheckboxText
          ? question.disableCheckboxText
          : 'Нет'

        formItem = (
          <>
            {question.disableCheckbox && (
              <Checkbox
                onChange={e => {
                  let checked = e.target.checked
                  this.props.setFoodAnswer(
                    tabIndex,
                    questionIndex,
                    checked ? disableCheckboxText : ''
                  )
                  setFieldsValue({
                    [question.key]: checked ? disableCheckboxText : ''
                  })
                }}
                checked={answersWithKeys[question.key] === disableCheckboxText}
                disabled={
                  answersWithKeys[question.key] &&
                  answersWithKeys[question.key] !== disableCheckboxText
                    ? true
                    : false
                }
              >
                {disableCheckboxText}
              </Checkbox>
            )}
            {getFieldDecorator(question.key, {
              rules: [
                {
                  required: question.required ? question.required : false,
                  message: 'Введите'
                }
              ],
              initialValue: answersWithKeys[question.key]
            })(
              <TextArea
                disabled={answersWithKeys[question.key] === disableCheckboxText}
                placeholder={
                  question.placeholder ? question.placeholder : 'Введите текст'
                }
                rows={3}
                onChange={e => {
                  let value = e.target.value
                  clearTimeout(updateTimer)
                  updateTimer = setTimeout(() => {
                    this.props.setFoodAnswer(tabIndex, questionIndex, value)
                  }, 500)
                }}
              />
            )}
          </>
        )
        break
      case 'select':
        formItem = getFieldDecorator(question.key, {
          rules: [
            {
              required: question.required ? question.required : false,
              message: 'Выберите'
            }
          ],
          initialValue: answersWithKeys[question.key]
        })(
          <Select
            placeholder={
              question.placeholder ? question.placeholder : 'Выберите'
            }
            onChange={value => {
              this.props.setFoodAnswer(tabIndex, questionIndex, value)

              if (question.onChange) {
                question.onChange(value, answersWithKeys)
              }
            }}
          >
            {question.options.map(option => (
              <Option value={option} key={option}>
                {option}
              </Option>
            ))}
          </Select>
        )
        break
      case 'multiple-select':
        formItem = getFieldDecorator(question.key, {
          rules: [
            {
              required: question.required ? question.required : false,
              message: 'Выберите'
            }
          ],
          initialValue: answersWithKeys[question.key]
            ? answersWithKeys[question.key].split('; ')
            : undefined
        })(
          <Select
            placeholder={
              question.placeholder ? question.placeholder : 'Выберите'
            }
            onChange={value =>
              this.props.setFoodAnswer(
                tabIndex,
                questionIndex,
                value.join('; ')
              )
            }
            mode="multiple"
          >
            {question.options.map(option => (
              <Option value={option} key={option}>
                {option}
              </Option>
            ))}
          </Select>
        )
        break
      case 'file':
        let fileList = []
        if (answersWithKeys[question.key]) {
          fileList.push({
            uid: answersWithKeys[question.key].id,
            name: answersWithKeys[question.key].name,
            status: answersWithKeys[question.key].status
          })
        }
        let props = {
          name: 'file',
          action: `${process.env.REACT_APP_API_HOST}/file/add`,
          headers: {
            authorization: 'Bearer ' + this.props.user.attributes.token
          },
          fileList,
          beforeUpload: file => checkFileType(file, question.allowTypes),
          onChange: info => {
            if (info.fileList.length === 0) {
              this.props.setFoodAnswer(tabIndex, questionIndex, undefined)
              return
            }

            if (info.file.status === 'done') {
              this.props.setFoodAnswer(tabIndex, questionIndex, {
                id: info.file.response.data.id,
                name: info.file.response.data.name,
                hash: info.file.response.data.hash,
                status: 'done'
              })
            } else {
              this.props.setFoodAnswer(tabIndex, questionIndex, {
                status: 'done',
                id: info.fileList[0].uid,
                name: info.fileList[0].name,
                ...info.fileList[0]
              })

              if (info.file.error) {
                this.props.setFoodAnswer(tabIndex, questionIndex, undefined)

                if (info.file.error.status === 415) {
                  message.error(`${info.file.name} неверный формат файла`)
                } else if (info.file.error.status === 413) {
                  message.error(`${info.file.name} превышен размер файла`)
                } else {
                  message.error(
                    `${info.file.name} загрузка прервана. Код ошибки ${info.file.error.status}`
                  )
                }
              }
            }
          }
        }
        formItem = (
          <Upload {...props}>
            <Button disabled={fileList.length > 0}>
              <Icon type="upload" /> Загрузить
            </Button>
          </Upload>
        )
        break
      default:
        break
    }

    return (
      <Form.Item
        label={
          tabIndex + 1 + '.' + (questionIndex + 1) + '. ' + question.question
        }
        key={question.key}
      >
        {formItem}
      </Form.Item>
    )
  }

  getAnswersWithKeys() {
    const { foodForm } = this.props.clientEstimates

    let answersWithKeys = {}

    foodForm.tabs.forEach(tab => {
      tab.questions.forEach(question => {
        answersWithKeys[question.key] = question.answer
      })
    })

    return answersWithKeys
  }

  render() {
    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />
    const { getFieldDecorator } = this.props.form

    const { clientEstimates } = this.props
    const { foodForm } = clientEstimates

    const answersWithKeys = this.getAnswersWithKeys()

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      }
    }

    const { tabs, formValidationErrorTabs } = this.state

    return (
      <Spin spinning={foodForm.isSending} indicator={antIcon}>
        <Form
          {...formItemLayout}
          onSubmit={this.handleSubmit}
          autoComplete="off"
          className="FoodForm"
        >
          <Form.Item style={{ marginBottom: 0 }}>
            {getFieldDecorator('date', {
              rules: [{ required: true, message: 'Выберите дату' }],
              initialValue: foodForm.date
                ? moment(foodForm.date, 'YYYY-MM-DD')
                : null
            })(
              <DatePicker
                format={'DD.MM.YYYY'}
                placeholder="Выберите дату"
                onChange={(date, dateString) =>
                  this.props.setField(
                    'date',
                    moment(dateString, 'DD.MM.YYYY').format('YYYY-MM-DD')
                  )
                }
              />
            )}
          </Form.Item>

          <Tabs defaultActiveKey="1">
            {tabs.map((tab, tabIndex) => (
              <TabPane tab={tab.name} key={tab.key}>
                {tab.questions.map((question, questionIndex) =>
                  this.renderFormItem(
                    question,
                    answersWithKeys,
                    tabIndex,
                    questionIndex
                  )
                )}
              </TabPane>
            ))}
          </Tabs>

          {formValidationErrorTabs.length > 0 && (
            <Alert
              message={
                'Не заполнены обязательные поля на вкладках: ' +
                tabs
                  .filter(tab => formValidationErrorTabs.includes(tab.key))
                  .map(tab => tab.name)
                  .join(', ')
              }
              type="error"
              showIcon
            />
          )}

          <Button
            type="primary"
            htmlType="submit"
            className="Interview__form-save-button"
          >
            Сохранить
          </Button>

          <p></p>

          {foodForm.id && (
            <Form.Item>
              <p>
                Создана: {foodForm.user_creating.user_creating_name}{' '}
                {foodForm.user_creating.user_creating_surname},{' '}
                {moment(foodForm.date_creating).format('DD.MM.YYYY HH:mm')}
                <br />
                Последнее редактирование:{' '}
                {foodForm.user_last_editing.user_last_editing_name}{' '}
                {foodForm.user_last_editing.user_last_editing_surname},{' '}
                {moment(foodForm.date_last_editing).format('DD.MM.YYYY HH:mm')}
              </p>
            </Form.Item>
          )}
        </Form>
      </Spin>
    )
  }
}

const mapStateToProps = store => {
  return {
    clientEstimates: store.clientEstimates,
    user: store.user
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    setField: (field, value) => dispatch(setField(field, value, 'foodForm')),
    setFoodAnswer: (tabIndex, questionIndex, answer) =>
      dispatch(setFoodAnswer(tabIndex, questionIndex, answer)),
    createItem: (clientId, formObj) =>
      dispatch(createItem(clientId, 'food', 'food', formObj)),
    updateItem: (id, formObj) =>
      dispatch(updateItem(id, 'food', 'food', formObj))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'FoodForm' })(FoodForm))
