import produce from 'immer'

import {
  RATES_LOAD_REQUEST,
  RATES_LOAD_SUCCESS,
  OPEN_RATE_FORM,
  OPEN_EDIT_RATE_FROM,
  CLOSE_RATE_FORM,
  SET_FIELD_FOR_RATE,
  CREATE_RATE_REQUEST,
  CREATE_RATE_SUCCESS,
  UPDATE_RATE_REQUEST,
  UPDATE_RATE_SUCCESS,
  SET_PRICE_FOR_RATE,
  SYNC_RATES_REQUEST,
  SYNC_RATES_SUCCESS
} from '../actions/RatesActions'

import serviceTypes from '../../config/serviceTypes.json'
import specialistTypes from '../../config/specialistTypes.json'

let initialRelations = {}
let initialSpecialistTypes = {}

Object.keys(specialistTypes).forEach(key => {
  initialSpecialistTypes[specialistTypes[key].key] = 0
})

Object.keys(serviceTypes).forEach(key => {
  initialRelations[serviceTypes[key].key] = Object.assign(
    {},
    initialSpecialistTypes
  )
})

const initialState = {
  isLoading: false,
  data: [],
  animation: true,
  pageNumber: 1,
  isTotalReached: false,
  sort: {
    field: 'id',
    type: 'desc'
  },

  rateForm: {
    isOpen: false,
    isSending: false,

    id: null,
    name: '',
    relations: Object.assign({}, initialRelations)
  },

  syncRates: {
    isLoading: false
  }
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SYNC_RATES_REQUEST:
      return produce(state, draftState => {
        draftState.syncRates.isLoading = true
      })
    case SYNC_RATES_SUCCESS:
      return produce(state, draftState => {
        draftState.syncRates.isLoading = false
      })
    case SET_PRICE_FOR_RATE:
      return produce(state, draftState => {
        draftState.rateForm.relations[action.payload.type][
          action.payload.specialist_type
        ] = action.payload.price
      })
    case OPEN_EDIT_RATE_FROM:
      return produce(state, draftState => {
        draftState.rateForm = {
          ...action.payload.item,
          isOpen: true,
          isSending: false
        }
      })
    case UPDATE_RATE_SUCCESS:
      return produce(state, draftState => {
        draftState.rateForm.isSending = false
        draftState.rateForm.isOpen = false

        let dataIndex = draftState.data.findIndex(
          item => item.id === action.payload.data.id
        )
        draftState.data[dataIndex] = action.payload.data
      })
    case UPDATE_RATE_REQUEST:
      return produce(state, draftState => {
        draftState.rateForm.isSending = true
      })
    case CREATE_RATE_SUCCESS:
      return produce(state, draftState => {
        draftState.rateForm.isSending = false
        draftState.rateForm.isOpen = false
        draftState.data.unshift(action.payload.data)
      })
    case CREATE_RATE_REQUEST:
      return produce(state, draftState => {
        draftState.rateForm.isSending = true
        draftState.animation = true
      })
    case SET_FIELD_FOR_RATE:
      return produce(state, draftState => {
        draftState.rateForm[action.payload.field] = action.payload.value
      })
    case CLOSE_RATE_FORM:
      return produce(state, draftState => {
        draftState.rateForm.isOpen = false
      })
    case OPEN_RATE_FORM:
      return produce(state, draftState => {
        draftState.rateForm = {
          ...initialState.rateForm,
          isOpen: true
        }
      })
    case RATES_LOAD_REQUEST:
      return produce(state, draftState => {
        draftState.isLoading = true
        draftState.isTotalReached = false

        const pageNumber = action.payload.request.params['page']
        if (pageNumber === 1) {
          draftState.data = []
        }
        draftState.pageNumber = pageNumber

        if (action.payload.request.params['sort']) {
          draftState.sort.field = action.payload.request.params['sort']
          draftState.sort.type = action.payload.request.params['sort_type']
        } else {
          draftState.sort = initialState.sort
        }
      })
    case RATES_LOAD_SUCCESS:
      return produce(state, draftState => {
        draftState.isLoading = false
        draftState.animation = false

        if (draftState.pageNumber === 1) {
          draftState.data = action.payload.data
        } else {
          draftState.isTotalReached = action.payload.data.length === 0
          draftState.data = draftState.data.concat(action.payload.data)
        }
      })
    default:
      return state
  }
}
