import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { Row, Col, Button, DatePicker, Select, TreeSelect, message } from 'antd'
import moment from 'moment'

import {
  setDateRange,
  setFieldForUnloading,
  resetFilters
} from '../../../redux/actions/UnloadingActions'

import {
  getUsersList,
  getProjectsList,
  getDiagnosesList,
  getSponsorsList,
  getClientsList
} from '../../../redux/actions/ClientActions'

import getUrlParamsFromObj from '../../../utils/getUrlParamsFromObj'
import downloadFile from '../../../utils/downloadFile'
import hasUserAccess from '../../../utils/hasUserAccess'

const { RangePicker } = DatePicker
const { Option } = Select
const { TreeNode } = TreeSelect

class Unloading extends PureComponent {
  state = {
    isDownloadingReportClients: false,
    isDownloadingReportClientsCommonInfo: false,
    isDownloadingReportSpecialists: false,
    isDownloadingReportSponsors: false,
    isDownloadingReportEmploymentAndStatistics: false
  }

  componentDidMount() {
    this.props.getDiagnosesList()
    this.props.getProjectsList()
    this.props.getUsersList()
    this.props.getSponsorsList()
    this.props.getClientsList()
  }

  render() {
    const commonData = this.props.client.commonData

    const clientsForm = this.props.unloading.clients
    const clientsCommonInfoForm = this.props.unloading.clients_common_info
    const specialistsForm = this.props.unloading.specialists
    const sponsorsForm = this.props.unloading.sponsors
    const employmentAndStatisticsForm = this.props.unloading
      .employment_and_statistics
    const calendarForm = this.props.unloading.calendar

    const url = process.env.REACT_APP_API_HOST
    const token = this.props.user.attributes.token

    const urlParamsClients = getUrlParamsFromObj(clientsForm)
    const urlParamsClientsCommonInfo = getUrlParamsFromObj(
      clientsCommonInfoForm
    )
    const urlParamsSpecialists = getUrlParamsFromObj(specialistsForm)
    const urlParamsSponsors = getUrlParamsFromObj(sponsorsForm)
    const urlParamsCalendar = getUrlParamsFromObj(calendarForm)
    const urlParamsEmploymentAndStatistics = getUrlParamsFromObj(
      employmentAndStatisticsForm
    )

    const baseUrl = process.env.REACT_APP_API_HOST.replace('/api', '/')

    const calLinkUrl =
      baseUrl + 'calendar/calendarExport.php' + urlParamsCalendar

    const userEmploymentAndStatisticsAccess = hasUserAccess(
      this.props.settings.params,
      'unloading_employment_and_statistics_access',
      this.props.user.id
    )

    const userClientsCommonInfoAccess = hasUserAccess(
      this.props.settings.params,
      'unloading_clients_common_info_access',
      this.props.user.id
    )

    return (
      <div>
        <Helmet>
          <title>Выгрузки</title>
        </Helmet>
        <h1>Выгрузки</h1>
        <h3>Клиенты</h3>
        <Row gutter={16} className="Clients__settings">
          <Col span={8}>
            <RangePicker
              placeholder={['Дата рождения c', 'Дата рождения по']}
              format={'DD.MM.YYYY'}
              style={{ width: '100%' }}
              onChange={(value, dateString) => {
                this.props.setDateRange(
                  'date_of_birth',
                  [
                    dateString[0]
                      ? moment(dateString[0], 'DD.MM.YYYY').format('YYYY-MM-DD')
                      : null,
                    dateString[1]
                      ? moment(dateString[1], 'DD.MM.YYYY').format('YYYY-MM-DD')
                      : null
                  ],
                  'clients'
                )
              }}
              value={[
                clientsForm.date_of_birth_from
                  ? moment(clientsForm.date_of_birth_from, 'YYYY-MM-DD')
                  : null,
                clientsForm.date_of_birth_to
                  ? moment(clientsForm.date_of_birth_to, 'YYYY-MM-DD')
                  : null
              ]}
            />
          </Col>
          <Col span={8}>
            <TreeSelect
              allowClear
              treeDefaultExpandAll
              multiple
              placeholder="Диагноз"
              style={{ width: '100%' }}
              value={clientsForm.diagnosis_ids}
              onChange={value => {
                this.props.setFieldForUnloading(
                  'diagnosis_ids',
                  value.map(item => parseInt(item)),
                  'clients'
                )
              }}
            >
              {commonData.diagnoses_list.map(item => (
                <TreeNode key={item.id} value={item.id} title={item.name}>
                  {item.children.map(child => (
                    <TreeNode
                      key={child.id}
                      value={child.id}
                      title={child.name}
                    />
                  ))}
                </TreeNode>
              ))}
            </TreeSelect>
          </Col>
          <Col span={8}>
            <Select
              placeholder="Выберите тип услуги"
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              value={clientsForm.service_types}
              onChange={value => {
                this.props.setFieldForUnloading(
                  'service_types',
                  value.map(item => parseInt(item)),
                  'clients'
                )
              }}
            >
              <Option value={1}>Консультация в центре</Option>
              <Option value={2}>Консультация онлайн</Option>
              <Option value={3}>Консультация на дому</Option>
              <Option value={4}>Индивидуальные занятия в центре</Option>
              <Option value={8}>Индивидуальные занятия на дому</Option>
              <Option value={9}>Индивидуальные занятия онлайн</Option>
              <Option value={5}>Семейная встреча онлайн</Option>
              <Option value={6}>Семейная встреча в центре</Option>
              <Option value={7}>Групповые занятия</Option>
            </Select>
          </Col>
        </Row>
        <Row gutter={16} className="Clients__settings">
          <Col span={8}>
            <RangePicker
              placeholder={[
                'Дата оказания услуги c',
                'Дата оказания услуги по'
              ]}
              format={'DD.MM.YYYY'}
              style={{ width: '100%' }}
              onChange={(value, dateString) => {
                this.props.setDateRange(
                  'service_date',
                  [
                    dateString[0]
                      ? moment(dateString[0], 'DD.MM.YYYY').format('YYYY-MM-DD')
                      : null,
                    dateString[1]
                      ? moment(dateString[1], 'DD.MM.YYYY').format('YYYY-MM-DD')
                      : null
                  ],
                  'clients'
                )
              }}
              value={[
                clientsForm.service_date_from
                  ? moment(clientsForm.service_date_from, 'YYYY-MM-DD')
                  : null,
                clientsForm.service_date_to
                  ? moment(clientsForm.service_date_to, 'YYYY-MM-DD')
                  : null
              ]}
            />
          </Col>
          <Col span={8}>
            <Select
              placeholder="Выберите сотрудника"
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              value={clientsForm.service_specialist_ids}
              onChange={value => {
                this.props.setFieldForUnloading(
                  'service_specialist_ids',
                  value.map(item => parseInt(item)),
                  'clients'
                )
              }}
            >
              {commonData.users_list.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.surname} {item.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={8}>
            <Select
              placeholder="Выберите профиль специалиста"
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              value={clientsForm.service_specialist_types.map(item => item)}
              onChange={value => {
                this.props.setFieldForUnloading(
                  'service_specialist_types',
                  value.map(item => parseInt(item)),
                  'clients'
                )
              }}
            >
              <Option value={1}>Физический терапевт</Option>
              <Option value={2}>Эрготерапевт</Option>
              <Option value={3}>Специалист по коммуникации</Option>
              <Option value={4}>Психолог</Option>
              <Option value={5}>Подбор и адаптация ТСР</Option>
              <Option value={6}>Врач ФРМ</Option>
              <Option value={7}>Анализ походки</Option>
              <Option value={8}>Логопед</Option>
              <Option value={9}>Специалист программы ранней помощи</Option>
              <Option value={10}>Супервизор программы ранней помощи</Option>
            </Select>
          </Col>
        </Row>
        <Row gutter={16} className="Clients__settings">
          <Col span={8}>
            <Select
              placeholder="Выберите проект"
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              value={clientsForm.project_ids}
              onChange={value => {
                this.props.setFieldForUnloading(
                  'project_ids',
                  value.map(item => parseInt(item)),
                  'clients'
                )
              }}
            >
              {commonData.projects_list.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={8}>
            <Select
              placeholder="Выберите спонсора"
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              value={clientsForm.sponsor_ids}
              onChange={value => {
                this.props.setFieldForUnloading(
                  'sponsor_ids',
                  value.map(item => parseInt(item)),
                  'clients'
                )
              }}
            >
              {commonData.sponsors_list.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={8}>
            <Select
              placeholder="GMFCS"
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              value={clientsForm.gmfcs}
              onChange={value => {
                this.props.setFieldForUnloading(
                  'gmfcs',
                  value.map(item => parseInt(item)),
                  'clients'
                )
              }}
            >
              <Option value={1}>I</Option>
              <Option value={2}>II</Option>
              <Option value={3}>III</Option>
              <Option value={4}>IV</Option>
              <Option value={5}>V</Option>
            </Select>
          </Col>
        </Row>
        <Row gutter={16} className="Clients__settings">
          <Col span={8}>
            <Select
              placeholder="MACS"
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              value={clientsForm.macs}
              onChange={value => {
                this.props.setFieldForUnloading(
                  'macs',
                  value.map(item => parseInt(item)),
                  'clients'
                )
              }}
            >
              <Option value={1}>I</Option>
              <Option value={2}>II</Option>
              <Option value={3}>III</Option>
              <Option value={4}>IV</Option>
              <Option value={5}>V</Option>
            </Select>
          </Col>
          <Col span={8}>
            <Select
              placeholder="CFCS"
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              value={clientsForm.cfcs}
              onChange={value => {
                this.props.setFieldForUnloading(
                  'cfcs',
                  value.map(item => parseInt(item)),
                  'clients'
                )
              }}
            >
              <Option value={1}>I</Option>
              <Option value={2}>II</Option>
              <Option value={3}>III</Option>
              <Option value={4}>IV</Option>
              <Option value={5}>V</Option>
            </Select>
          </Col>
          <Col span={8}>
            <Select
              placeholder="EDACS"
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              value={clientsForm.edacs}
              onChange={value => {
                this.props.setFieldForUnloading(
                  'edacs',
                  value.map(item => parseInt(item)),
                  'clients'
                )
              }}
            >
              <Option value={1}>I</Option>
              <Option value={2}>II</Option>
              <Option value={3}>III</Option>
              <Option value={4}>IV</Option>
              <Option value={5}>V</Option>
            </Select>
          </Col>
        </Row>
        <Row gutter={16} className="Clients__settings">
          <Col span={8}>
            <Select
              placeholder="VFCS"
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              value={clientsForm.vfcs}
              onChange={value => {
                this.props.setFieldForUnloading(
                  'vfcs',
                  value.map(item => parseInt(item)),
                  'clients'
                )
              }}
            >
              <Option value={1}>I</Option>
              <Option value={2}>II</Option>
              <Option value={3}>III</Option>
              <Option value={4}>IV</Option>
              <Option value={5}>V</Option>
            </Select>
          </Col>
        </Row>

        <p></p>

        <Row gutter={16} className="Clients_settings">
          <Col span={8}>
            <a
              href="/"
              target={'_blank'}
              onClick={e => {
                e.preventDefault()
                this.setState({ isDownloadingReportClients: true })
                downloadFile(
                  url + '/clients/get-report-clients' + urlParamsClients,
                  'Clients_report.xlsx',
                  token,
                  () => {
                    this.setState({ isDownloadingReportClients: false })
                  }
                )
              }}
            >
              <Button
                type="primary"
                loading={this.state.isDownloadingReportClients}
              >
                Сформировать
              </Button>{' '}
            </a>
            <Button
              type="dashed"
              onClick={e => {
                this.props.resetFilters('clients')
              }}
            >
              сбросить
            </Button>
          </Col>
        </Row>

        <p></p>

        {userClientsCommonInfoAccess && (
          <>
            <h3>Общие сведения клиентов</h3>

            <Row gutter={16} className="Clients__settings">
              <Col span={8}>
                <Select
                  placeholder="Выберите статус клиента"
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  value={clientsCommonInfoForm.is_archive}
                  onChange={value => {
                    this.props.setFieldForUnloading(
                      'is_archive',
                      value.map(item => parseInt(item)),
                      'clients_common_info'
                    )
                  }}
                >
                  <Option value={0}>Действующий</Option>
                  <Option value={1}>В архиве</Option>
                </Select>
              </Col>
              <Col span={8}>
                <Select
                  placeholder="Выберите наличие услуг"
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  value={clientsCommonInfoForm.has_complete_services}
                  onChange={value => {
                    this.props.setFieldForUnloading(
                      'has_complete_services',
                      value.map(item => parseInt(item)),
                      'clients_common_info'
                    )
                  }}
                >
                  <Option value={0}>Нет оказанных услуг</Option>
                  <Option value={1}>Есть оказанные услуги</Option>
                </Select>
              </Col>
            </Row>

            <p></p>

            <Row gutter={16} className="Clients_settings">
              <Col span={8}>
                <a
                  href="/"
                  target={'_blank'}
                  onClick={e => {
                    e.preventDefault()
                    this.setState({
                      isDownloadingReportClientsCommonInfo: true
                    })
                    downloadFile(
                      url +
                        '/clients/get-report-clients-common-info' +
                        urlParamsClientsCommonInfo,
                      'Clients_common_info_report.xlsx',
                      token,
                      () => {
                        this.setState({
                          isDownloadingReportClientsCommonInfo: false
                        })
                      }
                    )
                  }}
                >
                  <Button
                    type="primary"
                    loading={this.state.isDownloadingReportClientsCommonInfo}
                  >
                    Сформировать
                  </Button>{' '}
                </a>
                <Button
                  type="dashed"
                  onClick={e => {
                    this.props.resetFilters('clients_common_info')
                  }}
                >
                  сбросить
                </Button>
              </Col>
            </Row>

            <p></p>
          </>
        )}

        <h3>Специалисты</h3>

        <Row gutter={16} className="Clients__settings">
          <Col span={8}>
            <RangePicker
              placeholder={[
                'Дата оказания услуги c',
                'Дата оказания услуги по'
              ]}
              format={'DD.MM.YYYY'}
              style={{ width: '100%' }}
              onChange={(value, dateString) => {
                this.props.setDateRange(
                  'service_date',
                  [
                    dateString[0]
                      ? moment(dateString[0], 'DD.MM.YYYY').format('YYYY-MM-DD')
                      : null,
                    dateString[1]
                      ? moment(dateString[1], 'DD.MM.YYYY').format('YYYY-MM-DD')
                      : null
                  ],
                  'specialists'
                )
              }}
              value={[
                specialistsForm.service_date_from
                  ? moment(specialistsForm.service_date_from, 'YYYY-MM-DD')
                  : null,
                specialistsForm.service_date_to
                  ? moment(specialistsForm.service_date_to, 'YYYY-MM-DD')
                  : null
              ]}
            />
          </Col>
          <Col span={8}>
            <Select
              placeholder="Выберите сотрудника"
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              value={specialistsForm.service_specialist_ids}
              onChange={value => {
                this.props.setFieldForUnloading(
                  'service_specialist_ids',
                  value.map(item => parseInt(item)),
                  'specialists'
                )
              }}
            >
              {commonData.users_list.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.surname} {item.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={8}>
            <Select
              placeholder="Выберите профиль специалиста"
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              value={specialistsForm.service_specialist_types}
              onChange={value => {
                this.props.setFieldForUnloading(
                  'service_specialist_types',
                  value.map(item => parseInt(item)),
                  'specialists'
                )
              }}
            >
              <Option value={1}>Физический терапевт</Option>
              <Option value={2}>Эрготерапевт</Option>
              <Option value={3}>Специалист по коммуникации</Option>
              <Option value={4}>Психолог</Option>
              <Option value={5}>Подбор и адаптация ТСР</Option>
              <Option value={6}>Врач ФРМ</Option>
              <Option value={7}>Анализ походки</Option>
              <Option value={8}>Логопед</Option>
              <Option value={9}>Специалист программы ранней помощи</Option>
              <Option value={10}>Супервизор программы ранней помощи</Option>
            </Select>
          </Col>
        </Row>

        <Row gutter={16} className="Clients__settings">
          <Col span={8}>
            <Select
              placeholder="Выберите тип услуги"
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              value={specialistsForm.service_types}
              onChange={value => {
                this.props.setFieldForUnloading(
                  'service_types',
                  value.map(item => parseInt(item)),
                  'specialists'
                )
              }}
            >
              <Option value={1}>Консультация в центре</Option>
              <Option value={2}>Консультация онлайн</Option>
              <Option value={3}>Консультация на дому</Option>
              <Option value={4}>Индивидуальные занятия в центре</Option>
              <Option value={8}>Индивидуальные занятия на дому</Option>
              <Option value={9}>Индивидуальные занятия онлайн</Option>
              <Option value={5}>Семейная встреча онлайн</Option>
              <Option value={6}>Семейная встреча в центре</Option>
              <Option value={7}>Групповые занятия</Option>
            </Select>
          </Col>
          <Col span={8}>
            <Select
              placeholder="Выберите статус услуги"
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              value={specialistsForm.statuses}
              onChange={value => {
                this.props.setFieldForUnloading(
                  'statuses',
                  value.map(item => parseInt(item)),
                  'specialists'
                )
              }}
            >
              <Option value={0}>предстоит</Option>
              <Option value={1}>посетил</Option>
              <Option value={2}>не посетил</Option>
            </Select>
          </Col>
          <Col span={8}>
            <Select
              placeholder="Выберите причину неявки"
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              value={specialistsForm.reasons_for_absence}
              onChange={value => {
                this.props.setFieldForUnloading(
                  'reasons_for_absence',
                  value.map(item => parseInt(item)),
                  'specialists'
                )
              }}
            >
              <Option value={1}>уважительная</Option>
              <Option value={2}>неуважительная</Option>
            </Select>
          </Col>
        </Row>

        <Row gutter={16} className="Clients__settings">
          <Col span={8}>
            <Select
              placeholder="Выберите проект"
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              value={specialistsForm.project_ids}
              onChange={value => {
                this.props.setFieldForUnloading(
                  'project_ids',
                  value.map(item => parseInt(item)),
                  'specialists'
                )
              }}
            >
              {commonData.projects_list.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={8}>
            <Select
              placeholder="Выберите спонсора"
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              value={specialistsForm.sponsor_ids}
              onChange={value => {
                this.props.setFieldForUnloading(
                  'sponsor_ids',
                  value.map(item => parseInt(item)),
                  'specialists'
                )
              }}
            >
              {commonData.sponsors_list.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>

        <p></p>

        <Row gutter={16} className="Clients_settings">
          <Col span={8}>
            <a
              href="/"
              target={'_blank'}
              onClick={e => {
                e.preventDefault()
                this.setState({ isDownloadingReportSpecialists: true })
                downloadFile(
                  url +
                    '/clients/get-report-specialists' +
                    urlParamsSpecialists,
                  'Specialists_report.xlsx',
                  token,
                  () => {
                    this.setState({ isDownloadingReportSpecialists: false })
                  }
                )
              }}
            >
              <Button
                type="primary"
                loading={this.state.isDownloadingReportSpecialists}
              >
                Сформировать
              </Button>{' '}
            </a>
            <Button
              type="dashed"
              onClick={e => {
                this.props.resetFilters('sponsors')
              }}
            >
              сбросить
            </Button>
          </Col>
        </Row>

        <p></p>

        <h3>Реестр</h3>

        <Row gutter={16} className="Clients__settings">
          <Col span={8}>
            <RangePicker
              placeholder={[
                'Дата оказания услуги c',
                'Дата оказания услуги по'
              ]}
              format={'DD.MM.YYYY'}
              style={{ width: '100%' }}
              onChange={(value, dateString) => {
                this.props.setDateRange(
                  'service_date',
                  [
                    dateString[0]
                      ? moment(dateString[0], 'DD.MM.YYYY').format('YYYY-MM-DD')
                      : null,
                    dateString[1]
                      ? moment(dateString[1], 'DD.MM.YYYY').format('YYYY-MM-DD')
                      : null
                  ],
                  'sponsors'
                )
              }}
              value={[
                sponsorsForm.service_date_from
                  ? moment(sponsorsForm.service_date_from, 'YYYY-MM-DD')
                  : null,
                sponsorsForm.service_date_to
                  ? moment(sponsorsForm.service_date_to, 'YYYY-MM-DD')
                  : null
              ]}
            />
          </Col>
          <Col span={8}>
            <Select
              placeholder="Выберите сотрудника"
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              value={sponsorsForm.service_specialist_ids}
              onChange={value => {
                this.props.setFieldForUnloading(
                  'service_specialist_ids',
                  value.map(item => parseInt(item)),
                  'sponsors'
                )
              }}
            >
              {commonData.users_list.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.surname} {item.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={8}>
            <Select
              placeholder="Выберите профиль специалиста"
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              value={sponsorsForm.service_specialist_types}
              onChange={value => {
                this.props.setFieldForUnloading(
                  'service_specialist_types',
                  value.map(item => parseInt(item)),
                  'sponsors'
                )
              }}
            >
              <Option value={1}>Физический терапевт</Option>
              <Option value={2}>Эрготерапевт</Option>
              <Option value={3}>Специалист по коммуникации</Option>
              <Option value={4}>Психолог</Option>
              <Option value={5}>Подбор и адаптация ТСР</Option>
              <Option value={6}>Врач ФРМ</Option>
              <Option value={7}>Анализ походки</Option>
              <Option value={8}>Логопед</Option>
              <Option value={9}>Специалист программы ранней помощи</Option>
              <Option value={10}>Супервизор программы ранней помощи</Option>
            </Select>
          </Col>
        </Row>

        <Row gutter={16} className="Clients__settings">
          <Col span={8}>
            <Select
              placeholder="Выберите тип услуги"
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              value={sponsorsForm.service_types}
              onChange={value => {
                this.props.setFieldForUnloading(
                  'service_types',
                  value.map(item => parseInt(item)),
                  'sponsors'
                )
              }}
            >
              <Option value={1}>Консультация в центре</Option>
              <Option value={2}>Консультация онлайн</Option>
              <Option value={3}>Консультация на дому</Option>
              <Option value={4}>Индивидуальные занятия в центре</Option>
              <Option value={8}>Индивидуальные занятия на дому</Option>
              <Option value={9}>Индивидуальные занятия онлайн</Option>
              <Option value={5}>Семейная встреча онлайн</Option>
              <Option value={6}>Семейная встреча в центре</Option>
              <Option value={7}>Групповые занятия</Option>
            </Select>
          </Col>
          <Col span={8}>
            <Select
              placeholder="Выберите статус услуги"
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              value={sponsorsForm.statuses}
              onChange={value => {
                this.props.setFieldForUnloading(
                  'statuses',
                  value.map(item => parseInt(item)),
                  'sponsors'
                )
              }}
            >
              <Option value={0}>предстоит</Option>
              <Option value={1}>посетил</Option>
              <Option value={2}>не посетил</Option>
            </Select>
          </Col>
          <Col span={8}>
            <Select
              placeholder="Выберите причину неявки"
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              value={sponsorsForm.reasons_for_absence}
              onChange={value => {
                this.props.setFieldForUnloading(
                  'reasons_for_absence',
                  value.map(item => parseInt(item)),
                  'sponsors'
                )
              }}
            >
              <Option value={1}>уважительная</Option>
              <Option value={2}>неуважительная</Option>
            </Select>
          </Col>
        </Row>

        <Row gutter={16} className="Clients__settings">
          <Col span={8}>
            <Select
              placeholder="Выберите проект"
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              value={sponsorsForm.project_ids}
              onChange={value => {
                this.props.setFieldForUnloading(
                  'project_ids',
                  value.map(item => parseInt(item)),
                  'sponsors'
                )
              }}
            >
              {commonData.projects_list.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={8}>
            <Select
              placeholder="Выберите спонсора"
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              value={sponsorsForm.sponsor_ids}
              onChange={value => {
                this.props.setFieldForUnloading(
                  'sponsor_ids',
                  value.map(item => parseInt(item)),
                  'sponsors'
                )
              }}
            >
              {commonData.sponsors_list.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={8}>
            <Select
              placeholder="Тип"
              style={{ width: '100%' }}
              value={sponsorsForm.type}
              onChange={value => {
                this.props.setFieldForUnloading('type', value, 'sponsors')
              }}
            >
              <Option value={1}>Детальный</Option>
              <Option value={2}>Сводный</Option>
            </Select>
          </Col>
        </Row>

        <Row gutter={16} className="Clients__settings">
          <Col span={8}>
            <Select
              placeholder="Выберите клиента"
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              value={sponsorsForm.client_ids}
              onChange={value => {
                this.props.setFieldForUnloading(
                  'client_ids',
                  value.map(item => parseInt(item)),
                  'sponsors'
                )
              }}
            >
              {commonData.clients_list.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.surname} {item.name}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>

        <p></p>

        <Row gutter={16} className="Clients_settings">
          <Col span={8}>
            <a
              href="/"
              target={'_blank'}
              onClick={e => {
                e.preventDefault()
                this.setState({ isDownloadingReportSponsors: true })
                downloadFile(
                  url + '/clients/get-report-sponsors' + urlParamsSponsors,
                  'Sponsors_report.xlsx',
                  token,
                  () => {
                    this.setState({ isDownloadingReportSponsors: false })
                  }
                )
              }}
            >
              <Button
                type="primary"
                loading={this.state.isDownloadingReportSponsors}
              >
                Сформировать
              </Button>{' '}
            </a>
            <Button
              type="dashed"
              onClick={e => {
                this.props.resetFilters('sponsors')
              }}
            >
              сбросить
            </Button>
          </Col>
        </Row>

        <p></p>

        {userEmploymentAndStatisticsAccess && (
          <>
            <h3>Занятость и статистика</h3>
            <p></p>
            <Row gutter={16} className="Clients__settings">
              <Col span={8}>
                <RangePicker
                  placeholder={[
                    'Дата оказания услуги c',
                    'Дата оказания услуги по'
                  ]}
                  format={'DD.MM.YYYY'}
                  style={{ width: '100%' }}
                  onChange={(value, dateString) => {
                    this.props.setDateRange(
                      'service_date',
                      [
                        dateString[0]
                          ? moment(dateString[0], 'DD.MM.YYYY').format(
                              'YYYY-MM-DD'
                            )
                          : null,
                        dateString[1]
                          ? moment(dateString[1], 'DD.MM.YYYY').format(
                              'YYYY-MM-DD'
                            )
                          : null
                      ],
                      'employment_and_statistics'
                    )
                  }}
                  value={[
                    employmentAndStatisticsForm.service_date_from
                      ? moment(
                          employmentAndStatisticsForm.service_date_from,
                          'YYYY-MM-DD'
                        )
                      : null,
                    employmentAndStatisticsForm.service_date_to
                      ? moment(
                          employmentAndStatisticsForm.service_date_to,
                          'YYYY-MM-DD'
                        )
                      : null
                  ]}
                />
              </Col>
              <Col span={8}>
                <Select
                  placeholder="Выберите сотрудника"
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  value={employmentAndStatisticsForm.service_specialist_ids}
                  onChange={value => {
                    this.props.setFieldForUnloading(
                      'service_specialist_ids',
                      value.map(item => parseInt(item)),
                      'employment_and_statistics'
                    )
                  }}
                >
                  {commonData.users_list.map(item => (
                    <Option key={item.id} value={item.id}>
                      {item.surname} {item.name}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col span={8}>
                <Select
                  placeholder="Выберите профиль специалиста"
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  value={employmentAndStatisticsForm.service_specialist_types}
                  onChange={value => {
                    this.props.setFieldForUnloading(
                      'service_specialist_types',
                      value.map(item => parseInt(item)),
                      'employment_and_statistics'
                    )
                  }}
                >
                  <Option value={1}>Физический терапевт</Option>
                  <Option value={2}>Эрготерапевт</Option>
                  <Option value={3}>Специалист по коммуникации</Option>
                  <Option value={4}>Психолог</Option>
                  <Option value={5}>Подбор и адаптация ТСР</Option>
                  <Option value={6}>Врач ФРМ</Option>
                  <Option value={7}>Анализ походки</Option>
                  <Option value={8}>Логопед</Option>
                  <Option value={9}>Специалист программы ранней помощи</Option>
                  <Option value={10}>Супервизор программы ранней помощи</Option>
                </Select>
              </Col>
            </Row>

            <Row gutter={16} className="Clients__settings">
              <Col span={8}>
                <Select
                  placeholder="Выберите проект"
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  value={employmentAndStatisticsForm.project_ids}
                  onChange={value => {
                    this.props.setFieldForUnloading(
                      'project_ids',
                      value.map(item => parseInt(item)),
                      'employment_and_statistics'
                    )
                  }}
                >
                  {commonData.projects_list.map(item => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col span={8}>
                <Select
                  placeholder="Выберите спонсора"
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  value={employmentAndStatisticsForm.sponsor_ids}
                  onChange={value => {
                    this.props.setFieldForUnloading(
                      'sponsor_ids',
                      value.map(item => parseInt(item)),
                      'employment_and_statistics'
                    )
                  }}
                >
                  {commonData.sponsors_list.map(item => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col span={8}>
                <Select
                  placeholder="Тип"
                  style={{ width: '100%' }}
                  value={employmentAndStatisticsForm.state}
                  onChange={value => {
                    this.props.setFieldForUnloading(
                      'state',
                      value,
                      'employment_and_statistics'
                    )
                  }}
                >
                  <Option value={0}>Предстоящие услуги</Option>
                  <Option value={1}>Прошедшие услуги</Option>
                </Select>
              </Col>
            </Row>

            <Row gutter={16} className="Clients__settings">
              <Col span={8}>
                <Select
                  placeholder="Выберите тип услуги"
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  value={employmentAndStatisticsForm.service_types}
                  onChange={value => {
                    this.props.setFieldForUnloading(
                      'service_types',
                      value.map(item => parseInt(item)),
                      'employment_and_statistics'
                    )
                  }}
                >
                  <Option value={1}>Консультация в центре</Option>
                  <Option value={2}>Консультация онлайн</Option>
                  <Option value={3}>Консультация на дому</Option>
                  <Option value={4}>Индивидуальные занятия в центре</Option>
                  <Option value={8}>Индивидуальные занятия на дому</Option>
                  <Option value={9}>Индивидуальные занятия онлайн</Option>
                  <Option value={5}>Семейная встреча онлайн</Option>
                  <Option value={6}>Семейная встреча в центре</Option>
                  <Option value={7}>Групповые занятия</Option>
                  <Option value={10}>Фитнес-группа онлайн</Option>
                </Select>
              </Col>
            </Row>

            <p></p>

            <Row gutter={16} className="Clients_settings">
              <Col span={8}>
                <a
                  href="/"
                  target={'_blank'}
                  onClick={e => {
                    e.preventDefault()
                    this.setState({
                      isDownloadingReportEmploymentAndStatistics: true
                    })
                    downloadFile(
                      url +
                        '/clients/get-report-employment-and-tatistics' +
                        urlParamsEmploymentAndStatistics,
                      'Employment_and_statistics_past.xlsx',
                      token,
                      () => {
                        this.setState({
                          isDownloadingReportEmploymentAndStatistics: false
                        })
                      }
                    )
                  }}
                >
                  <Button
                    type="primary"
                    loading={
                      this.state.isDownloadingReportEmploymentAndStatistics
                    }
                  >
                    Сформировать
                  </Button>{' '}
                </a>
                <Button
                  type="dashed"
                  onClick={e => {
                    this.props.resetFilters('employment_and_statistics')
                  }}
                >
                  сбросить
                </Button>
              </Col>
            </Row>

            <p></p>
          </>
        )}

        <h3>Календарь</h3>

        <Row gutter={16} className="Clients__settings">
          <Col span={8}>
            <Select
              placeholder="Выберите сотрудника"
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              value={calendarForm.specialists}
              onChange={value => {
                this.props.setFieldForUnloading(
                  'specialists',
                  value.map(item => parseInt(item)),
                  'calendar'
                )
              }}
            >
              {commonData.users_list.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.surname} {item.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={8}>
            <Button
              type="primary"
              onClick={() => {
                navigator.clipboard
                  .writeText(calLinkUrl)
                  .then(() => {
                    message.success('Ссылка скопирована')
                  })
                  .catch(err => {
                    console.log('Something went wrong', err)
                  })
              }}
            >
              Копировать ссылку
            </Button>{' '}
            <Button
              type="dashed"
              onClick={e => {
                this.props.resetFilters('calendar')
              }}
            >
              сбросить
            </Button>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = store => {
  return {
    unloading: store.unloading,
    client: store.client,
    user: store.user,
    settings: store.settings
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setDateRange: (type, dateString, mode) =>
      dispatch(setDateRange(type, dateString, mode)),
    setFieldForUnloading: (field, value, mode) =>
      dispatch(setFieldForUnloading(field, value, mode)),
    resetFilters: mode => dispatch(resetFilters(mode)),
    getProjectsList: () => dispatch(getProjectsList()),
    getDiagnosesList: () => dispatch(getDiagnosesList()),
    getUsersList: () => dispatch(getUsersList()),
    getSponsorsList: () => dispatch(getSponsorsList()),
    getClientsList: () => dispatch(getClientsList())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Unloading)
