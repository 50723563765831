import React, { PureComponent } from 'react'
import { Icon, Button, Row, Col, Radio, Spin, Input, Drawer } from 'antd'
import { Helmet } from 'react-helmet'
import { EditOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import moment from 'moment'

import {
  openClientForm,
  closeClientForm,
  openEditClientForm
} from '../../../redux/actions/ClientActions'

import {
  getClientsList,
  getCounter
} from '../../../redux/actions/ClientsActions'

import ClientForm from './Components/ClientFormComponent'

import AnimatedTable from '../../../components/AnimatedTableComponent'
import ClientStatus from '../../../components/ClientStatusComponent'

let searchTimer

class Clients extends PureComponent {
  componentDidMount() {
    this.props.getClientsList(1, this.props.clients.sort)
    this.props.getCounter()
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = e => {
    if (
      this.props.clients.isLoadingClients ||
      this.props.clients.isTotalReached
    ) {
      return
    }

    const body = document.body,
      html = document.documentElement

    const wholePageHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    )

    const scrollTop = window.scrollY
    const windowHeight = window.innerHeight

    if (scrollTop > wholePageHeight - windowHeight * 1.05) {
      const pageNumber = this.props.clients.pageNumber + 1
      this.props.getClientsList(
        pageNumber,
        this.props.clients.sort,
        this.props.clients.filter
      )
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    let sort = null
    if (Object.keys(sorter).length > 0) {
      sort = {
        field: sorter.field,
        type: sorter.order === 'ascend' ? 'asc' : 'desc'
      }
    }
    this.props.getClientsList(1, sort, {
      ...this.props.clients.filter
    })
  }

  getClientParent(item, parentType = 'parent_one') {
    let parent = ''

    if (item[parentType + '_name'] && item[parentType + '_surname']) {
      parent += item[parentType + '_name'] + ' ' + item[parentType + '_surname']
    } else if (item[parentType + '_name']) {
      parent += item[parentType + '_name']
    } else if (item[parentType + '_surname']) {
      parent += item[parentType + '_surname']
    }

    if (item[parentType + '_phone']) {
      parent += (parent ? ', ' : '') + item[parentType + '_phone']
    }

    return parent ? parent : '–'
  }

  startSearch(e) {
    const value = e.target.value

    clearTimeout(searchTimer)
    searchTimer = setTimeout(() => {
      this.props.getClientsList(1, this.props.clients.sort, {
        ...this.props.clients.filter,
        search: value
      })
    }, 500)
  }

  render() {
    const { clients } = this.props

    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />

    let tableStyle = null
    if (clients.isLoadingEvents && clients.pageNumber === 1) {
      tableStyle = { display: 'none' }
    }

    let columns = [
      {
        title: '',
        dataIndex: 'status',
        className: 'Clients__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            this.props.history.push(`/client/${record.id}`)
          }
        })
      },
      {
        title: 'Номер',
        dataIndex: 'id',
        sorter: true,
        className: 'Clients__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            this.props.history.push(`/client/${record.id}`)
          }
        })
      },
      {
        title: 'Имя',
        dataIndex: 'name',
        sorter: true,
        className: 'Clients__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            this.props.history.push(`/client/${record.id}`)
          }
        })
      },
      {
        title: 'Дата рождения',
        dataIndex: 'date_of_birth',
        sorter: true,
        className: 'Clients__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            this.props.history.push(`/client/${record.id}`)
          }
        })
      },
      {
        title: 'Представитель №1',
        dataIndex: 'parent_one',
        className: 'Clients__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            this.props.history.push(`/client/${record.id}`)
          }
        })
      },
      {
        title: 'Представитель №2',
        dataIndex: 'parent_two',
        className: 'Clients__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            this.props.history.push(`/client/${record.id}`)
          }
        })
      },
      {
        title: 'E-mail',
        dataIndex: 'email',
        className: 'Clients__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            this.props.history.push(`/client/${record.id}`)
          }
        })
      },
      {
        title: '',
        key: 'action',
        width: 50,
        render: (text, record) => (
          <span className="Clients__control-buttons">
            <a
              href="/"
              onClick={e => {
                e.preventDefault()
                const client = clients.clients.find(
                  item => item.id === record.id
                )
                this.props.openEditClientForm(client)
              }}
            >
              <EditOutlined />
            </a>
          </span>
        )
      }
    ]

    const data = clients.clients.map(item => ({
      key: item.id,
      id: item.id,
      status: <ClientStatus client={item} />,
      name: item.name,
      date_of_birth: moment(item.date_of_birth).format('DD.MM.YYYY'),
      parent_one: this.getClientParent(item, 'parent_one'),
      parent_two: this.getClientParent(item, 'parent_two'),
      email: item.email
    }))

    const { Search } = Input

    return (
      <div>
        <Helmet>
          <title>Клиенты</title>
        </Helmet>

        <h1>
          Клиенты
          <Button
            type="primary"
            className="Clients__add-button"
            onClick={this.props.openClientForm}
          >
            Создать
          </Button>
        </h1>

        <Row gutter={16} className="Clients__settings">
          <Col span={24}>
            <Radio.Group
              onChange={e => {
                const value = e.target.value
                this.props.getClientsList(1, clients.sort, {
                  ...clients.filter,
                  status: value
                })
              }}
              value={clients.filter.status}
            >
              <Radio.Button value="active">
                Действующие{' '}
                {clients.counter.active > 0
                  ? `(${clients.counter.active})`
                  : null}
              </Radio.Button>
              <Radio.Button value="new">
                Новые{' '}
                {clients.counter.new > 0 ? `(${clients.counter.new})` : null}
              </Radio.Button>
              <Radio.Button value="archived">
                Архив{' '}
                {clients.counter.archive > 0
                  ? `(${clients.counter.archive})`
                  : null}
              </Radio.Button>
              <Radio.Button value="all">
                Все{' '}
                {clients.counter.all > 0 ? `(${clients.counter.all})` : null}
              </Radio.Button>
            </Radio.Group>
          </Col>
        </Row>

        <Search
          placeholder="Поиск..."
          onChange={this.startSearch.bind(this)}
          enterButton
          size="large"
          className="Clients__search"
          style={{ marginBottom: '.5rem' }}
        />

        <AnimatedTable
          columns={columns}
          dataSource={data}
          pagination={false}
          onChange={this.handleTableChange}
          style={tableStyle}
          animation={clients.animation}
        />

        {clients.isLoadingClients && (
          <div className="Clients__loader">
            <Spin indicator={antIcon} />
          </div>
        )}

        <Drawer
          title={
            !this.props.client.clientForm.id ? 'Создание' : 'Редактирование'
          }
          width={'700'}
          onClose={this.props.closeClientForm}
          visible={this.props.client.clientForm.isOpen}
        >
          <ClientForm />
        </Drawer>
      </div>
    )
  }
}

const mapStateToProps = store => {
  return {
    client: store.client,
    clients: store.clients
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openClientForm: () => dispatch(openClientForm()),
    closeClientForm: () => dispatch(closeClientForm()),
    getClientsList: (pageNumber = 1, sort = null, filterForm = null) =>
      dispatch(getClientsList(pageNumber, sort, filterForm)),
    openEditClientForm: item => dispatch(openEditClientForm(item)),
    getCounter: () => dispatch(getCounter())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Clients)
