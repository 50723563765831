export const ADD_TIMESPAN = 'SCHEDULE/ADD_TIMESPAN'

export function addTimespan(date) {
  return dispatch => {
    dispatch({
      type: ADD_TIMESPAN,
      payload: {
        date
      }
    })
  }
}

export const DELETE_TIMESPAN = 'SCHEDULE/DELETE_TIMESPAN'

export function deleteTimespan(date, id) {
  return dispatch => {
    dispatch({
      type: DELETE_TIMESPAN,
      payload: {
        date,
        id
      }
    })
  }
}

export const EDIT_TIMESPAN = 'SCHEDULE/EDIT_TIMESPAN'

export function editTimespan(date, id, field, value) {
  return dispatch => {
    dispatch({
      type: EDIT_TIMESPAN,
      payload: {
        date,
        id,
        field,
        value
      }
    })
  }
}

export const GET_TIMESPANS_REQUEST = 'SCHEDULE/GET_TIMESPANS_REQUEST'
export const GET_TIMESPANS_SUCCESS = 'SCHEDULE/GET_TIMESPANS_SUCCESS'

export function getTimespans() {
  return dispatch => {
    dispatch({
      types: [GET_TIMESPANS_REQUEST, GET_TIMESPANS_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: `/schedule/get-my-free-timespans`
        }
      }
    })
  }
}

export const UPDATE_TIMESPANS_REQUEST = 'SCHEDULE/UPDATE_TIMESPANS_REQUEST'
export const UPDATE_TIMESPANS_SUCCESS = 'SCHEDULE/UPDATE_TIMESPANS_SUCCESS'

export function updateTimespans(params) {
  return dispatch => {
    dispatch({
      types: [UPDATE_TIMESPANS_REQUEST, UPDATE_TIMESPANS_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/schedule/update-my-free-timespans`,
          data: {
            ...params
          }
        }
      }
    })
  }
}

export const GET_CALENDAR_REQUEST = 'SCHEDULE/GET_CALENDAR_REQUEST'
export const GET_CALENDAR_SUCCESS = 'SCHEDULE/GET_CALENDAR_SUCCESS'

export function getCalendar(dateS, dateE) {
  return dispatch => {
    dispatch({
      types: [GET_CALENDAR_REQUEST, GET_CALENDAR_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: `/schedule/get-calendar`,
          params: {
            date_s: dateS,
            date_e: dateE
          }
        }
      }
    })
  }
}
