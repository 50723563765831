import { combineReducers } from 'redux'
import user from './user'
import server from './server'
import clients from './clients'
import client from './client'
import clientEstimates from './clientEstimates'
import unloading from './unloading'
import rates from './rates'
import settings from './settings'
import schedule from './schedule'
import employment from './employment'

export default combineReducers({
  user,
  server,
  clients,
  client,
  unloading,
  rates,
  clientEstimates,
  settings,
  schedule,
  employment
})
